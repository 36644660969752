<template>
    <div class="card-header">
        <b-button v-show="!isActiveForm" size="md" variant="primary" tabindex="0"
                      class="border-left border-right-0"
                      @click="clickNew" style="margin-right: 20px;">
            <i class="fa fa-plus"></i> Nuevo
        </b-button>

        <b-button v-show="!isActiveForm" size="md" variant="primary" tabindex="0"
                      class="border-left border-right-0"
                      @click="clickRefresh">
            <i class="fas fa-sync-alt"></i> Actualizar
        </b-button>
        <b-button v-show="isActiveForm" size="md" variant="secondary" tabindex="0"
                      class="border-left border-right-0"
                      @click="clickBackward">
            <i class="fa fa-backward"></i> Regresar
        </b-button>
    </div>
</template>

<script>
    export default {
        name: "UCToolbar",
        props: {
            isActiveForm: Boolean
        },
        data() {
            return {}
        },
        mounted() {
            this.isActiveForm = false;
        },
        methods: {
            clickNew() {
                this.$forceUpdate();
                this.$emit('OnCreateEvent');
            },
            clickRefresh() {
                this.$forceUpdate();
                this.$emit('OnReadEvent');
            },
            clickBackward() {
                this.$forceUpdate();
                this.$emit('OnBackwardEvent');
            }
        }
    }
</script>

<style scoped>

</style>