<template>
  <div class="col-md-6">
    <form @submit.prevent="checkFormValidity('form')" @submit="clickSave" ref="form" class="mt-3">
      <!-- .form-row -->
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label class="font-weight-bold">Código :<abbr
              title="Requerido">*</abbr></label>
          <b-input type="text"
                   class="form-control"
                   v-model="costCenter.code" Required :state="codeState">
          </b-input>
        </div>
        <div class="col-md-12 mb-3">
          <label class="font-weight-bold">Centro de Costo :<abbr
              title="Requerido">*</abbr></label>
          <b-input type="text"
                   class="form-control"
                   v-model="costCenter.name" Required minlength="5" :state="nameState">
          </b-input>
        </div>
      </div><!-- /.form-row -->
      <div class="form-actions col-md-6  offset-md-6" >
        <b-button class="btn-block" type="submit" variant="primary" >
          <span class="menu-icon fa fa-save"></span>
          <span class="menu-text"> Guardar</span>
        </b-button>
      </div>
    </form>
  </div>

</template>

<script>
export default {
  name: "CostCenterForm",
  props: {
    entity: Object
  },
  computed: {
      codeState(){
        if(this.costCenter.code) {
          return this.costCenter.code.length > 1 ? true : false;
        }
        return null;
      },
      nameState(){
        if(this.costCenter.name) {
          return this.costCenter.name.length > 4 ? true : false;
        }
        return null;
      },
  },
  mounted() {
    //this.initialize();
  },
  data() {
    return {
      costCenter: this.entity,
    }
  },
  methods: {
    initialize() {
    },
    checkFormValidity(form) {
      const valid = this.$refs[form].checkValidity()
      return valid
    },
    clickSave() {
      if (!this.checkFormValidity('form')) {
        return;
      } 
      this.$emit("OnSaveEvent", this.costCenter);
    }
  },
}
</script>