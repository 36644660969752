<template>
  <div class="page">
        <div class="page-inner">
            <header class="page-title-bar">
                <div class="container-fluid">
                    <h5>Configuraciones de seguridad</h5>
                </div>
            </header>
            <div class="card mb-3 col-xl-6">
                      <div class="card-body">
                        <!-- form -->
                        <form ref="form" @submit.prevent="checkFormValidity('form')" @submit="saveAccount()">
                          <!-- form row -->
                            <div class="col-md-12 mb-2">
                              <label >Nombre </label> <b-input type="text"  disabled v-model="user.name"></b-input>
                            </div><!-- /form column -->
                          <!-- .form-group -->
                          <div class="col-md-12 mb-2">
                            <label>Email</label> <b-input type="email" v-model="user.email" required disabled></b-input>
                          </div><!-- /.form-group -->
                          <!-- .form-group -->
                          <div class="col-md-12 mb-2">
                            <label>Nueva Contraseña </label> <b-input id="password" ref="password" type="password" required v-model="newconfig.password" autocomplete="off" maxlength="16" minlength="8" :state="passwordState"></b-input>
                          </div><!-- /.form-group -->
                          <div class="col-md-12 mb-2">
                            <label>Confirme Nueva Contraseña</label> <b-input type="password"  required v-model="newconfig.new_password" autocomplete="off" maxlength='16' minlength="8" :state="newpasswordState"></b-input>
                          </div><!-- /.form-group -->
                          <!-- .form-group -->
                          <hr>
                          <!-- .form-actions -->
                          <div class="form-row ml-2 mr-2">
                            <!-- enable submit btn when user type their current password -->
                            <div class='col-md-12'>
                            <label>Contraseña Actual</label>
                            </div>
                            <div class='form-actions col-md-12'>
                            <b-input type="password" class="form-control mr-3" placeholder="Ingrese Contraseña actual para confirmar cambios" required="" @input="validPass()" v-model="newconfig.current_password" :state="formState" autocomplete="off">
                            </b-input> <b-button type="submit" class="text-nowrap ml-auto" variant="danger" :disabled="!formState">Actualizar Cuenta</b-button>
                            </div>
                          </div><!-- /.form-actions -->
                        </form><!-- /form -->
                      </div><!-- /.card-body -->
            </div>
        </div>
        <div v-if="isLoading">
          <div class="overlay"></div>
          <div class="loading-img"></div>
        </div>
        <b-popover target="password" title="Estandar requerido" variant="warning" placement="topright" triggers="focus">
          <li>Almenos una Mayuscula</li>
          <li>Almenos una minuscula</li>
          <li>Almenos un Numero</li>
          <li>Minimo 8 caracteres</li>
        </b-popover>
    </div>
</template>

<script>

    export default {
        name: "SettingAccount",
        mounted() {
            //this.initialize();
        },
        data() {
            return {
                isDisabled: true,
                newconfig: {
                  current_password: "",
                  password: "",
                  new_password: "",
                },
                isLoading: false,
            }
        },
        computed: {
          user() {
                return this.$store.getters.user;
            },
          passwordState(){
            if(this.validPass(this.newconfig.password)) {
                return true;
            }
            return false;
          },
          newpasswordState(){
            return this.validPass(this.newconfig.new_password)
          },
          formState(){
            if(this.newconfig.current_password.length < 8) {
                return false
            } 
            return true
          }
        },
        methods: {
            validPass(pass){
              if(!/[A-Z]/.test(pass)) return false
              if(!/[a-z]/.test(pass)) return false
              if(!/[0-9]/.test(pass)) return false
              if(pass.length < 8) return false
              //if(!/[#?!@$%^&*-]/.test(pass)) return false;
              return true
            },
            loadAlert(message, tittle='Mensaje de Error: ', variant='danger'){
                this.$bvToast.toast(message, {
                    title: tittle,
                    variant: variant,
                    solid: true
                })
            },
            checkFormValidity(form) {
              const valid = this.$refs[form].checkValidity()
              return valid
            },
            saveAccount() {
                if(!this.validPass(this.newconfig.password)) {
                  this.$refs.password.$el.focus()
                  return false;
                }
                this.isLoading = true
                this.$api.post('management/users/' + this.user.id+'/edit', this.newconfig)
                    .then(response => {
                      this.isLoading = false
                      if(response.data.errors) {
                        var errors = response.data.errors
                        for(var index in errors) { 
                            errors[index].map(value =>{
                              this.loadAlert(value, 'Advertencia', response.data.variant)
                            });
                        }

                      } else if(response.data.error) {
                        this.loadAlert(response.data.message, 'Advertencia', response.data.variant)

                      } else {
                        //this.loadAlert(response.data.message, 'Mensaje', response.data.variant)
                        //this.$router.push("/dashboard");
                        this.$router.push({name: 'ShowDashboard', params: { password: 'password' }})
                      }
                    })
                    .catch(error => {
                          this.isLoading = false
                          this.loadAlert(error.message)
                    });
            }
        }
    }
</script>
