<template>
  <div class="page">
    <div class="balert">
        <b-alert class="has-icon" :variant="errorVariant" dismissible fade :show="hasError" 
            @dismissed="hasError=false" role="alert" >
          <div class="alert-icon">
              <span class="oi oi-info"></span>
          </div>
          <span class="font-weight-bold"> {{ errorTittle }} </span> {{errorMessage}}.
        </b-alert>
    </div>
    <div class="page-inner">
      <header class="page-title-bar">
        <div class="container-fluid">
            <h5>{{this.$options.name}}</h5>
        </div>
      </header>
      <div class="page-section">
        <!-- BEGIN VIEW -->
        <div class="card card-fluid">
          <div class="card-body pt-0">
            <div class="mt-4">
              <div class="form-row">
                  <div class="col-xl-6 col-md-12 mb-5">
                    <legend class="mb-1">Asignación de importe</legend>
                    <hr>
                    <div class="form-row">
                      <div class="col-xl-8">
                        <label > Nombre
                        </label>
                        <list-select :list="items"
                          id="mySelectId"
                          name="mySelectName"
                          option-value="id"
                          :custom-text="setTextSelect"
                          :selectedItem="user"
                          @select="selectItem"
                          placeholder="Seleccione Usuario Rendidor">
                        </list-select>
                      </div>
                      <div class="col-xl-4">
                          <label > &nbsp;
                            </label>
                            <b-button class="btn-md mt-2 mb-3" variant="success" block type="button"  v-on:click="csvExport"><i class="fas fa-download"></i> Expotar a CSV  </b-button>
                          </div>
                      <div class="col-xl-12 mb-3">
                        <label > Cargo
                        </label>
                        <b-input type="text"  class="form-control"
                            v-model="user.cargo" disabled>
                        </b-input>
                      </div>
                    </div>
                    <FundForm :user=user 
                      v-if="showFundForm"
                      v-on:OnshowRecord="showRecord"
                      v-on:OnSetFunds="setFunds"
                      v-on:OnHideRecordForm="hideRecordForm"
                      v-on:OnLoadAlert="loadAlert">
                    </FundForm>
                  </div>
                  <hr>
                  <div class="col-xl-6" v-if="showRecordForm">
                    <legend class="mb-1">Historial de Importes:
                      <span class="text-danger"> {{this.project.name}}</span>
                    </legend>
                    <hr>
                    <RecordForm :entity=records :funds=funds
                    :project=project
                    v-on:OnHideRecordForm="hideRecordForm"
                    v-on:OnLoadAlert="loadAlert"
                    v-on:OnSetFunds="setFunds">
                    </RecordForm>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END VIEW -->
      </div>
    </div>
    <div v-if="isLoading">
            <div class="overlay"></div>
            <div class="loading-img"></div>
    </div>
  </div>
</template>

<script>
import { ListSelect } from 'vue-search-select'
import FundForm from "../../components/management/FundForm";
import RecordForm from "../../components/management/RecordForm";
import {Functions} from "../../components/functions/lib.js";


export default {
  name: "Fondos",
  components: {
      ListSelect, FundForm, RecordForm
  },
  data() {
    return {
      hasError: false,
      errorMessage:'',
      errorVariant:'danger',
      errorTittle:'Mensaje de Error: ',
      entity: {},
      items: [],
      filter: null,
      filterOn: [],
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      pageOptions: [10, 25, 50, 100],
      isLoading: false,
      projects: {},
      user: {
        value: '',
        text: ''
      },
      showFundForm: false,
      showRecordForm: false,
      funds: {},
      records: [],
      project: null,
      indexProject: null,
    }
  },
  beforeMount() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.getUser();
    },
    getUser(){
      this.items = [];
      this.isLoading = true;
      this.$api.get('select/users')
          .then(response => {
            this.items = response.data;
            //this.totalRows = (!this.items) ? 0 : this.items.length;
            this.isLoading = false;
          })
          .catch(e => {
            alert(e);
          });
    },
    setTextSelect (item) {
        return `${item.initials} - ${item.name}`
    },
    selectItem(item){
      this.showRecordForm=false;
      this.showFundForm=false;
      delete item.project_id;
      item.project_id=null;
      this.user=item;
      this.records=[];
      this.funds=[];
      this.$nextTick(() => this.showFunds());
    },
    showFunds(){
      this.showFundForm=true;
    },
    //getFund() {
      //this.showFundForm=false;
      //this.showRecordForm=false;
      //this.isLoading=true;
      //this.$api.get("management/fund/" + this.user.id)
          //.then(response => {
              //this.isLoading=false;
              //this.funds=response.data.funds;
              ////this.records=response.data.records;
              //this.$forceUpdate();
              //this.loadRecord();
              //this.showFundForm=true;
          //})
          //.catch(error => {
              //this.isLoading = false;
              //this.loadAlert(error.response.data.message);
          //});
    //},
    //loadRecord(){
      //this.isLoading=true;
      //this.$api.get("management/fundrecord/" + this.user.id+"/"+'all')
          //.then(response => {
              //this.isLoading=false;
              //this.records=response.data;
              //this.$forceUpdate();
          //})
          //.catch(error => {
              //this.isLoading = false;
              //this.loadAlert(error.response.data.message);
          //});
    //},
    getFund() {
      this.showRecordForm=false;
      this.isLoading=true;
      this.$api.get("management/userfund/" + this.user.id + "/" + this.project.id)
          .then(response => {
              this.isLoading=false;
              this.funds=response.data.funds;
              this.records=response.data.records;
              this.showRecordForm=true;
              this.$forceUpdate();
              //this.loadRecord();
              
          })
          .catch(error => {
              this.isLoading = false;
              this.loadAlert(error.response.data.message);
          });
    },
    loadRecord(){
      this.isLoading=true;
      this.$api.get("management/fundrecord/" + this.user.id+"/"+ this.project.id)
          .then(response => {
              this.isLoading=false;
              this.records=response.data;
              this.showRecordForm=true;
              this.$forceUpdate();
          })
          .catch(error => {
              this.isLoading = false;
              this.loadAlert(error.response.data.message);
          });
    },
    loadAlert(message, tittle='Mensaje de Error: ', variant='danger'){
        this.$bvToast.toast(message, {
                    title: tittle,
                    variant: variant,
                    solid: true
                });
    },
    showRecord(item) {
      this.project=item;
      this.getFund();
    },
    hideRecordForm(){
      this.showRecordForm=false;
    },
    setFunds(item) {
      this.funds=item.funds;
      this.records=item.records;
      this.showRecordForm=true;
      //this.$forceUpdate();
    },
     csvExport() {
        if(this.user.id==undefined) {
                  this.loadAlert('Seleccione Usuario Rendidor','Advertencia', 'warning');
                  return;
        }

        this.isLoading=true;
        var userRecords=[]
        this.$api.get("management/fundrecord/" + this.user.id+"/all")
        .then(response => {
            this.isLoading=false;
            userRecords=response.data;
            var arrData = userRecords.map(item => ({
                                //...item,
                                id : item.id,
                                Rendidor : item.usuario.name,
                                Proyecto: item.project.code,
                                Tipo: item.type_record==1 ? 'Asignacion' : 'Rendicion',
                                Descripcion: item.description==null ? '': Functions.replaceChar(item.description),
                                Importe: item.type_record==0 ? '-'+item.amount : item.amount,
                                Documento: item.num_document,
                                Fecha: Functions.formatDate(item.created_at),
                                Usuario_Admin: item.admin.name,
                            }));
            Functions.downloadFileCsv(arrData, 'Resumen_Importes_all.csv');

        })
        .catch(error => {
            this.isLoading = false;
            this.loadAlert(error.response.data.message);
        });

     },
  }
}
</script>


<style scoped>

</style>
