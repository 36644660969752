<template>
    <div>
            <div class="card-header" v-if="showForm">
                        <b-button pill class="col-xl-3" variant="secondary" type="button"  v-on:click="onClosedExpense">
                            <i class="fa fa-backward"></i> Regresar
                        </b-button>
            </div>
                <b-container fluid>
                    <ExpenseForm  :expense="expense"
                                :projects="projects"
                                :docExpenses="docExpenses"
                                :docTypes="docTypes"
                                :docDetraccionPer="docDetraccionPer"
                                :isDisabled="isDisabled"
                                :report="true"
                                v-if="showForm"
                                class="mt-3"
                                v-on:OnUpdated="onUpdated">
                    </ExpenseForm>
                </b-container>

                <div class="mt-4"  v-if="!showForm">
                    <b-row class="mr-1 ml-1">
                                <b-col lg="3" class="offset-md-3">
                                    <b-button pill class="mb-3"
                                            @click="showModal({}, 'modal-3')" 
                                            variant="danger" block
                                            title="Enviar a Contabilidad" v-if="showadminOpt"><i class="fa fa-paper-plane" aria-hidden="true"></i> Enviar Reporte
                                    
                                    </b-button>
                                </b-col>
                                <b-col lg="2">
                                    <b-button pill class="mb-3" variant="outline-success" block v-on:click="sendReportExport" title="Exportar Reporte Excel"><i class="fas fa-download"></i> Exportar Reporte
                                    </b-button>
                                </b-col>
                                <b-col lg="2">
                                    <b-button pill class="mb-3" variant="outline-info" block v-on:click="getSustentosExport" title="Exportar Sustentos PDF"><i class="fas fa-download"></i> Exportar PDFs
                                    </b-button>
                                </b-col>
                                <b-col lg="2">
                                    <b-button pill class="mb-3" variant="outline-dark" block v-on:click="getSustentosXml" title="Exportar Sustentos PDF"><i class="fas fa-download"></i> Exportar XMLs
                                    </b-button>
                                </b-col>
                    </b-row>
                    <div class="card-body">
                        <b-container fluid>
                            <b-row class="my-1">
                            <b-col sm="2" >
                                <label>Titulo</label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input type="text" class="form-control"
                             v-model="expenseReport.title" :disabled=true></b-form-input>
                            </b-col>
                            <b-col sm="2">
                                <label>Fecha Envío</label>
                            </b-col>
                            <b-col sm="4">
                           
                             <b-form-datepicker class="form-control" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" v-model="expenseReport.created_at" :disabled=true>
                                    </b-form-datepicker >

                            </b-col>
                            </b-row>

                            <b-row class="my-1" >
                            <b-col sm="2">
                                <label>Proyecto</label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input  type="text" class="form-control"
                             v-model="expenseReport.project" :disabled=true></b-form-input>
                            </b-col>
                            <b-col sm="2">
                                <label>Responsable</label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input  type="text" class="form-control"
                             v-model="expenseReport.user_name" :disabled=true></b-form-input>
                            </b-col>
                            </b-row>

                            <b-row class="my-1">
                            <b-col sm="2">
                                <label>Área</label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input type="text" class="form-control"
                             v-model="expenseReport.area" :disabled=true></b-form-input>
                            </b-col>
                            <b-col sm="2">
                                <label>Cargo</label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input  type="text" class="form-control"
                             v-model="expenseReport.cargo" :disabled=true></b-form-input>
                            </b-col>
                            </b-row>
                        </b-container>
                            <b-collapse visible id="collapse-1">
                                <b-container fluid>
                                    <b-row class="my-1">
                                    <b-col sm="2">
                                        <label>Fecha Inicio</label>
                                    </b-col>
                                    <b-col sm="4">
                                    <b-form-datepicker class="form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="expenseReport.start_date" placeholder="DD-MM-YYYY" :disabled=true>
                                    </b-form-datepicker >
                                    </b-col>
                                    <b-col sm="2">
                                        <label>Fecha Fin</label>
                                    </b-col>
                                    <b-col sm="4">
                                        <b-form-datepicker class="form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="expenseReport.end_date" placeholder="DD-MM-YYYY" :disabled=true>
                                        </b-form-datepicker >
                                    </b-col>
                                    </b-row>
                                </b-container>
                            </b-collapse>
                    </div>

                    <div class="card-body"  v-if="!showForm">
                       
                        <b-card class="card-fluid">
                            <b-row  style="text-align: right;">
                                <b-col lg="4" class="my-1">
                                    <b-form-group
                                            label="Mostrar registros"
                                            label-cols-sm="4"
                                            label-cols-md="4"
                                            label-cols-lg="4"
                                            label-align-sm="left"
                                            label-size="small"
                                            label-for="perPageSelect"
                                            class="mb-0">
                                        <b-form-select
                                                v-model="perPage"
                                                id="perPageSelect"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="2" class="my-1"></b-col>
                                <b-col lg="6" class="my-1">
                                <b-form-group
                                        label="Buscar registro en la lista: "
                                        label-cols-sm="6"
                                        label-size="small"
                                        label-for="filterInput"
                                        class="mb-0">
                                    <b-input-group size="sm">
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                id="filterInput"
                                                placeholder=""
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Limpiar</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            </b-row>
                            <b-table stacked="md"  hover striped class="table"
                                     ref="table"
                                     id="table-id"
                                     :items="items"
                                     :fields="fields"
                                     :per-page="perPage"
                                     :current-page="currentPage"
                                     :responsive="true"
                                     :busy.sync="isLoading"
                                     :filter="filter"
                                     :filterIncludedFields="filterOn"
                                     thead-class="thead-light"
                                     @filtered="onFiltered"
                                     outlined
                                     small>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong> Cargando...</strong>
                                    </div>
                                </template>
                                <template v-slot:cell(doc_date)="data">
                                    {{ formatdate(data.item.doc_date) }}
                                </template>
                                <template #cell(estado)="data">
                                        <span v-bind:class="classStatus[data.item.status]">
                                        {{data.item.doc_status}}
                                        </span>
                                </template>
                                <template #cell(card_name)="data">
                                        {{ data.item.card_name==null ? 'No Aplica' : data.item.card_name }}
                                </template>
                                <template #cell(total)="data">
                                        <sub><span class="badge badge-secondary">
                                        PEN
                                        </span></sub> {{data.item.expense_amount}}
                                </template>
                                <template v-slot:cell(options)="data">
                                    <b-button pill variant="warning" size="sm" @click="showExpense(data.item)"
                                              class="mr-1"  title="Ver Gasto">
                                        <i class="fa fa-eye"></i>
                                    </b-button>
                                    <b-button pill size="sm" @click="showModal(data.item, 'modal-4')" 
                                              variant="danger" class="mr-1 " v-if="showadminOpt"  title="Observar">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </b-button>
                                    <b-button pill size="sm" @click="showModal(data.item, 'modal-1')" 
                                              variant="success" class="mr-1 " v-if="showadminOpt"  title="Aprobar">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </b-button>
                                    <b-button pill size="sm" @click="showModal(data.item, 'modal-2')" 
                                              variant="dark" class="mr-1 " style="width: 33px;" v-if="showadminOpt"  title="Rechazar">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </b-button>
                                </template>
                            </b-table>
                            <b-row class="align-items-center">
                                <div class="col-sm-12 col-md-5">
                                    Mostrando un total de <b>{{ totalRows }}</b> registro(s).
                                </div>

                                <b-col class="col-sm-12 col-md-7 d-flex justify-content-end">
                                    <b-pagination
                                            ref="pagination"
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                            align="fill"
                                    ></b-pagination>
                                </b-col>
                            </b-row>
                        </b-card>
                        <div class="metric-row">
                            <div class="col-12 col-sm-6 col-lg-4">
                      <div class="card-metric btn-subtle-success">
                        <div class="metric">
                          <p class="metric-value h3">
                            <sub><i class="">PEN</i></sub> <span class="value">{{formatNumber(expenseReport.expenses_total)}}</span>
                          </p>
                          <h2 class="metric-label"> Total Rendido </h2>
                        </div>
                      </div>
                    </div>
                    <!-- metric column -->
                    <div class="col-12 col-sm-6 col-lg-4">
                      <!-- .metric -->
                      <div class="card-metric btn-subtle-success">
                        <div class="metric">
                          <p class="metric-value h3">
                            <sub><i class="fas fa-wallet"></i></sub> <span class="value">{{expenseReport.approved}} <b> / </b> {{expenseReport.expenses_num}}</span>
                          </p>
                          <h2 class="metric-label"> # Gastos Aprobados </h2>
                        </div>
                      </div><!-- /.metric -->
                    </div><!-- /metric column -->
                    <!-- metric column -->
                    <div class="col-12 col-sm-6 col-lg-4">
                      <!-- .metric -->
                      <div class="card-metric btn-subtle-success">
                        <div class="metric">
                          <p class="metric-value h3">
                            <sub><i class="">PEN</i></sub> <span class="value">{{formatNumber(expenseReport.expenses_approved)}}</span>
                          </p>
                          <h2 class="metric-label"> Total Aprobado </h2>
                        </div>
                      </div><!-- /.metric -->
                    </div><!-- /metric column -->
                    </div>
                    </div>
                </div>
       
    <div>
    <b-modal ref="modal-1" title="Aprobar Gasto" centered @ok="AprobarItem" header-bg-variant="light" header-text-variant='primary' footer-bg-variant="light" footer-text-variant='primary' @hidden="resetModal">
        <p class="my-4">¿Aprobar gasto con<b> ID: {{ this.expense.id }} </b>por el Importe de: <b>  {{this.expense.expense_amount}}</b> ?</p>
        <template #modal-footer="{ ok, cancel }">
            <a @click="cancel()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;"><strong>Cancelar</strong></a>
            <a @click="ok()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;" ><strong>Aprobar</strong></a>
        </template>
    </b-modal>
    </div>
    <div>
    <b-modal ref="modal-2" title="Rechazar Gasto" @hidden="resetModal" @ok="handleOk" centered header-bg-variant="light" header-text-variant='primary' footer-bg-variant="light" footer-text-variant='primary' >
        <br>
        <p class="my-2">¿Rechazar gasto con<b> ID: {{ this.expense.id }}</b> ?</p>
        <form ref="form" @submit.stop.prevent="checkFormValidity('form')" @submit="handleSubmit(3, 'modal-2', 'form') ">
            <b-form-group
            label="Indique Motivo de Rechazo"
            label-for="decline_comment"
            invalid-feedback="Motivo es requerido"
            :state="nameState"
            >
            <b-form-input
                id="decline_comment"
                v-model="expense.decline_comment"
                :state="nameState" maxlength="255"
                required
            ></b-form-input>
            </b-form-group>
        </form>

        <template #modal-footer="{ ok, cancel }">
            <a @click="cancel()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;"><strong>Cancelar</strong></a>
             <a @click="ok()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;" ><strong>Rechazar</strong></a>
        </template>
    </b-modal>
    </div>
    <div>
    <b-modal ref="modal-3" title="Enviar Reporte" centered @ok="setStatusReport" header-bg-variant="light" header-text-variant='primary' footer-bg-variant="light" footer-text-variant='primary'>
        <p class="my-4">¿Cerrar y Enviar Reporte con<b> ID: {{this.expenseReport.id }} - Titulo: "{{this.expenseReport.title}}" </b> ?</p>
        <template #modal-footer="{ ok, cancel }">
            <a @click="cancel()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;"><strong>Cancelar</strong></a>
            <a @click="ok()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;" ><strong>OK</strong></a>
        </template>
    </b-modal>
    </div>

    <div>
    <b-modal ref="modal-4" title="Observar Gasto" @hidden="resetModal" @ok="setObservation" centered header-bg-variant="light" header-text-variant='primary' footer-bg-variant="light" footer-text-variant='primary' >
        <p class="my-2 mb-3">¿Observar gasto con<b> ID: {{ this.expense.id }}</b> con Importe de: <b>{{ this.expense.expense_amount}}</b> ?</p>
        <form ref="form_o" @submit.stop.prevent="checkFormValidity('form_o')" @submit="handleSubmit(4, 'modal-4', 'form_o') ">
            <b-form-group
            label="Indique Observación"
            label-for="observation_comment"
            invalid-feedback="Observación es requerida"
            :state="nameState"
            >
            <b-form-input
                id="observation_comment"
                v-model="expense.observation_comment"
                :state="nameState" maxlength="255"
                required
            ></b-form-input>
            </b-form-group>
        </form>

        <template #modal-footer="{ ok, cancel }">
            <a @click="cancel()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;"><strong>Cancelar</strong></a>
            <a @click="ok()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;" ><strong>Observar</strong></a>
        </template>
    </b-modal>
    </div>


    <b-button type="button" variant="primary" class="btn-md btn-floated hidden-lg" v-if="showForm" v-on:click="onClosedExpense">
            <i class="fas fa-chevron-left fa-2x mr-1"></i>
    </b-button>
    <div v-if="isLoading">
        <div class="overlay"></div>
        <div class="loading-img"></div>
    </div>

    

    </div>
</template>

<!--<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js"></script>-->

<script>
    import * as moment from 'moment'
    import PhotoSwipe from 'photoswipe/dist/photoswipe'
    import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
    import 'photoswipe/dist/photoswipe.css'
    import 'photoswipe/dist/default-skin/default-skin.css'
    import createPreviewDirective from "vue-photoswipe-directive";
    import ExpenseForm from "../employee/ExpenseForm";
    import {Functions} from "../functions/lib.js";

    export default {
        name: "ExpenseReportDetails",
        components: {ExpenseForm},
        directives: {
            preview: createPreviewDirective(null, PhotoSwipe, PhotoSwipeUI)
        },
        props: {Report: Object,  projects: {}, docExpenses: {}, docTypes:{}, docDetraccionPer: {}},
        data() {
            return {
                expenseReport: this.Report,
                fields: [
                    {key: 'id'},
                    {key: 'doc_date', label: 'Fecha', sortable: true},
                    {key: 'card_name', label: 'Proveedor', sortable: true, tdClass: 'sizeTd'},
                    //{key: 'expensedescription.name', label: 'Gasto', sortable: true},
                    {key: 'doc_num', label: 'Documento'},
                    {key: 'total', label: 'Total Rendido'},
                    {key: 'user_revisor_first_name', label: 'Ult. Revisión', tdClass: 'sizeTdname'},
                    {key: 'estado', label: 'Estado'},
                    {key: 'options', label: 'Opcion', tdClass: 'sizeTdopcion'},
                ],
                items: [],
                filter: null,
                filterOn: [],
                totalRows: 0,
                perPage: 25,
                currentPage: 1,
                pageOptions: [25, 60, 100],
                showForm: false,
                isLoading: false,
                isBusy: false,
                errorMessage: '',
                image: '',
                expense:{},
                nameState: null,
                decline_comment:"",
                classStatus: ["badge badge-primary","badge badge-warning","badge badge-success","badge badge-dark", "badge badge-danger"],
                seeTotal: false,
                errorVariant:'danger',
                errorTittle:'Mensaje de Error: ',
                showadminOpt: false,
                isDisabled: true,
            }
        },
        computed: {
            user() {
                return this.$store.getters.user;
            },
            url(){
                return this.$web.defaults.baseURL;
            },
        },
        mounted() {
            this.initialize();
        },
        methods: {
            async initialize() {
                this.validateRole();
                if(this.expenseReport.expenses) {
                    this.items =this.expenseReport.expenses;
                    this.totalRows = this.items.length;
                } else {
                    this.loadExpenses();
                }
               // this.loadOptions();
            },
            loadOptions(){
                this.isBusy = true;
                this.$api.get("select/optExpenses")
                    .then(response => {
                        this.isBusy = false;
                        this.projects=response.data.projects.project;
                        this.docExpenses=response.data.docExpenses;
                        this.docTypes=response.data.docTypes;
                        this.docDetraccionPer=response.data.docDetraccionPer;
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.loadAlert(error.response.data.message);
                });
            },
            loadExpenses(){
                this.isLoading = true;
                this.$api.get('expen/reports/' + this.expenseReport.id) //revisar la carga
                    .then(response => {
                        this.items = response.data;
                        this.expenseReport.expenses= response.data;
                        this.totalRows = this.items.length;
                        this.isLoading = false;
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.onAlert(error.response.data.message);
                    });
            },
            validateRole(){
                if(this.user.role_id==3 && this.expenseReport.status==0) {
                    this.showadminOpt=true;
                }
            },
            onAlert(message, tittle="Mensaje de Error: ", variant="danger"){
                this.$bvToast.toast(message, {
                    title: tittle,
                    variant: variant,
                    solid: true
                });
            },
            onBack(){
                this.$emit('onClosed');
            },
            getImage(image) {
                if(image!==null) {
                    return this.url+image;
                }
                else {
                    return "";
                }
            },
            getStatusName(status) {
                switch (status) {
                    case 1:
                        return "En Revisión";
                    case 2:
                        return "Aprobado";
                    case 3:
                        return "Rechazado";
                    case 4:
                        return "Observado";
                }
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            confirmData(item) {
                this.expense = item;
            },
            resetModal() {
                this.nameState = null
                this.expense = {}
                this.$forceUpdate();
            },
            handleOk(bvModalEvt) {
                bvModalEvt.preventDefault()
                this.handleSubmit(3, 'modal-2', 'form')
            },
            setObservation(bvModalEvt){
                bvModalEvt.preventDefault()
                this.handleSubmit(4, 'modal-4', 'form_o')
            },

            checkFormValidity(form) {
                const valid = this.$refs[form].checkValidity()
                this.nameState = valid
                return valid
            },
            handleSubmit(status, modal, form) {//rechazar
                if (!this.checkFormValidity(form)) {
                    return
                }
                this.expense.status=status;
                this.expense.doc_status=this.getStatusName(status);
                this.expense.user_revisor_first=this.user.id;
                this.expense.user_revisor_first_name=this.user.name;
                this.Update(modal);
            },
            AprobarItem() {
                this.expense.status=2;
                this.expense.doc_status=this.getStatusName(2);
                this.expense.user_revisor_first=this.user.id;
                this.expense.user_revisor_first_name=this.user.name;
                this.Update('modal-1');
            },
            Update(modal){
                this.isLoading = true;
                this.$api.post("expen/expenses", this.expense)
                    .then(response => {
                    console.log(response.data);
                        var x=0;
                        var y=0;
                        var totpen=0;
                        //var totusd=0;
                        this.items.map(function(value) {
                            if(value.status==2) {
                                x++;
                                //if(value.doc_cur=="PEN") {
                                    totpen = parseFloat(value.expense_amount) + parseFloat(totpen);
                                //} else {
                                    //totusd = parseFloat(value.expense_amount) + parseFloat(totusd);
                                //}
                            }
                            if(value.status==3) {
                                y++;
                            }
                        });
                        this.expenseReport.approved=x;
                        this.expenseReport.expenses_approved=this.formatNumber(totpen);
                        //this.expenseReport.expenses_approved_usd=totusd;
                        this.expenseReport.rejected=y;
                        this.expenseReport.last_user_admin_id = this.user.id
                        this.expenseReport.last_user_admin_name = this.user.name
                        this.updateReport();
                        this.onClosed();
                        this.onAlert("Estado modificado correctamente", 'Mensaje de éxito', 'success');
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.onAlert(error.response.data.message);
                    });
                this.$nextTick(() => {
                    this.$refs[modal].hide();
                });
                
            },
            updateReport(){
                this.isLoading = true;
                this.$api.put('expen/reports/' + this.expenseReport.id, this.expenseReport)
                    .then(response => {
                        if(response.data.status==1) {
                            this.onAlert("Reporte cerrado y enviado correctamente", 'Mensaje de éxito', 'success');
                        }
                        this.isLoading = false;
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.onAlert(error.response.data.message);
                    });
            },
            onCalculate(){
                this.isLoading = true;
                this.$api.post('expen/recalculate', this.expenseReport)
                    .then(response => {
                        this.expenseReport = response.data;
                        this.items = this.expenseReport.expenses;
                        this.totalRows = this.items.length;
                        this.isLoading = false;
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.onAlert(error.response.data.message);
                    });
                //this.$emit('OnEditOK', true);
                this.onClosed();
            }, 
            onClosed() {
                this.expense = {};
                //this.$refs.table.refresh();
                this.$nextTick(() => this.$refs.table.refresh());
                this.$forceUpdate();
                this.isLoading = false;               
            },
            onClosedExpense() {
                this.expense = {};
                //this.$refs.table.refresh();
                this.showForm = false;
                this.$emit('OnShowExpense', false)
                this.$forceUpdate();
            },
            showModal(item, modal) {
                this.expense=item;
                this.$refs[modal].show();
            },
            setStatusReport(){
                var total=this.expenseReport.approved + this.expenseReport.rejected;
                //console.log(total);
                if(this.expenseReport.expenses_num == total) {
                    this.expenseReport.status=1;
                    this.expenseReport.report_status='Cerrado';
                    this.showadminOpt=false;
                    this.updateReport();
                } else {
                    this.onAlert('Existen Gastos por revisar', 'Advertencia', 'warning');
                }
            },
            formatdate(created_at) {
               return moment(created_at).format('DD/MM/YYYY');
            },
            showExpense(item){
                this.expense=item;
                this.isDisabled=true;
                if(this.expense.doc_num!=null) {
                    var doc_num = this.expense.doc_num.split('-');
                    if(doc_num.length==2) {
                        this.expense.serie= doc_num[0];
                        this.expense.correlativo= doc_num[1];
                    }
                }
                if(this.user.role_id==3) {
                    if(item.distribution==0 && item.status==1) {
                        this.isDisabled=false;
                    }
                }

                if(this.user.role_id==4) {
                    if(item.distribution==0 && item.status==2) {
                        this.isDisabled=false;
                    }
                }
                this.$emit('OnShowExpense', true)
                this.showForm=true;
            },
            sendReportExport(){
                this.isLoading = true;
                this.$api.post("report/reportexpenses", this.expenseReport)
                .then(response => {
                    this.isLoading = false;
                    var filename=response.data.file;
                    Functions.exportReport(this.url+filename, filename);
                })
                .catch(error => {
                    this.isLoading = false;
                    this.onAlert(error.response.data.message);                        
                });                
            },
            getSustentosExport(){
                this.isLoading = true;
                this.$api.post("report/getfiles", 
                    {'id' : this.expenseReport.id, 
                    'title': this.expenseReport.title}
                    )
                    .then(response => {
                        this.isLoading = false;
                        var file=response.data.file;
                        var filename=response.data.filename;
                        Functions.exportReport(this.url+file, filename);
                        //this.$api({
                            //method: 'get',
                            //url: this.url+file,
                            //responseType: 'arraybuffer'
                        //})
                        //.then(response => {
                            //this.isLoading = false;
                            //Functions.forceFileDownload(response, filename)
                        //})
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.onAlert(error.response.data.message);                        
                    });
            },
            getSustentosXml(){
                this.isLoading = true;
                this.$api.post("report/getfilesxml", 
                    {'id' : this.expenseReport.id, 
                    'title': this.expenseReport.title}
                    )
                    .then(response => {
                        this.isLoading = false;
                        if(response.data.total == 0) {
                            this.onAlert("Este reporte no tiene Sustentos XML para descargar","Advertencia", "warning")
                        } else {
                            var file=response.data.file;
                            var filename=response.data.filename;
                            Functions.exportReport(this.url+file, filename);
                        }
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.onAlert(error.response.data.message);                        
                    });
            },
            onUpdated(item, response) { //GASTO
                this.expense=item;
                if(response.data.totalreport) {
                    this.expenseReport.expenses_total=response.data.totalreport
                }
                if(response.data.approvedreport) {
                    this.expenseReport.expenses_approved=response.data.approvedreport
                }
                this.$forceUpdate();
                this.onAlert('Gasto Modificado Correctamente', 'Mensaje de éxito', 'success');
                this.onClosedExpense();
            },
            formatNumber(nums) {
                return Number(nums).toFixed(2).replace(/(\d)(?=(\d{6})+(?:\.\d+)?$)/g, "$1,");
            },

        }
    }
</script>

<style scoped>

img.zoom {
    width: 3rem;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
}

img.zoom:hover {
    cursor: pointer;
}
@media screen and (min-width: 992px) {
    /deep/ .table > tbody > tr > td.sizeTd {
        width: 300px;
    }

    /deep/ .table > tbody > tr > td.sizeTdname {
        max-width: 180px;
    }

    /deep/ .table > tbody > tr > td.sizeTdopcion {
        width: 180px;
    }
}
/*
/deep/ .table > tbody > tr > td.sizeTdmax {
   width: 300px;
}

/deep/ .table > tbody > tr > td.sizeTdopcion {
   width: 210px;
}*/
 
.overlay,
.loading-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.overlay {
  z-index: 1060;
  background: rgba(255, 255, 255, 0.7);
}
.overlay.dark {
  background: rgba(0, 0, 0, 0.5);
}
.loading-img {
  z-index: 1070;
  background: transparent url('/assets/loading.gif') 50% 50% no-repeat;
}

</style>