<template>
  <div class="page">
    <div class="balert">
        <b-alert class="has-icon" :variant="errorVariant" dismissible fade :show="hasError" 
            @dismissed="hasError=false" role="alert" >
          <div class="alert-icon">
              <span class="oi oi-info"></span>
          </div>
          <span class="font-weight-bold"> {{ errorTittle }} </span> {{errorMessage}}.
        </b-alert>
    </div>
    <div class="page-inner">
      <header class="page-title-bar">
        <div class="container-fluid">
            <h5>Resumen de Importes</h5>
        </div>
      </header>
      <div class="page-section">
        <!-- BEGIN VIEW -->
        <div class="card card-fluid">
          <div class="card-body pt-0">
            <div class="mt-4">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <div class="form-row">
                      <div class="col-xl-4">
                        <label > Usuario Rendidor
                        </label>
                        <list-select :list="users"
                          id="mySelectId"
                          name="mySelectName"
                          option-value="id"
                          :custom-text="setTextUserSelect"
                          :selectedItem="user"
                          @select="selectItemUser"
                          placeholder="Seleccione Usuario Rendidor">
                        </list-select>
                      </div>
                      <div class="col-xl-4 mb-1">
                        <label > Proyecto
                        </label>
                        <list-select :list="projects"
                          id="mySelectId"
                          name="mySelectName"
                          option-value="id"
                          :custom-text="setTextSelect"
                          :selectedItem="project"
                          @select="selectItem"
                          placeholder="Busque y seleccione...">
                        </list-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="showRecordForm">
                  <h6 v-show="records.length>0" class="bg-success text-center text-light hidden-lg">ASIGNACIONES</h6>
                  <b-table  v-show="records.length>0" hover striped class="table table-bordered"
                              :small="true"
                              ref="table"
                              :items="records"
                              :fields="columns"
                              :responsive="true"
                              head-variant="light"
                              stacked="sm"
                              outlined foot-clone>
                          <template v-slot:cell(created_at)="data">
                              <strong> {{ formatdate(data.item.created_at) }} </strong>
                          </template>
                          <template v-slot:cell(importe_pen)="data">
                              <sub><span class="badge badge-secondary">PEN</span></sub> {{ data.item.amount }} 
                          </template>
                          <!--<template v-slot:cell(importe_usd)="data">
                              {{ data.item.currency=='USD' ? data.item.amount : '' }} 
                          </template>-->
                          <template #thead-top>
                                    <b-tr>
                                    <b-th colspan="6" class="bg-success text-center text-light">ASIGNACIONES</b-th>
                                    </b-tr>
                                    <!--<b-tr>
                                    <b-th colspan="1"></b-th>
                                    <b-th colspan="2" class="bg-info text-center text-light">IMPORTE ASIGNADO</b-th>
                                    <b-th colspan="3"></b-th>
                                    </b-tr>-->
                          </template>
                          <template #foot()>
                            <span>-</span>
                          </template>
                          <template #foot(usuario.name)>
                            <span>TOTALES</span>
                          </template>
                          <template #foot(importe_pen)>
                            <div class="text-right">
                              <span class="text-primary"><sub><span class="badge badge-secondary">PEN</span></sub> {{formatAmount(asignado_pen)}}</span>
                            </div>
                          </template>
                          <!--<template #foot(importe_usd)>
                            <span class="text-dark">$ {{asignado_usd}}</span>
                          </template>-->
                    </b-table> 
                    <h6 v-show="reports.length>0" class="bg-success text-center text-light hidden-lg">RENDICIONES</h6>
                    <b-table v-show="reports.length>0" hover striped class="table table-bordered"
                              :small="true"
                              ref="table"
                              :items="reports"
                              :fields="columnstwo"
                              :responsive="true"
                              head-variant="light"
                              stacked="sm"
                              outlined foot-clone>
                          <template #thead-top>
                                    <b-tr>
                                    <b-th colspan="9" class="bg-success text-center text-light">RENDICIONES</b-th>
                                    </b-tr>
                                    <!--<b-tr>
                                    <b-th colspan="2"></b-th>
                                    <b-th colspan="2" class="bg-info text-center text-light">IMPORTE RENDIDO</b-th>
                                    <b-th colspan="2" class="bg-warning text-center text-dark">IMPORTE CONTABLE</b-th>
                                    <b-th colspan="2" class="bg-danger text-center text-light">DIFERENCIA</b-th>
                                    <b-th colspan="1"></b-th>
                                    </b-tr>-->
                          </template>
                          <template #cell(created_at)="data">
                              <strong> {{ formatdate(data.item.created_at) }} </strong>
                          </template>
                          <template v-slot:cell(expenses_total)="data" >
                              <sub><span class="badge badge-secondary">PEN</span></sub> {{ data.item.expenses_total }}
                          </template>
                          <template v-slot:cell(expenses_approved)="data">
                              <sub><span class="badge badge-secondary">PEN</span></sub> {{ data.item.expenses_approved }} 
                          </template>
                          <template #cell(estado)="data">
                                        <span v-bind:class="classStatus[data.item.status]">
                                        {{data.item.report_status}}
                                        </span>
                          </template>
                          <template #cell(dif_pen)="data">
                                       <sub><span class="badge badge-secondary">PEN</span></sub> {{formatAmount(data.item.expenses_total - data.item.expenses_approved)}}
                          </template>
                          <!--<template #cell(dif_usd)="data">
                                        {{formatAmount(data.item.expenses_total_usd - data.item.expenses_approved_usd)}}
                          </template>-->
                          <template #foot()>
                            <span >-</span>
                          </template>
                          <template #foot(expenses_total)>
                            <div class="text-right">
                            <span class="text-primary"><sub><span class="badge badge-secondary">PEN</span></sub> {{formatAmount(rendido_pen)}}</span>
                            </div>
                          </template>
                          <template #foot(expenses_approved)>
                            <div class="text-right">
                            <span class="text-primary"><sub><span class="badge badge-secondary">PEN</span></sub> {{formatAmount(aprobado_pen)}}</span>
                            </div>
                          </template>

                          <template #foot(dif_pen)>
                            <div class="text-right">
                            <span class="text-primary"><sub><span class="badge badge-secondary">PEN</span></sub> {{formatAmount(rendido_pen - aprobado_pen)}}</span>
                            </div>
                          </template>

                          <template #foot(created_at)>
                            <span class="text-right">TOTALES</span>
                          </template>
                    </b-table>

                    <table class="table b-table table-hover table-striped border responsive table-bordered table-sm">
                        <thead class="thead-dark text-center">
                            <tr>
                                <th >SALDO (por Rendicion) (*)</th>
                                <th >SALDO CONTABLE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center text-primary" >
                                  <strong>
                                  <sub><span class="badge badge-secondary">PEN</span></sub> {{formatAmount(asignado_pen - dsctoreal_pen) }}
                                  </strong>
                                </td>
                                <td class="text-center text-primary"><strong><sub><span class="badge badge-secondary">PEN</span></sub> {{formatAmount(asignado_pen - aprobado_pen)}}</strong></td>
                            </tr>
                        </tbody>
                    </table>

                  </div>
                </div>
            </div>
          </div>
        </div>
        <!-- END VIEW -->
      </div>
      <span>(*): Si el estado del Reporte es 'En Revisión', se considera el Importe Rendido</span><br>
      <span>(*): Si el estado del Reporte es 'Cerrado', se considera el Importe Contable</span>
    </div>
    <div v-if="isLoading">
            <div class="overlay"></div>
            <div class="loading-img"></div>
    </div>
  </div>
</template>

<script>
import { ListSelect } from 'vue-search-select'
import * as moment from 'moment'

export default {
  name: "Resumen",
  components: {
      ListSelect
  },
  data() {
    return {
      hasError: false,
      errorMessage:'',
      errorVariant:'danger',
      errorTittle:'Mensaje de Error: ',
      
      isLoading: false,
      projects: [],
      allProjects: [],
      users: [],
      user: {},
      project: {
        value: '',
        text: ''
      },
      showFundForm: false,
      showRecordForm: false,
      funds: {},
      records: {},
      reports: {},
      columns: [
                    {key: 'usuario.name', label: 'Rendidor'},
                    {key: 'project.code', label: 'Proyecto'},
                    {key: 'importe_pen', label: 'IMPORTE', tdClass: 'tdClass'},
                    //{key: 'importe_usd', label: 'USD'},
                    {key: 'description', label: 'Descripcion'},
                    {key: 'num_document', label: 'Documento'},
                    {key: 'created_at', label: 'Fecha Asignacion'},
                ],
      columnstwo: [
                    {key: 'created_at', label: 'Fecha'},
                    {key: 'user_name', label: 'Rendidor'},
                    {key: 'title', label: 'Reporte'},
                    {key: 'expenses_total', label: 'IMPORTE RENDIDO', tdClass: 'tdClass'},
                    //{key: 'expenses_total_usd', label: 'USD'},
                    {key: 'expenses_approved', label: 'IMPORTE CONTABLE', tdClass: 'tdClass'},
                    //{key: 'expenses_approved_usd', label: 'USD'},
                    {key: 'dif_pen', label: 'DIFERENCIA', tdClass: 'tdClass'},
                    //{key: 'dif_usd', label: 'USD'},
                    {key: 'estado', label: 'Estado'},
                ],
      classStatus: ["badge badge-warning","badge badge-dark"],
      asignado_pen: 0,
      asignado_usd: 0,
      rendido_pen: 0,
      rendido_usd: 0,
      aprobado_pen: 0,
      aprobado_usd: 0,
      dsctoreal_pen: 0,
      dsctoreal_usd: 0,
    }
  },
  beforeMount() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.getUser();
      this.getProjects();
    },
    getUser(){
      this.users = [];
      this.isLoading = true;
      this.$api.get('select/users')
          .then(response => {
            this.users = response.data;
            this.isLoading = false;
          })
          .catch(error => {
            this.loadAlert(error.response.data.message);
          });
    },
    getProjects(){
      this.allProjects = [];
      this.isLoading = true;
      this.$api.get('select/projects')
          .then(response => {
            this.allProjects = response.data;
            this.projects = this.allProjects;
            this.isLoading = false;
          })
          .catch(error => {
            this.loadAlert(error.response.data.message);
          });
    },
    setTextUserSelect (item) {
        return `${item.initials} - ${item.name}`
    },
    selectItemUser(item) {  
      this.user=item;
      this.project = {};
      this.projects = [];    
      //
      if(item.project!= undefined) {
        this.projects = Object.assign([], item.project);
        if(this.projects.length > 0)
          this.projects.push({id: '0', code : 'All', name: 'Todos'})
      } else {
        this.projects = this.allProjects;
      }
    },
    setTextSelect (item) {
        return `${item.code} - ${item.name}`
    },
    selectItem(item){
      this.project=item;
      if(this.user.id != undefined ) {
        this.getFund();
      } else {
        this.user.id = 0;
        this.getFund();  
      }
    },
    getFund() {
      this.showRecordForm=false;
      this.isLoading=true;
      this.$api.post("employee/record/" + this.user.id+"/"+this.project.id, {projects: this.projects})
          .then(response => {
              this.isLoading=false;
              this.records=response.data.records;
              this.reports=response.data.reports;
              this.calculateTotal();
          })
          .catch(error => {
              this.isLoading = false;
              this.loadAlert(error.response.data.message);
          });
    },
    formatdate(created_at) {
                return moment(created_at).format('DD/MM/YYYY');
    },
    loadAlert(message, tittle='Mensaje de Error: ', variant='danger'){
        this.$bvToast.toast(message, {
                    title: tittle,
                    variant: variant,
                    solid: true
                });
    },
    calculateTotal(){
      var iapen=0;
      var iausd=0;
      this.records.map(function(value) {
          if(value.currency=='PEN'){
            iapen=iapen+parseFloat(value.amount);
          } else {
            iausd=iausd+parseFloat(value.amount);
          }
      });
      this.asignado_pen=iapen;
      this.asignado_usd=iausd;
      this.$forceUpdate();

      var pen=0;
      var conta_pen=0;
      var real_pen=0;
      this.reports.map(function(value) {
          if(value.status==1) {
            real_pen=real_pen + parseFloat(value.expenses_approved);
          } else {
            real_pen=real_pen + parseFloat(value.expenses_total);
          }

          pen=pen + parseFloat(value.expenses_total);

          conta_pen=conta_pen + parseFloat(value.expenses_approved);
      });
      this.rendido_pen=pen;
      this.aprobado_pen=conta_pen;
      this.dsctoreal_pen=real_pen;
      this.showRecordForm=true;
      this.$forceUpdate();

    },
    formatAmount(amount) {
      return amount.toFixed(2).replace(/(\d)(?=(\d{6})+(?:\.\d+)?$)/g, "$1,");
    }

  }
}
</script>


<style scoped>

/deep/ .table > tbody > tr > td.tdClass {
   text-align: right;
}
</style>


