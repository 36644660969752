import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

Vue.config.productionTip = false

//import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

var env = "test" //dev - test - pro
var url = null
    switch (env) {
        case "pro":
            url = "https://apicr.calaminon.pe/"
            break
        case "test":
            url = "https://apirendicion.aynicom.pe/"
            break
        case "dev":
            url = "http://127.0.0.1:8000/"
            break
    }

Vue.prototype.$api = axios.create({
    baseURL: url + 'api/',
    environment: env 
})

Vue.prototype.$web = axios.create({
    baseURL: url,
    environment: env
})

Vue.prototype.$api.defaults.timer="3000000";

const token = localStorage.getItem('user-token');

Vue.prototype.$api.defaults.headers.common["Authorization"] =`Bearer ${token}`;
Vue.prototype.$web.defaults.headers.common["Authorization"] =`Bearer ${token}`;

Vue.prototype.$api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
     if ((error.response.status==401 || error.response.status==403 ) && error.config.url !="auth/login") {
      switch (error.response.status) {
        case 401:
        case 403:
            store.commit('AUTH_LOGOUT')
            localStorage.removeItem('user-token');
            localStorage.removeItem('user');
            localStorage.removeItem('user-status');
            router.replace({ path: '/login'/*, query: { redirect: router.currentRoute.fullPath }*/ })
          break;
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
