<template>
    <div>
        <!-- TABLE HEADER -->
        <b-row style="text-align: right;" class="mt-3">
            <b-col lg="4" class="my-1">
                <b-form-group
                        label="Mostrar registros"
                        label-cols-sm="4"
                        label-cols-md="4"
                        label-cols-lg="4"
                        label-align-sm="left"
                        label-size="small"
                        label-for="perPageSelect"
                        class="mb-0 small">
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="2" class="my-1"></b-col>
            <b-col lg="6" class="my-1">
                <b-form-group
                        label="Buscar registro en la lista: "
                        label-cols-sm="6"
                        label-size="small"
                        label-for="filterInput"
                        class="mb-0 small">
                    <b-input-group size="sm">
                        <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder=""
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Limpiar</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <!-- TABLE CONTENT -->
        <b-table stacked="sm" hover striped class="table"
                 :small="small"
                 ref="table"
                 :items="items"
                 :fields="this.columns"
                 :per-page="perPage"
                 :current-page="currentPage"
                 :responsive="true"
                 :busy.sync="isBusy"
                 :filter="filter"
                 :filterIncludedFields="filterOn"
                 thead-class="thead-light"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :sort-direction="sortDirection"
                 @filtered="onFiltered"
                 outlined>
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando...</strong>
                </div>
            </template>
            <template v-slot:cell(status)="data">
                    <b-button :variant="getStatusClass(data.item.status)" size="sm" @click="showModal(data.item, 'modal-1')">
                    {{ getStatusName(data.item.status) }}
                    </b-button>
            </template>
            <template v-slot:cell(options)="data">
                <b-button class="mr-1" size="sm" @click="onUpdateEvent(data.item)">
                    <i class="fa fa-edit"></i>Editar
                </b-button>
            </template>
        </b-table>
        <!-- TABLE FOOTER -->
        <b-row class="align-items-center">
            <div class="col-sm-12 col-md-5">
            </div>
            <b-col class="col-sm-12 col-md-7 d-flex justify-content-end">
                <b-pagination
                        ref="pagination"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                ></b-pagination>
            </b-col>
        </b-row>

        <b-modal ref="modal-1" title="Estado"  size="sm" centered @ok="onDeleteEvent()"  @hidden="resetModal" header-bg-variant="light" header-text-variant='primary' footer-bg-variant="light" footer-text-variant='primary' >
        <p class="my-2">¿Desea {{ this.messageModal }} item con<b> ID: {{ this.object.id }}</b> ?</p>
        <template #modal-footer="{ ok, cancel }">
            <a @click="ok()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;" ><strong>OK</strong></a>
            <a @click="cancel()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;"><strong>Cancelar</strong></a>
        </template>
        </b-modal>

    <div v-if="isLoading">
        <div class="overlay"></div>
        <div class="loading-img"></div>
    </div>

    </div>

</template>

<script>
    import * as moment from 'moment'
    export default {
        name: "UCDatatable",
        props: {
            columns: Array,
            items: Array,
            isBusy: Boolean,
            small: Boolean,
        },
        data() {
            return {
                filter: null,
                filterOn: [],
                totalRows: 0,
                perPage: 25,
                currentPage: 1,
                pageOptions: [25, 50, 75, 100],
                showForm: false,
                isEdit: false,
                hasError: false,
                errorMessage: '',
                object: {},
                messageModal: 'Inactivar',
                isLoading: false,
                sortBy: 'id',
                sortDesc: true,
                sortDirection: 'desc',
            }
        },
        mounted() {
            this.initialize();
        },
        methods: {
            initialize() {
                this.totalRows = (!this.items) ? 0 : this.items.length;
                this.isBusy = false;
            },
            confirmDelete(item) {
                this.object = item;
            },
            onUpdateEvent(item) {
                this.$emit('OnUpdateEvent', item);
            },
            onDeleteEvent() { //desactivar
                this.$emit('OnDeleteEvent', this.object);
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            getStatusName(status){
                //return status + "Activo";
                switch (status) {
                    case 0:
                        return "Inactivo";
                    case 1:
                        return "Activo";
                }
            },
            getStatusClass(status) {
                switch (status) {
                    case 0:
                        // Inactivo
                        return "danger";
                    case 1:
                        return "success";
                }
            },
            showModal(item, modal) {
                this.object=item;
                if(this.object.status==0){
                this.messageModal='activar';
                } else {
                this.messageModal='inactivar';
                }
                this.$refs[modal].show();
            },
            resetModal() {
                this.object={};
            },
            formatdate(created_at) {
                return moment(created_at).format('DD/MM/YYYY');
            },
        }
    }
</script>

<style scoped>

</style>