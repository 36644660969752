<template>
  <div class="col-md-6 mb-3">
    <form  ref="form" @submit.prevent="checkFormValidity('form')" @submit="clickSave">
      <!-- .form-row -->
      <div class="form-row">
        <div class="col-md-12 mt-3">
          <label class=" font-weight-bold"> Nombre de Departamento<abbr
              title="Requerido">*</abbr></label>
          <b-input type="text"
                   class="form-control"
                   v-model="entity.name" required minlength="3">
          </b-input>
        </div>
        <div class="col-md-12 mt-3 mb-3">
          <label class=" font-weight-bold"> Descripción
          </label>
          <b-input type="text"
                   class="form-control"
                   v-model="entity.remarks">
          </b-input>
        </div>
      </div><!-- /.form-row -->
      <div class="form-actions col-md-6 offset-md-6" >
        <b-button class="btn-block" type="submit" variant="primary" >
          <span class="menu-icon fa fa-save"></span>
          <span class="menu-text"> Guardar</span>
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "DepartmentForm",
  props: {
    entity: Object
  },
  data() {
    return {
      nameState: null,
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
    },
    checkFormValidity(form) {
      const valid = this.$refs[form].checkValidity()
      this.nameState = valid
      return valid
    },
    clickSave() {
      if (!this.checkFormValidity('form')) {
        return;
      } 
      this.$emit("OnSaveEvent", this.entity);
    }
  },
}
</script>