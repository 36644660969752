import * as types from '../../mutation-types'

export default {
    [types.AUTH_REQUEST](state) {
        state.status = 'loading'
    },
    [types.AUTH_SUCCESS]: (state, token) => {
        state.status = 'success'
        state.token = token
    },
    [types.AUTH_ERROR]: (state) => {
        state.status = 'error'
    },
    [types.USER]: (state, user) => {
        state.user = user
    },
    [types.MENU]: (state, menu) => {
        state.menu = menu
    },
    [types.AUTH_LOGOUT](state) {
        state.status = 'logout';
        state.token = null;
    }
}
