<template>
    <div>
    <div class="page">
        <div class="page-inner" :class="getWidth()">
            <header class="page-title-bar">
                <div class="container-fluid">
                    <h5>Reporte de Gastos</h5>
                    <p class="d-block text-muted">A continuación puedes ver todos los reportes de gastos creados y enviados.</p>
                </div>
            </header>
                <div id="base-style" class="card" >
                    <div class="card-header" v-if="!showExpense">
                        <b-button  class="col-xl-2" pill variant="secondary" type="button" v-if="(showForm || showReport) && !showExpense " v-on:click="onClosed">
                            <i class="fa fa-backward"></i> Regresar
                        </b-button>
                        <div class="form-row col-xl-12">
                            <div class="col-xl-2  mb-2">
                                <b-button pill v-on:click="create" block v-if="!showForm && !showReport" variant="danger">
                                    <i class="fa fa-plus"></i> Crear Reporte
                                </b-button>
                            </div>
                             <div class="col-xl-2">
                                <b-button pill block v-if="!showForm && !showReport" v-on:click="refresh" variant="danger">
                                    <i class="fas fa-sync-alt"></i> Actualizar
                                </b-button>
                             </div>
                        </div>
                    </div>
                    <ExpenseReportDetails :Report="expenseReport"
                                        v-if="showReport"
                                        :projects="projects"
                                        :docExpenses="docExpenses"
                                        :docTypes="docTypes"
                                        :docDetraccionPer="docDetraccionPer"
                                        v-on:onClosed="onClosed"
                                        v-on:OnShowExpense="onShowExpense">
                    </ExpenseReportDetails>
                    <ExpenseReportForm v-bind:expense-report="expenseReport"
                                        :projects="projects"
                                        v-if="showForm"
                                        v-on:OnCreated="onCreated"
                                        v-on:OnClosed="onClosed"
                                        v-on:OnUpdated="onUpdated">
                    </ExpenseReportForm>
                    <div class="card-body">
                        <div v-show="(!showForm && !showReport)">
                             <b-row>
                                <b-col lg="2" class="offset-md-10 mb-2">
                                    <b-button pill class="btn-md" variant="outline-success" block type="button"  v-on:click="csvExport"><i class="fas fa-download"></i> Exportar CSV
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row  style="text-align: right;">
                                <b-col lg="4" class="my-1">
                                    <b-form-group
                                            label="Mostrar registros"
                                            label-cols-sm="4"
                                            label-cols-md="4"
                                            label-cols-lg="4"
                                            label-align-sm="left"
                                            label-size="small"
                                            label-for="perPageSelect"
                                            class="mb-0 small">
                                        <b-form-select
                                                v-model="perPage"
                                                id="perPageSelect"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="2" class="my-1"></b-col>
                                <b-col lg="6" class="my-1">
                                <b-form-group
                                        label="Buscar registro en la lista: "
                                        label-cols-sm="6"
                                        label-size="small"
                                        label-for="filterInput"
                                        class="mb-0 small">
                                    <b-input-group size="sm">
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                id="filterInput"
                                                placeholder=""
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Limpiar</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            </b-row>
                            <b-table stacked="sm" small hover striped class="table"
                                     ref="table"
                                     :items="items"
                                     :fields="fields"
                                     :per-page="perPage"
                                     :current-page="currentPage"
                                     :responsive="true"
                                     :busy.sync="isBusy"
                                     :filter="filter"
                                     :filterIncludedFields="filterOn"
                                     :sort-by.sync="sortBy"
                                     :sort-desc.sync="sortDesc"
                                     :sort-direction="sortDirection"
                                     thead-class="thead-light"
                                     @filtered="onFiltered"
                                     show-empty
                                     outlined>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong> Cargando...</strong>
                                    </div>
                                </template>
                                <template #empty="scope">
                                   <h6 class="text-center mt-2 mb-2"> {{ scope.emptyText ? 'No hay Registros para mostrar' : ''}}</h6>
                                </template>
                                <template #emptyfiltered="scope">
                                    <h6 class="text-center mt-2 mb-2"> {{ scope.emptyFilteredText ? 'Ningun registro coincide con la búsqueda' : ''}}  </h6>
                                </template>
                                <template v-slot:cell(created_at)="row">
                                    {{ formatdate(row.item.created_at) }}
                                </template>
                                <template #cell(estado)="row">
                                        <span v-bind:class="classStatus[row.item.status]">
                                        {{row.item.report_status}}
                                        </span>
                                </template>
                                <template v-slot:cell(options)="row">
                                    <b-button pill class="mr-1"
                                              size="sm"
                                              @click="showExpenseReport(row.item)"
                                              variant="warning"
                                              title="Abrir Reporte">
                                        <i class="fas fa-eye"></i>
                                        <span class="hidden-lg"> Ver Reporte</span>
                                    </b-button>
                                </template>
                            </b-table>
                            <b-row class="align-items-center">
                                <div class="col-sm-12 col-md-5">
                                </div>

                                <b-col class="col-sm-12 col-md-7 d-flex justify-content-end">
                                    <b-pagination
                                            ref="pagination"
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                            align="fill"
                                    ></b-pagination>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </div>
            </div>
        <b-button type="button" variant="primary" class="btn-md btn-floated hidden-lg" v-if="(showForm || showReport) && !showExpense " v-on:click="onClosed">
            <i class="fas fa-chevron-left fa-2x mr-1"></i>
        </b-button>
        <div v-if="isBusy">
            <div class="overlay"></div>
            <div class="loading-img"></div>
        </div>
    </div>
    </div>
</template>

<script>
    import ExpenseReportForm from "../../components/employee/ExpenseReportForm";
    import {Functions} from "../../components/functions/lib.js";
    import ExpenseReportDetails from "../../components/expenses/ExpenseReportDetails";
    const moment = require('moment');
    
    export default {
        name: "ExpenseReports",
        components: {ExpenseReportForm, ExpenseReportDetails},
        computed: {
            user() {
                return this.$store.getters.user;
            },
        },
        mounted() {
            this.initialize();
        },
        data() {
            return {
                expenseReport: {},
                fields: [
                    {key: 'id', label: 'ID', sortable: true},
                    {key: 'title', label: 'Reporte', sortable: true},
                    //{key: 'user.name', label: 'Responsable', sortable: true},
                    {key: 'created_at', label: 'Fecha', sortable: true},
                    {key: 'project', label: 'Proyecto'},
                    {key: 'estado', label: 'Estado'},
                    {key: 'expenses_num', label: 'Registrados'},
                    {key: 'approved', label: 'Aprobados', variant: 'success' },
                    {key: 'rejected', label: 'Rechazados', variant: 'danger'},
                    {key: 'options', label: 'Opciones'},
                ],
                items: [],
                filter: null,
                filterOn: [],
                totalRows: 0,
                perPage: 10,
                currentPage: 1,
                pageOptions: [10, 20, 30],
                sortBy: 'id',
                sortDesc: true,
                sortDirection: 'desc',
                showForm: false,
                showReport: false,
                showExpense: false,
                isEdit: false,
                isBusy: false,
                errorMessage:'',
                errorVariant:'danger',
                errorTittle:'Mensaje de Error: ',
                projects: {},
                itemSelected:{},
                classStatus: ["badge badge-warning","badge badge-dark"],
                folio: 0,
                listReport:true,
                docExpenses: {},
                docTypes: {},
                docDetraccionPer: {},
                
            }
        },
        methods: {
            initialize() {
                this.loadReports();
                this.loadFolio();
                this.loadOptions();
            },
            loadFolio(){
                this.isBusy = true;
                this.$api.get("employee/folio/"+ this.user.id)
                    .then(response => {
                        this.isBusy = false;
                        this.folio=response.data.folio;
                        this.$forceUpdate();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.loadAlert(error.response.data.message);
                });
            },
            loadReports(){
                this.isBusy = true;
                this.$api.get("expen/reports")
                    .then(response => {
                        this.items = response.data;
                        this.totalRows = (!this.items) ? 0 : this.items.length;
                        this.isBusy = false;
                        this.showForm = false;
                    })
                    .catch(error => {
                        this.isBusy = false;
                        //if (error.response.status === 405) {
                            this.loadAlert(error.response.data.message);
                        //}
                    });
            },
            loadOptions(){
                this.isBusy = true;
                this.$api.get("select/optExpenses")
                    .then(response => {
                        this.isBusy = false;
                        this.projects=response.data.projects.project;
                        this.docExpenses=response.data.docExpenses;
                        this.docTypes=response.data.docTypes;
                        this.docDetraccionPer=response.data.docDetraccionPer;
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.loadAlert(error.response.data.message);
                });
            },
            showExpenseReport(expenseReport) {
                this.expenseReport = expenseReport;
                this.showReport = true;
                window.scrollTo(0, 0);
            },
            create() {
                this.expenseReport.title=this.user.initials+'_'+
                                        moment().format('MMYYYY')+'_'+
                                        this.folio;
                this.expenseReport.folio=this.folio;
                this.expenseReport.expense_num = 0;
                this.expenseReport.expense_total = 0;
                this.expenseReport.expense_total_usd = 0;
                this.expenseReport.approved = 0;
                this.expenseReport.rejected=0;
                this.expenseReport.notes = "";
                this.expenseReport.status = 0;
                this.expenseReport.project_id=null;
                this.expenseReport.project=null;
                this.expenseReport.area=this.user.department.name;
                this.expenseReport.cargo=this.user.cargo;
                this.expenseReport.user_name=this.user.name;
                this.expenseReport.user_id=this.user.id;
                this.showForm = true;
            },
            update(expenseReport) {
                this.expenseReport = expenseReport;
                this.showForm = true;
            },
            onCreated(item) {
                this.updateFolio();
                this.items.push(item);
                this.onClosed();
                this.loadAlert('Reporte Creado y enviado correctamente', 'Mensaje de éxito', 'success');
            },
            onUpdated() {
                this.onClosed();
            },
            onClosed() {
                this.expenseReport = {};
                this.totalRows = (!this.items) ? 0 : this.items.length;
                this.showForm = false;
                this.showReport = false;
                this.$refs.table.refresh();
                this.$forceUpdate();
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            formatdate(created_at) {
                return moment(created_at).format('DD/MM/YYYY');
            },
            confirmData(item) {
                this.itemSelected = item;
            },
            loadAlert(message, tittle='Mensaje de Error: ', variant='danger'){
                this.$bvToast.toast(message, {
                    title: tittle,
                    variant: variant,
                    solid: true
                });
            },
            updateFolio(){
                this.isBusy = true;
                this.$api.put("employee/folio/"+ this.user.id)
                    .then(response => {
                        this.isBusy = false;
                        this.folio=response.data.folio;
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.loadAlert(error.response.data.message);
                });
            },
            onShowExpense(value){
                this.showExpense=value;
            },
            csvExport() {
                //var rows = document.getElementsByTagName("table")[0].rows;
                //console.log(rows);
                if(this.items.length==0) {
                    this.loadAlert('No tiene reportes creados', 'Advertencia', 'warning');
                    return;
                }
                var arrData = this.items.map(item => ({
                                //...item,
                                id: item.id,
                                Responsable: item.user_name,
                                Reporte: item.title,
                                'Fecha Rendicion': Functions.formatDate(item.created_at),
                                Proyecto: item.project,
                                Estado: Functions.replaceChar(item.report_status),
                                'Gastos Registrados': item.expenses_num,
                                'Importe Rendido': item.expenses_total,
                                'Importe Aprobado': item.expenses_approved,
                                'Fecha Inicio': item.start_date,
                                'Fecha Fin': item.end_date
                            }));

                Functions.downloadFileCsv(arrData, 'Resumen_ReportesGastos.csv');
            },
            getWidth(){
                if(this.showExpense){
                    return "col-md-8";
                } else return "";
            },
            refresh(){
                this.initialize();
            }

        }
    }
</script>

<style scoped>

.overlay,
.loading-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.overlay {
  z-index: 1060;
  background: rgba(255, 255, 255, 0.7);
}
.overlay.dark {
  background: rgba(0, 0, 0, 0.5);
}
.loading-img {
  z-index: 1070;
  background: transparent url('/assets/loading.gif') 50% 50% no-repeat;
}

</style>
