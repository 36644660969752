<template>
    <div class="page">
        <div class="page-inner">
             <header class="page-title-bar">
                <div class="container-fluid">
                    <h5>{{this.$options.name}}</h5>
                </div>
            </header>
            <div class="page-section">
                <!-- BEGIN VIEW -->
                <div class="card card-fluid">
                    <!-- TOOLBAR -->
                    <UCToolbar v-bind:is-active-form="isActiveForm"
                               v-on:OnCreateEvent="createListener"
                               v-on:OnReadEvent="readListener"
                               v-on:OnBackwardEvent="onBackwardEvent">
                    </UCToolbar>
                    <div class="card-body pt-0">
                        <!-- BODY CARD -->
                        <UCForm v-if="this.isActiveForm"
                                     v-bind:entity="entity"
                                     v-on:OnSaveEvent="saveListener">
                        </UCForm>
                        <UCDatatable v-if="!this.isActiveForm & isActiveTable"
                                     :columns="this.columns"
                                     :items="this.items"
                                     :small=true
                                     :is-busy="this.isBusy"
                                     v-on:OnUpdateEvent="updateListener"
                                     v-on:OnDeleteEvent="deleteListener">
                        </UCDatatable>
                    </div>
                </div>
                <!-- END VIEW -->
            </div>
        </div>
        <div v-if="isLoading">
        <div class="overlay"></div>
        <div class="loading-img"></div>
        </div>
    </div>
</template>

<script>
    import UCForm from "../../components/management/ProjectForm";
    import UCToolbar from "../../components/framework/UCToolbar";
    import UCDatatable from "../../components/framework/UCDatatable";

    export default {
        name: "Proyectos",
        components: {UCDatatable, UCToolbar, UCForm},
        data() {
            return {
              entity: {},
                items: [],
                columns: [
                    {key: 'id', label: 'ID', sortable: true},
                    {key: 'code', label: 'Codigo', sortable: true, tdClass: 'text-uppercase'},
                    {key: 'name', label: 'Nombre de Proyecto', sortable: true, tdClass: 'text-uppercase'},
                    {key: 'validFrom', label: 'Fecha Inicio', sortable: true},
                    {key: 'validTo', label: 'Fecha Fin', sortable: true},
                    {key: 'status', label: 'Estado'},
                    {key: 'options', label: 'Opciones'},
                ],
                isActiveForm: false,
                isActiveTable: false,
                isBusy: false,
                isLoading:false,
                res: null,
            }
        },
        computed: {
            user() {
                return this.$store.getters.user;
            },
        },
        mounted() {
            this.initialize();
        },
        methods: {
            async initialize() {
                this.items = [];
                this.isLoading = true;
                await this.$api.get('management/projects')
                    .then(response => {
                        this.items = response.data;
                        this.isActiveTable=true;
                        this.isLoading = false;
                    })
                    .catch(e => {
                        alert(e);
                    })
            },
            createListener() {
                this.entity = {};
                this.entity.isNew = true;
                this.isActiveForm = true;
                this.$forceUpdate();
            },
            readListener() {
                this.initialize();
            },
            updateListener(item) {
                this.entity = item;
                this.entity.isNew = false;
                this.isActiveForm = true;
                this.$forceUpdate();
            },
            deleteListener(item) {
            this.entity = item;
            
            this.isLoading = true;
            this.$api.delete('management/projects/' + this.entity.id)
                    .then(response => {
                        this.isLoading = false;
                        this.res = response;
                        this.entity.status= this.entity.status==0 ? 1 : 0
                        this.loadAlert('Registrado correctamente', 'Mensaje de éxito', 'success')
                    }).catch(error => {
                    this.isLoading = false;
                    this.loadAlert(error.response.data.message)
                    });
            },
            saveListener(item) {
                this.entity = item;
                this.isActiveForm = false;
                if (this.entity.isNew) {
                  this.isLoading = true;
                  this.$api.post('management/projects', this.entity)
                      .then(response => {
                        this.isLoading = false;
                        this.loadAlert('Registrado correctamente', 'Mensaje de éxito', 'success')
                        this.entity.id=response.data.id;
                        this.entity.status=response.data.status;
                        this.items.push(this.entity);
                        this.onBackwardEvent();
                      })
                      .catch(error => {
                            this.isLoading = false;
                            this.loadAlert(error.response.data.message)
                        });

                } else {
                  this.loadAlert('Registrado correctamente', 'Mensaje de éxito', 'success')
                  this.onBackwardEvent();
                }
            },
            onBackwardEvent() {
                this.isActiveForm = false;
                //this.$nextTick(() => this.$refs.table.refresh());
            },
            loadAlert(message, tittle='Mensaje de Error: ', variant='danger'){
                this.$bvToast.toast(message, {
                            title: tittle,
                            variant: variant,
                            solid: true
                        });
            },
        }
    }
</script>

