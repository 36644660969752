import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    token: localStorage.getItem('user-token') || '',
    status: '',
    user: JSON.parse(localStorage.getItem('user')) || {},
    menu: JSON.parse(localStorage.getItem('menu')) || {},
    system: JSON.parse(localStorage.getItem('system')) || {},
}

export default {
    state,
    getters,
    actions,
    mutations
}
