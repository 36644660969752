<template>
  <div class="page">
    <div class="page-inner">
      <header class="page-title-bar">
                <div class="container-fluid">
                    <h5>Centros de Costos</h5>
                </div>
      </header>
      <div class="page-section">
        <!-- BEGIN VIEW -->
        <div class="card card-fluid">

          <!-- TOOLBAR -->
          <UCToolbar v-bind:is-active-form="isActiveForm"
                     v-on:OnCreateEvent="createListener"
                     v-on:OnReadEvent="readListener"
                     v-on:OnBackwardEvent="onBackwardEvent">
          </UCToolbar>
          <div class="card-body pt-0">
            <!-- BODY CARD -->
            <UCForm v-if="this.isActiveForm"
                    v-bind:entity="this.entity"
                    v-on:OnSaveEvent="saveListener">
            </UCForm>
            <UCDatatable v-if="!this.isActiveForm"
                         :columns="this.columns"
                         :items="this.items"
                         :is-busy="this.isBusy"
                         v-on:OnUpdateEvent="updateListener"
                         v-on:OnDeleteEvent="deleteListener">
            </UCDatatable>
          </div>
        </div>
        <!-- END VIEW -->
      </div>
    </div>
    <div v-if="isLoading">
        <div class="overlay"></div>
        <div class="loading-img"></div>
    </div>
  </div>
</template>

<script>
import UCForm from "../../components/management/CostCenterForm";
import UCToolbar from "../../components/framework/UCToolbar";
import UCDatatable from "../../components/framework/UCDatatable";

export default {
  name: "CostCentersView",
  components: {UCDatatable, UCToolbar, UCForm},
  data() {
    return {
      entity: {},
      items: [],
      columns: [
        {key: 'id', label: 'ID', sortable: true},
        {key: 'code', label: 'Codigo', sortable: true},
        {key: 'name', label: 'Centro de Costo', sortable: true},
        {key: 'status', label: 'Estado'},
        {key: 'options', label: 'Opciones'},
      ],
      isActiveForm: false,
      isBusy: false,
      isLoading:false,
      res: null,
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.items = [];
      this.isBusy = true;
      await this.$api.get('management/costCenters')
          .then(response => {
            this.items = response.data;
            this.isBusy = false;
          })
          .catch(e => {
            alert(e);
          });
    },
    createListener() {
      this.entity = {};
      this.entity.isNew = true;
      this.isActiveForm = true;
      this.$forceUpdate();
    },
    readListener() {
      this.initialize();
    },
    updateListener(item) {
      this.entity = item;
      this.entity.isNew = false;
      this.isActiveForm = true;
      this.$forceUpdate();
    },
    deleteListener(item) {
            this.entity = item;
            this.entity.status==0 ? this.entity.status=1 : this.entity.status=0;
            this.isLoading = true;
            this.$api.put('management/costCenters/' + this.entity.id, this.entity)
                    .then(response => {
                    this.isLoading = false;
                    this.res=response.data;
                    this.$bvToast.toast('Se registró correctamente!', {
                        title: 'Mensaje de Éxito',
                        variant: 'success',
                        solid: true
                    });
                    
                    })
                    .catch(error => {
                    this.isLoading = false;
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Mensaje de Error',
                        variant: 'danger',
                        solid: true
                    });
                    });
    },
    saveListener(item) {
      this.entity = item;
      this.isLoading = true;
      if (this.entity.isNew) {
        this.$api.post('management/costCenters', this.entity)
            .then(response => {
              this.isLoading = false;
              this.$bvToast.toast('Se registró correctamente', {
                title: 'Mensaje de Éxito',
                variant: 'success',
                solid: true
              });
              this.items.push(response.data);
            })
            .catch(error => {
                    this.isLoading = false;
                    this.$bvToast.toast( error.response.data.message, {
                        title: 'Mensaje de Error',
                        variant: 'danger',
                        solid: true
                    });
            });

      } else {
        this.$api.put('management/costCenters/' + this.entity.id, this.entity)
            .then(response => {
              this.isLoading = false;
              this.$bvToast.toast('Se modificó correctamente', {
                title: 'Mensaje de Éxito',
                variant: 'success',
                solid: true
              });
              this.res=response.data;
            })
            .catch(error => {
                    this.isLoading = false;
                    this.$bvToast.toast( error.response.data.message, {
                        title: 'Mensaje de Error',
                        variant: 'danger',
                        solid: true
                    });
            });
      }
      this.isActiveForm = false;
    },
    onBackwardEvent() {
      this.isActiveForm = false;
    }
  }
}
</script>

<style scoped>

</style>