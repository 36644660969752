<template>
  <div>
    <form ref="form" @submit.prevent="checkFormValidity('form')">
                      <div class="form-row">
                        <div class="col-md-6">
                          <label > Proyecto <abbr title="Obligatorio">(*)</abbr>
                          </label>
                          <b-form-group class="font-weight-bold" label-for="project_id">
                            <b-form-select v-model="user.project_id" id="project_id" @change="OnshowRecord" required>
                              <template v-slot:first>
                              <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
                              </template>
                              <b-form-select-option v-for="(option, index) in user.project" v-bind:value="index"  :key=index v-show="option.status==1"> {{ option.code }} - {{ option.name }}
                              </b-form-select-option>
                            </b-form-select>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group label="Importe (*)" label-for="amount">
                            <b-input-group>
                                <b-form-input id="amount" v-model="assignment.amount" type="number" min=1 step="0.001" required :state="amountState"></b-form-input>
                                <b-input-group-append>
                                    <b-form-select id="currency" v-model="assignment.currency" class="custom-select d-block" >
                                        <b-form-select-option value="PEN">PEN</b-form-select-option>
                                        <!--<b-form-select-option value="USD">USD</b-form-select-option>-->
                                    </b-form-select>
                                </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </div>
                        <div class="col-md-12">
                          <label > Descripción
                          </label>
                          <b-input type="text"
                            class="form-control" v-model="assignment.description">
                          </b-input>
                        </div>
                        <div class="col-lg-12 mt-2">
                          <label > Comprobante Depósito Bancario
                          </label>
                          <b-input type="text"
                            class="form-control" v-model="assignment.num_document">
                          </b-input>
                        </div>
                      <div class="mt-3 mr-2 d-flex justify-content-between align-items-center">
                        <div class="col-xs-11 col-md-11  col-lg-11">
                        <span><strong>Fondo Flexible:</strong> El Rendidor podrá enviar reportes aunque se sobrepase el saldo disponible</span> <!-- .switcher-control -->
                        </div>
                        <div class="col-xs-1">
                        <label class="switcher-control switcher-control-lg"><input type="checkbox" class="switcher-input" checked="" v-model="assignment.flexible"> <span class="switcher-indicator"></span> <span class="switcher-label-on">SI</span> <span class="switcher-label-off">NO</span></label> <!-- /.switcher-control -->
                        </div>
                      </div>
                      <div class="form-actions col-md-6 col-sm-12 col-xs-12  offset-md-6 mt-4" >
                        <b-button class="btn-block" type="submit" variant="primary" size="" @click="saveAssignment">
                          <span class="menu-icon fa fa-save"></span>
                          <span class="menu-text"> Asignar</span>
                        </b-button>
                      </div>
                      </div>
    </form>
    <div v-if="isLoading">
            <div class="overlay"></div>
            <div class="loading-img"></div>
    </div>
  </div>
</template>

<script>


export default {
  name: "FundForm",
  components: {},
  props: {
    user: {}, 
  },
  data() {
    return {
      hasError: false,
      errorMessage:'',       
      errorVariant:'danger',
      errorTittle:'Mensaje de Error: ',
      isBusy: false,
      //user: this.user,
      assignment: {
        currency: "PEN",
        project_id: null,
        flexible: true
      },
      isLoading: false
    }
  },
  computed: {
      amountState(){
        if(this.assignment.amount) {
          return this.assignment.amount > 0 ? true : false;
        }
        return null;
      },
      projectState(){
          return this.assignment.project_id == null ? false : true;
      },
  },
  mounted() {
    this.Initialize();
  },
  methods: {
    Initialize() {
      this.assignment = {
        currency: "PEN",
        project_id: null,
        flexible: true,
        amount: null
      }
      this.assignment.flexible=1;
      this.assignment.user_id=this.user.id;
    },
    OnshowRecord(index){
       if(index==null) return;
       this.assignment.project_id=this.user.project[index].id;
       this.$emit('OnshowRecord', this.user.project[index]);
    },
    checkFormValidity(form) {
      const valid = this.$refs[form].checkValidity()
      return valid
    },
    saveAssignment() {
      if (!this.checkFormValidity('form')) {
        return;
      } 
        this.$emit('OnHideRecordForm');
        this.assignment.type_record=1;
        this.isLoading=true;
        this.$api.post("management/fundrecord", this.assignment)
            .then(response => {
                this.isLoading=false;
                this.$emit('OnSetFunds', response.data);
                this.$emit('OnLoadAlert','Importe asignado correctamente',  'Mensaje de éxito', 'success');
                this.assignment.amount="";
                this.assignment.description="";
                this.assignment.num_document="";
                this.$forceUpdate();
            })
            .catch(error => {
                this.isLoading = false;
                this.$emit('OnLoadAlert',error.response.data.message);
        });
    },
  },
}
</script>
