import * as mutations from '../../mutation-types';
import * as actions from '../../action-types';
import Vue from 'vue'

export default {

    [actions.AUTH_REQUEST]({commit}, user) {
        return new Promise((resolve, reject) => {

            commit(mutations.AUTH_REQUEST);

            Vue.prototype.$api.post('auth/login', user)
                .then((resp) => {
                    const token = resp.data.token;
                    localStorage.setItem('user-token', token);
                    Vue.prototype.$api.defaults.headers.common["Authorization"] =`Bearer ${token}`;
                    commit(mutations.AUTH_SUCCESS, token);
                    // you have your token, now log in your user :)
                    commit(mutations.USER, resp.data.user);
                    localStorage.setItem('user', JSON.stringify(resp.data.user));
                    commit(mutations.MENU, resp.data.menu);
                    localStorage.setItem('menu', JSON.stringify(resp.data.menu));
                    localStorage.setItem('system', JSON.stringify(resp.data.user.system[0]));
                    //dispatch(actions.USER_REQUEST)
                    resolve(resp)
                })
                .catch((err) => {
                    commit(mutations.AUTH_ERROR, err)
                    localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
                    reject(err)
                })
        })
    },
    [actions.CONFIG_REQUEST]({commit}) {
        return new Promise((resolve, reject) => {

            Vue.prototype.$api.post('auth/config')
                .then((resp) => {
                    // you have your token, now log in your user :)
                    commit(mutations.USER, resp.data.user);
                    commit(mutations.MENU, resp.data.menu);

                    //dispatch(actions.USER_REQUEST)
                    resolve(resp)
                })
                .catch((err) => {
                    commit(mutations.AUTH_ERROR, err)
                    reject(err);
                })
        })
    },

    [actions.AUTH_LOGOUT]: ({commit}) => {
        return new Promise((resolve) => {

            Vue.prototype.$api.post('auth/logout')
                .then((resp) => {
                    resolve(resp);
                }).catch((error) => {
                console.log(error);
            });
            localStorage.removeItem('user-token');
            localStorage.removeItem('user');
            localStorage.removeItem('menu');
            localStorage.removeItem('system');
            commit(mutations.AUTH_LOGOUT)
            resolve();
        })
    }
}
