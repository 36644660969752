<template>
  <main class="auth">
    <!--<header id="auth-header"
            class="auth-header"
            style="background-image: url('assets/images/illustration/img-1.png')">
      <h1>RINDEGASTOS</h1>
    </header>-->

    <header id="auth-header" class="auth-header" >
        <h1 class="mt-6 mb-4 text-center mb-4">RINDEGASTOS</h1>
    </header>

    <form class="auth-form" ref="form" @submit="login" @submit.prevent="checkFormValidity('form')">
      <div class="alert alert-danger alert-dismissible fade show"
           v-show="hasError">
        <button type="button" class="close" data-dismiss="alert">×</button>
        <strong>Oh No!</strong> - {{ msgError }}
      </div>
      <p class="h6 text-muted">Iniciar Sesión: </p>
      <div class="form-group">
        <div class="form-label-group">
          <b-input
              type="email"
              id="inputUser"
              class="form-control"
              placeholder="Usuario"
              v-model="email"
              ref="email"
              autofocus=""
              Required>
            </b-input>
          <label for="inputUser">Usuario</label>
        </div>
      </div>
      <div class="form-group">
        <div class="form-label-group">
          <b-input
              type="password"
              id="inputPassword"
              class="form-control"
              placeholder="Contraseña"
              v-model="password"
              Required></b-input>
          <label for="inputPassword">Contraseña</label>
        </div>
      </div>
      <div class="form-group">
        <b-button
            type="submit"
            block
            variant="primary"
            :disabled="this.isBusy">
          <span class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-if="isBusy"></span> Ingresar
        </b-button>
      </div>
    </form>
    <br>
    <div class="mt-3 text-muted autores text-center showAutor-sm showAutor-lg">
      <div>
        <b-row>
          <b-col lg="6"><b>Desarrollador:</b> Jenn Huallanca</b-col>
          <b-col lg="6"><b>Coordinador: </b> Jimmy Solis</b-col>
        </b-row>
        <p class="mt-2"><b>Área de Sistemas CALAMINON © 2022</b></p>
        <p class="mt-2"><b>Mesa de Ayuda al </b> <b-link href="https://wa.me/51998328229?text=Hola%2C+Necesito+Apoyo+con+RindeGastos+por+favor." target="_blank"><i class="ml-2 fa-2x fab fa-whatsapp"></i></b-link> </p>
      </div>
    </div>
  </main>
</template>

<script>
import * as actions from '../../store/action-types';

export default {
  name: "index",
  data() {
    return {
      isBusy: false,
      hasError: false,
      msgError: "",
      email: "",
      password: "",
      id: null,
      organization_id:1,
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
      emailState() {
        var emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if(this.email){
          return emailRegex.test(this.email) ? true : false;
        }
        return null;
      },
  },
  methods: {
    async initialize() {
        //if (this.$route.params.id == 1) {
          //this.email = null;
          //this.password = null;
          //this.id = this.$route.params.id;
          //this.login();
        //}
    },
    checkFormValidity(form) {
      const valid = this.$refs[form].checkValidity()
      return valid

    },
    login: function () {

      if (this.isBusy) return;
        this.isBusy = true

      if(!this.checkFormValidity('form')) {
        return;
      }

      const {email, password, id, organization_id} = this;

      this.$store.dispatch(actions.AUTH_REQUEST, {email, password, id, organization_id})
          .then(() => {
            this.$router.push("/dashboard");
            this.$router.go("/dashboard");
          })
          .catch((error) => {
            this.isBusy = false;
            this.$refs.email.focus();
            if(error.response == undefined) {
              this.msgError = "Verifique Ruta de Acceso";
              this.hasError = true;
              return;
            }

            if (error.response.status === 401) {
              this.hasError = true;
              //this.msgError = error.response.statusText + " " + error.response.status;
              this.msgError ="Usuario No Autorizado. Verifique.";
              this.isBusy = false;
              return;
            }
            if (error.response.status === 403) {
              this.hasError = true;
              this.msgError ="Usuario No cuenta con acceso a este Sistema";
              this.isBusy = false;
              return;
            }

              this.msgError = "Problemas de red";
              this.hasError = true;
              return;
          });
    },
  },
};
</script>


