<template>
  <div>
    <multi-list-select            
            :list="List"
            option-value="id"
            :option-text="name"
            :selected-items="items"
            :placeholder="placeholder"
            @select="onSelect"
            @searchchange="printSearchText"
            variant="primary"
            :isDisabled="isDisabled"
          >
    </multi-list-select>
  </div>
</template>

<script>

import 'vue-search-select/dist/VueSearchSelect.css'
//import unionWith from 'lodash/unionWith'
//import isEqual from 'lodash/isEqual'
import { MultiListSelect } from 'vue-search-select'

export default {
  name: "UCComboBoxMultiple",
  components: {MultiListSelect},
  props: {
    url: String,
    List: {},
    data: {},
    placeholder: String,
    isDisabled: Boolean, 
    textname: String,
  },
  data() {
    return {
      searchText: '',
      items: this.data,
      name: this.textname ==null ? 'name' : this.textname,
    }
  },
  mounted(){
    this.initialize();
  },
  methods: {
    initialize() {
        if(this.url!=undefined) {
          this.$api.get(this.url)
          .then((response) => {
            this.List = response.data;
          })
        }
    },
    onSelect (items) {
      this.items = items 
      //this.lastSelectItem = lastSelectItem
      this.$emit("OnSelectEvent", this.items);
    },
    printSearchText (searchText) {
      this.searchText = searchText
    },
  }
}
</script>