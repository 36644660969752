<template>
    <div>
        <div class="mt-3 mb-3" >
            <div id="archivos-pills">
                <form class="form-horizontal form-label-left input_mask" v-if="(entity.status==0 && !isDisabled && entity.distribution==0)  || entity.status==4">
                    <div class="form-row">
                    <div class="col-xl-12" style="margin-bottom: 10px;">
                        <label><span style="background-color: #ee2929;" class="badge badge-danger">Es Obligatorio adjuntar su comprobante y/o el archivo xml !!</span>
                        </label>
                            <b-form-file multiple
                                    v-model="inputficons1"
                                    class="form-control"
                                    placeholder="Elija un archivo..."
                                    drop-placeholder="Suelte archivo aquí..."
                                    :no-drop="droping"
                                    @change="onFileSelected" 
                                    accept="'.pdf', '.xml'"
                                    ref="file-input"
                            ></b-form-file>
                            <label><span class="badge badge-info">pdf</span> <span class="badge badge-info">xml</span>
                            <span class="badge badge-danger">Adjuntar Constancia de Bancarización para Gastos mayores a S/. 2000</span>
                            </label>
                    </div>
                    </div>
                </form>
                <div v-if="entity.expensefile!=undefined" class="list-group list-group-flush list-group-divider">
                    <div v-for="(file, index) in entity.expensefile"  :key=index class="list-group-item">
                        <div class="list-group-item-body">
                            <div class="media align-items-center">
                                <div class="form-row">
                                <div class="media-body">
                                    <b-button size="sm" variant="danger" @click="deleteFile(index, file)"><i class="fa fa-trash"></i>
                                    </b-button> 
                                    <b-link @click="openfile(file.url)" class="ml-2">{{ file.newname }}</b-link>
                                </div>
                                    <div class="col-xl-12">
                                    <figure class="figure mt-2" v-if="file.ext!='pdf' && file.ext!='xml'" >
                                    <div class="figure-img" >
                                        <img v-preview:scope-a v-bind:src="getImage(file.url)" class="zoom mx-auto d-block" :alt="file.originalname" />
                                    </div>
                                    </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <p v-if=" entity.expensefile==undefined || entity.expensefile.length==0 ">Lista Vacía...</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import PhotoSwipe from 'photoswipe/dist/photoswipe'
import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import createPreviewDirective from "vue-photoswipe-directive";

export default {
  name: "PaymentDocumentForm",
  props: {
    entity: Object, isDisabled: Boolean
  },
  directives: {
            preview: createPreviewDirective({fullscreenEl: false,shareEl: false,}, PhotoSwipe, PhotoSwipeUI)
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      inputficons1:[],
      uploadList: [],
      mainProps: { blankColor: '#777', class: 'm1', width: 75, height: 75 },
      droping: true,
    }
  },
  beforeMount() {
            this.Initialize();
            this.url = this.$web.defaults.baseURL;
  },
  methods: {
    Initialize() {
        if(this.entity.id!=undefined && 
            this.entity.expensefile==undefined) {
            this.$emit('OnBusy',true);
            this.$api.get("expen/files/"+ this.expense.id)
                .then(response => {
                    this.entity.expensefile=response.data;
                    this.$emit('OnBusy',false);
                    this.$refs['modalimg'].show();
                })
                .catch(error => {
                    this.$emit('OnBusy',false);
                    this.$emit("OnLoadAlert", error.response.data.message);
                });
        }
    },
    onFileSelected (event) {
        if(this.entity.expensefile==undefined) {
            this.entity.expensefile=[];
        }
        if(event.type=='change') {
            var file = event.target.files;
            var formData = new FormData();
            for (var i = 0; i < file.length; i++) {
                formData.append("inputficons1["+i+"]", file[i]);
            }
            this.$emit('OnBusy',true);
            this.$api.post("upload/store", formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                this.$emit('OnBusy',false);
                if(response.data.upload==true) {
                    if (response.data.files!=undefined) {
                    var listfile=response.data.files;
                    for(var i = 0; i < listfile.length; i++) {
                        this.entity.expensefile.push(listfile[i]);
                    }
                    
                    }
                    if(response.data.noupload!=""){
                        this.$emit("OnLoadAlert", "Documentos no permitidos: " + response.data.noupload);
                    }
                } else {
                    this.$emit("OnLoadAlert", "Documentos no permitidos: " + response.data.msg);
                }
                this.inputficons1=null
                this.$refs['file-input'].reset()
                this.$forceUpdate();
            })
            .catch(error => {
                this.$emit('OnBusy',false);
                this.$emit("OnLoadAlert", error.response.data.message);
            });
        } else {
            console.log(event)
        }
    }, 
    deleteFile(index, file) {
        if(file.id==undefined) {
            this.spliceFile(index);
        } else {
            this.deleteSavedFile(file, index);
        }
    },
    spliceFile(index){
        this.entity.expensefile.splice(index, 1);
        this.$forceUpdate();
    },
    deleteSavedFile(file, index){
      this.$bvModal.msgBoxConfirm('Eliminar Definitivamente el Sustento: '+ file.newname , {
          title: 'Eliminar Sustento',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'OK',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if(!value) return;
          this.$emit('OnBusy',true);
          this.$api.delete("expen/files/"+file.id)
          .then(response => {
              console.log(response);
              this.spliceFile(index);
              this.$emit("OnLoadAlert", 'Sustento eliminado correctamente', 'Respuesta', 'success');
              this.$emit('OnBusy',false);
          })
          .catch(error => {
              this.$emit('OnBusy',false);
              this.$emit(error.response.data.message);
          });
        })
        .catch(err => {
          this.$emit("OnLoadAlert", err.message);
        })
    },
    getImage(image) {
        if(image!=null) {
            return this.url+image;
        }
        else {
            return "";
        }
    },
    openfile(file) {
        /* this.$api({
            method: 'get',
            url: this.url+file,
            responseType: 'arraybuffer'
        })
        .then(response => {
            this.forceFileDownload(response)
        })
        .catch(() => console.log('error occured'))*/
        window.open(this.url+file, '_blank');// forzar descarga de file, falta modificar forcefiledowload
    },
    forceFileDownload(response){
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'download.pdf') //or any other extension
        document.body.appendChild(link)
        link.click()
    },
    getClassFile(ext){
        switch (ext) {
            case "pdf":
                return "fas fa-file-pdf";
            case "xml":
                return "far fa-file-excel";
        }
        return "fas fa-file-image";
    }
  },
}
</script>

<style scoped>

img.zoom {
    width:300px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    border-radius: 0.25rem;
    border: solid 1px #346cb0;
}

img.zoom:hover {
    cursor: pointer;
}
/*
img.minizoom {
    width: 2rem;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
}

img.minizoom:hover {
    -webkit-transform: scale(2); 
    -moz-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(3);
    position: fixed;
    margin-top: 12rem;
    z-index: 99999;
    padding-right: auto !important;;
    padding-left: auto !important;;
}*/

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
input[type=number] { -moz-appearance:textfield; }

</style>