<template>
  <div class="page">
    <div class="page-inner">
      <!-- PAGE TITLE -->
      <header class="page-title-bar" style="padding: 0rem">
        <div class="section-block" style="padding: 0rem">
          <b-alert class="has-icon" fade 
                   :show="dismissCountDown"
                   dismissible
                   @dismissed="dismissCountDown=0"
                   @dismiss-count-down="countDownChanged">
            <div class="alert-icon">
              <span class="fas fa-user-check"></span>
            </div>
            <h4 class="alert-heading">Bienvenido: {{ this.user.name }} </h4>
            <p class="mb-0"> {{ system.description }} </p>
          </b-alert>
        </div>
      </header>

      <!-- PAGE CONTENT -->
      <div class="page-section">
        <div class="card card-fluid">
          <!-- .list-group -->
          <ul class="list-group  list-group-bordered" v-for="menu in menus" :key="menu.id" >
            <router-link v-for="(submenu, index) in menu.items" v-show="submenu.dashShow==1" :to="{ path: submenu.url }" :key="index" append>
              <div >
              <li class="list-group-item" style="border: 0px">
                <div class="list-group-item-figure">
                  <div class="tile avatar has-badge" style="background-color: white;">
                    <span> <i :class="submenu.icon" class="fa-2x text-apple"></i>  </span>

                    <span v-if="submenu.id==10" class="tile tile-xs tile-circle bg-flickr" v-b-tooltip.hover.topright
                          title="Gastos Pendientes">{{pendientes}}</span>

                  </div>
                </div>
                <div class="list-group-item-body">
                    <b-row>
                      <b-col class="text-uppercase col-xl-11 col-md-11 col-sm-11 font-weight-bold">
                        {{ submenu.name }}
                      </b-col>
                      <!--<b-col class="col-xl-1 col-md-1 col-sm-1" style="max-width: 50px;">
                      <span v-if="submenu.id==10" class="badge badge-danger badge-pill" v-b-tooltip.hover.topright
                            title="Gastos Pendientes">{{ pendientes }}</span>
                      </b-col>-->
                    </b-row>
                    <span class="text-apple">{{ submenu.description }}</span>
                </div>
              </li>
              </div>
            </router-link>
          </ul><!-- /.list-group -->
        </div><!-- /.card -->
      </div>

    </div>
  </div>

</template>

<script>
import * as actions from '../store/action-types';

export default {
  name: "Login",
  data() {
    return {
      //password: "",
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: true,
      pendientes: 0,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    system() {
      return this.$store.getters.system;
    },
    menus() {
      return this.$store.getters.menu;
    },
  },
  beforeMount() {
    this.showAlert();
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.$route.params.password=='password') {
         this.loadAlert('Tu Contraseña fue actualizada', 'Mensaje de Éxito','success')
      }
      //if(this.user.role_id==2)
        this.getPendients()
    },
    getPendients(){
      this.$api.get("employee/expen")
          .then(response => {
            this.pendientes = response.data;
          })
          .catch(error => {
            this.isBusy = false;
            if (error.response.status === 405) {
              this.hasError = true;
              this.errorMessage = error.response.data.message;
            }
            if(error.response.status === 401) {
                  this.$store.dispatch(actions.AUTH_LOGOUT)
                  this.$router.push("/login");
            } else {
              this.loadAlert(error.response.data.message)
            }
      });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    loadAlert(message, tittle='Mensaje de Error: ', variant='danger'){
      this.$bvToast.toast(message, {
          title: tittle,
          variant: variant,
          solid: true
      })
    },
  },
}
</script>
<style scoped>

a {
  color: #363642;
  /*text-decoration: none;
  background-color: transparent;*/
}

a:hover {
  color: #346cb0;
  text-decoration: none;
}

</style>

