<template>

  <div>
    <form ref="form" @submit.prevent="checkFormValidity('form')" @submit="clickSave">
      <!-- .form-row --><br>
      <div class="form-row">
        <div class="col-xl-4 mb-3">
          <label class="font-weight-bold"> Nombres y Apellidos:
            <abbr title="Requerido">(*)</abbr>
          </label>
          <b-input type="text"
                   class="form-control"
                   v-model="user.name" Required 
                    :state="nameState">
          </b-input>
        </div>
        <div class="col-xl-4 mb-3">
          <label class="font-weight-bold"> Correo Electronico :
            <abbr title="Requerido">(*)</abbr>
          </label>
          <b-input type="email"
                   class="form-control"
                   v-model="user.email" Required :state="emailState">
          </b-input>
        </div>
        <div class="col-xl-4 mb-3">
          <label class="font-weight-bold"> Iniciales :
            <abbr title="Requerido">* (3 caracteres, solo texto)</abbr>
          </label>
          <b-input type="text"
                   class="form-control"
                   v-model="user.initials" Required minlength="3" maxlength="3" pattern="[a-zA-Z ]{3,3}"
                    :state="initialState" style="text-transform:uppercase;" placeholder="ABC">
          </b-input>
        </div>
        <!--<div class="col-md-6 mb-3">
          <label class="font-weight-bold"> Contraseña :
            <abbr title="Requerido" >*</abbr>
          </label>
          <b-input type="password"
                   class="form-control"
                   v-model="user.password" :state="passwordState">
          </b-input>
        </div>-->
        <div class="col-xl-4 mb-3">
          <label class="font-weight-bold">Rol de Sistema : <abbr title="Requerido">(*)</abbr>
          </label>
          <!--<UCComboBox v-model="user.role_id" url="management/role" :uivalue="'user.role_id'"></UCComboBox>-->
          <select class="custom-select custom-select" v-model="user.role_id" Required>
            <option :value="null" selected>Seleccione una opcion</option>
            <option v-for="item in roles" :value="item.id" :key="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="col-xl-4 mb-3">
          <label class="font-weight-bold">Planilla :
          <abbr title="Requerido">(*)</abbr> </label>
          <select class="custom-select custom-select" v-model="user.planilla">
            <option :value="null" selected>Seleccione una opcion</option>
            <option value="DYNAMIC">DYNAMIC</option>
            <option value="EGA">EGA</option>
            <option value="FRICAM">FRICAM</option>
            <option value="TYS">TYS</option>
          </select>
        </div>
        <div class="col-xl-4 mb-3">
          <label class="font-weight-bold">Departamento :
          <abbr title="Requerido">(*)</abbr> </label>
          <!--<UCComboBox v-model="user.role_id" url="management/role" :uivalue="'user.role_id'"></UCComboBox>-->
          <select class="custom-select custom-select" v-model="user.department_id">
            <option :value="null" selected>Seleccione una opcion</option>
            <option v-for="item in departments" :value="item.id" :key="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="col-xl-4 mb-3">
          <label class="font-weight-bold">Cargo <abbr title="Requerido">(*)</abbr>
          </label>
          <b-input type="text" v-model="user.cargo" Required></b-input>
        </div>
        <div class="col-xl-4 mb-3">
          <label class="font-weight-bold">Centro de Costos :
          </label>
          <!--<UCComboBox v-model="user.role_id" url="management/role" :uivalue="'user.role_id'"></UCComboBox>-->
          <select class="custom-select custom-select" v-model="user.ocrCode">
            <option :value="null" selected>Seleccione una opcion</option>
            <option v-for="item in costCenters" :value="item.id" :key="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="col-xl-8 mb-3">
          <label class="font-weight-bold">Proyectos
          </label>
          <UCComboBoxMultiple v-model="user.project" :List="projects" :data="user.project" v-on:OnSelectEvent="ProjectEvent" placeholder="Busque y Seleccione al menos un proyecto"></UCComboBoxMultiple>
        </div>
        
      </div><!-- /.form-row -->
      <div class="form-actions col-xl-3 col-sm-12 col-xs-12  offset-md-9" >
        <b-button class="btn-block" type="submit" variant="primary" size="" >
          <span class="menu-icon fa fa-save"></span>
          <span class="menu-text"> Guardar</span>
        </b-button>
      </div>
    </form>
    <div v-if="isLoading">
        <div class="overlay"></div>
        <div class="loading-img"></div>
    </div>
  </div>

</template>

<script>

//import UCComboBox from "@/components/framework/UCComboBox";
import UCComboBoxMultiple from "@/components/framework/UCComboBoxMultiple";

export default {
  name: "UserForm",
  components: {UCComboBoxMultiple},
  props: {
    entity: Object, roles: {}, projects: {}, costCenters: {}, departments: {},
  },
  computed: {
      emailState() {
        var emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if(this.user.email){
          return emailRegex.test(this.user.email) ? true : false;
        }
        return null;
      },
      passwordState(){
        if(this.user.password) {
          return this.user.password.length > 5 ? true : false;
        }
        return null;
      },
      nameState(){
        if(this.user.name) {
          return this.user.name.length > 4 ? true : false;
        }
        return null;
      },
      initialState(){
        if(this.user.initials) {
          return this.user.initials.length!=3 ? false : true;
        }
        return null;
      },
  },
  data() {
    return {
      user: this.entity,
      isLoading: false,
      res: null,
    }
  },
  mounted() {
    //this.Initialize();
  },
  methods: {
    Initialize() {
      
    },
    checkFormValidity(form) {
      const valid = this.$refs[form].checkValidity()
      return valid
    },
    clickSave() {
      if (!this.checkFormValidity('form')) {
        return;
      } 

      //if(this.user.role_id==2 && this.user.project.length == 0) {
        //this.$emit("OnLoadAlert", 'Seleccione al menos un proyecto', 'Advertencia', 'warning')
        //return;
      //}
      this.user.initials=this.user.initials.toUpperCase();
      this.isLoading = true;
      if (this.user.isNew) {
        this.user.status=1;
        this.$api.post('management/users', this.user)
            .then(response => {
              this.isLoading = false;
              if(response.data.errors) {
                this.$emit("OnLoadAlert", response.data.message, 'Verifique', 'warning')
              } else {
                this.$emit("OnLoadAlert", 'Usuario Registrado correctamente', 'Mensaje de éxito', 'success')
                this.user=response.data
                this.$emit("OnSaveEvent",  this.user)
              }
            })
            .catch(error => {
              this.isLoading = false;
              this.$emit("OnLoadAlert", error.response.message)
            });
      } else {
        this.$api.put('management/users/' + this.user.id, this.user)
            .then(response => {
              this.isLoading = false;
              if(response.data.errors) {
                this.$emit("OnLoadAlert", response.data.message, 'Verifique', 'warning')
              } else {
                this.user=response.data
                this.$emit("OnLoadAlert", 'Modificado correctamente', 'Mensaje de éxito', 'success')
                this.$emit("OnSaveEvent",  this.user)
              }
            })
            .catch(error => {
              this.isLoading = false;
              this.$emit("OnLoadAlert", error.response.message)
          });
      }

      /*if(this.user.isNew && !this.user.password){
        this.$bvToast.toast('Contraseña es requerida!', {
                title: 'Advertencia',
                variant: 'danger',
                solid: true
              });
        return;
      }*/
      
    },
    ProjectEvent(item) {
      this.user.project=item;
    }
  },
}
</script>
