<template>
    <div>
    <div class="balert">
        <b-alert class="has-icon" :variant="errorVariant" dismissible fade :show="hasError" 
                @dismissed="hasError=false" role="alert" >
                        <div class="alert-icon">
                            <span class="oi oi-info"></span>
                        </div>
                        <span class="font-weight-bold"> {{ errorTittle }} </span> {{errorMessage}}.
        </b-alert>
    </div>
    <div class="card-body" >
    <form>
        <fieldset>
            <div class="form-row">
                <div class="form-group col-xl-6 col-md-6 col-sm-12">
                    <label>Titulo</label>
                    <b-input type="text" class="form-control"
                             v-model="expenseReport.title">
                    </b-input>
                </div>
                <div class="form-group col-xl-6 col-md-6 col-sm-12">
                    <label>Proyecto</label>
                    <b-form-select v-model="expenseReport.project_id" ref="project_id">
                        <template v-slot:first>
                        <b-form-select-option value="null">Seleccione una opción</b-form-select-option>
                        <b-form-select-option v-for="(option, index) in projects" v-bind:value="option.id"  :key=index v-show="option.status==1">
                            {{ option.code }}
                        </b-form-select-option>
                        </template>
                    </b-form-select>
                </div>
                <div class="form-group col-xl-6 col-md-6 col-sm-12">
                    <label>Area</label>
                    <b-input type="text" class="form-control"
                             v-model="expenseReport.area" :disabled=true>
                    </b-input>
                </div>
                <div class="form-group col-xl-6 col-md-6 col-sm-12">
                    <label>Cargo</label>
                    <b-input type="text" class="form-control"
                             v-model="expenseReport.cargo" :disabled=true>
                    </b-input>
                </div>
            </div>
            <!--<div class="form-row">
                <div class="form-group col-xl-4 col-md-4 col-sm-12">
                <label></label>
                <b-form-checkbox class="custom-control" v-model="expenseReport.travel"> ¿Realizó Viaje?
                </b-form-checkbox>
                </div>
            </div>-->
            <b-collapse visible id="collapse-1">
                <div class="form-row">
                    <div class="form-group col-xl-6 col-md-6 col-sm-12">
                        <label>Fecha Inicio</label>
                        <b-form-datepicker class="form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="expenseReport.start_date" placeholder="DD-MM-YYYY">
                        </b-form-datepicker >
                    </div>
                    <div class="form-group col-xl-6 col-md-6 col-sm-12">
                        <label>Fecha Fin</label>
                        <b-form-datepicker class="form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="expenseReport.end_date" placeholder="DD-MM-YYYY">
                        </b-form-datepicker >
                    </div>
                </div>
            </b-collapse>
            <div class="form-actions col-md-3 col-sm-12 col-xs-12  offset-md-9">
                <button class="btn btn-primary btn-md btn-block" type="button" v-on:click="save">
                    <i class="fa fa-save"></i> Guardar
                </button>
            </div>

        </fieldset>
        <div v-if="isBusy">
            <div class="overlay"></div>
            <div class="loading-img"></div>
        </div>
    </form>
    </div>
    </div>
</template>

<script>
    export default {
        name: "ExpenseReportForm",
        props: {expenseReport: {}, projects: {}},
        computed: {
        },
        beforeMount() {
            //this.initialize();
        },
        data: function () {
            return {
                options: [],
                hasError: false,
                errorMessage:'', 
                isBusy: false,
                errorVariant:'danger',
                errorTittle:'Mensaje de Error: ',
            }
        },
        methods: {
            save: function () {
                this.hasError = false;
                if(this.expenseReport.title=="") {
                    this.loadAlert('Ingrese Titulo Válido para el Reporte', 'Advertencia', 'warning');
                    return;
                }
                if(this.expenseReport.project_id==null) {
                    this.loadAlert('Seleccione Proyecto', 'Advertencia', 'warning');
                    return;
                }

                if(this.expenseReport.end_date==null || this.expenseReport.start_date==null) {
                    this.loadAlert('Ingrese Fechas', 'Advertencia', 'warning');
                    return;
                }

                if(this.expenseReport.end_date<this.expenseReport.start_date) {
                    this.loadAlert('Ingrese Fechas Válidas', 'Advertencia', 'warning');
                    return;
                }

                if((this.expenseReport.end_date && !this.expenseReport.start_date && this.expenseReport.travel) || 
                    (!this.expenseReport.end_date && this.expenseReport.start_date && this.expenseReport.travel)) {
                    this.loadAlert('Ingrese ambas las Fechas', 'Advertencia', 'warning');
                    return;
                }

                if (!this.expenseReport.id) {
                    this.isBusy=true;
                    this.$api.post("expen/reports", this.expenseReport)
                        .then(response => {
                            this.isBusy=false;
                            if(response.data.estado==true) {
                                this.$emit('OnCreated', response.data.data);
                            }
                            else {
                                this.loadAlert( response.data.msg, 'Advertencia', 'warning');
                            }
                        })
                        .catch(error => {
                            this.isBusy = false;
                            this.loadAlert(error.response.data.message);
                        });

                }
            },

            loadAlert(message, tittle='Mensaje de Error: ', variant='danger'){
                this.$bvToast.toast(message, {
                    title: tittle,
                    variant: variant,
                    solid: true
                });
            },
        },
    }
</script>

<style scoped>

.overlay,
.loading-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.overlay {
  z-index: 1060;
  background: rgba(255, 255, 255, 0.7);
}
.overlay.dark {
  background: rgba(0, 0, 0, 0.5);
}
.loading-img {
  z-index: 1070;
  background: transparent url('/assets/loading.gif') 50% 50% no-repeat;
}

</style>