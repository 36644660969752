<template>
  <div>
    <p v-if="entity.length==0 || entity==undefined"> Sin Registros</p>
    <div>
    <div class="form-row" v-if="funds!==null">
      <div class="col-lg-12 mb-3">
                    <!-- metric column -->
                    <div class="col-12 col-sm-12 col-lg-12">
                      <div class="card-metric">
                        <div class="metric">
                          <!--<h2 class="metric-label mt-2"><i class="fas fa-wallet"></i> Fondo del proyecto selecionado </h2>-->
                          <h2 class="metric-label mt-2 text-danger"><i :class="funds.flexible==0 ? 'fas fa-lock' : 'fas fa-lock-open'" class="text-danger"></i> {{funds.flexible==0 ? 'Fondo No Flexible' : 'Fondo Flexible'}}</h2>
                          <div>
                          <p class="metric-value h4 text-default" v-if="funds.fund_pen!==null"><sub><span class="badge badge-success">PEN</span></sub> {{ funds.fund_pen }}
                          </p>
                          <p class="metric-value h4 text-default" v-if="funds.fund_usd!==null"><sub><span class="badge badge-success">USD</span></sub> {{funds.fund_usd}}
                          </p>
                          </div>
                        </div>
                      </div>
                      </div><!-- /.metric -->
      </div>
    </div>
    <b-row style="text-align: right;" v-show="entity.length>0">
            <b-col lg="5" class="my-1">
                <b-form-group
                        label="Mostrar registros"
                        label-cols-sm="5"
                        label-cols-md="5"
                        label-cols-lg="5"
                        label-align-sm="left"
                        label-size="small"
                        label-for="perPageSelect"
                        class="mb-0 small">
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="7" class="my-1">
                <b-form-group
                        label="Buscar registro en la lista: "
                        label-cols-sm="6"
                        label-size="small"
                        label-for="filterInput"
                        class="mb-0 small">
                    <b-input-group size="sm">
                        <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder=""
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Limpiar</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    <b-table stacked="sm" v-show="entity.length>0" hover striped class="table small"
                 :small="true"
                 ref="table"
                 :items="entity"
                 :fields="this.columns"
                 :per-page="perPage"
                 :current-page="currentPage"
                 :responsive="true"
                 :busy.sync="isBusy"
                 :filter="filter"
                 :filterIncludedFields="filterOn"
                 thead-class="thead-light"
                 
                 @filtered="onFiltered"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :sort-direction="sortDirection"
                 outlined>
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando...</strong>
                </div>
            </template>
            <template v-slot:cell(created_at)="data">
                 <strong> {{ formatdate(data.item.created_at) }} </strong>
            </template>
            <template v-slot:cell(importe)="data">
                 <strong class="text-danger">{{ data.item.type_record==0 ? '(-) ' : '' }}</strong> {{ data.item.amount +' ' +data.item.currency }} 
            </template>
            <template v-slot:cell(options)="data">
                <b-button variant="danger" size="sm"  v-if="data.item.type_record==1 && data.index==0" class="mr-1" @click="showDeleteRecord(data.item)" title="Eliminar Importe">
                    <i class="fa fa-trash"></i>
                </b-button>
            </template>
      </b-table>
      <b-row class="align-items-center" v-show="entity.length>0">
            <div class="col-sm-12 col-md-5">
            </div>
            <b-col class="col-sm-12 col-md-7 d-flex justify-content-end">
                <b-pagination
                        ref="pagination"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                ></b-pagination>
            </b-col>
        </b-row>  
        
      </div>
      <b-modal ref="modal-1" title="Eliminar Importe"  size="sm" centered @ok="deleteRecord()" header-bg-variant="light" header-text-variant='primary' footer-bg-variant="light" footer-text-variant='primary'>
        <p class="my-2" v-if="this.item!=undefined">¿Desea eliminar este importe: 
          <b> {{ this.item.amount +' ' +this.item.currency }} </b> ?
        </p>
        <template #modal-footer="{ ok, cancel }">
            <a @click="cancel()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;"><strong>Cancelar</strong></a>
            <a @click="ok()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;" ><strong>OK</strong></a>
        </template>
      </b-modal>
      <div v-if="isLoading">
            <div class="overlay"></div>
            <div class="loading-img"></div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
export default {
  name: "RecordForm",
  props: {
    entity: {}, project: Object, funds: {}
  },
  data() {
    return {
      hasError: false,
      errorMessage:'',       
      errorVariant:'danger',
      errorTittle:'Mensaje de Error: ',
      isLoading: false,
      isBusy: false,
      columns: [
                    {key: 'created_at', label: 'Fecha'},
                    {key: 'importe', label: 'Importe'},
                    {key: 'description', label: 'Descripcion'},
                    {key: 'num_document', label: 'Documento'},
                    {key: 'options', label: 'Opcion'}
                ],
      filter: null,
      filterOn: [],
      totalRows: 0,
      perPage: 25,
      currentPage: 1,
      pageOptions: [25, 50, 100],
      item: null,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'asc',
    }
  },
  mounted() {
    this.Initialize();
  },
  methods: {
    Initialize() {
      this.totalRows = (!this.entity) ? 0 : this.entity.length;
    },
    onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
    },
    formatdate(created_at) {
                return moment(created_at).format('DD/MM/YYYY');
    },
    rowClass(data, type) {
        
        if(type !== 'row') return;
        if(data.project_id==null) return;
        if(data.project_id!=this.project.id) return 'd-none';
    },
    showDeleteRecord(item){
      this.item=item;
      //console.log(this.item);
      this.$refs['modal-1'].show();
    },
    deleteRecord(){
      this.isLoading=true;
      this.$api.delete("management/fundrecord/"+ this.item.id)
          .then(response => {
              this.isLoading=false;
              this.item={};
              //this.$emit('OnHideRecordForm');
              this.$emit('OnSetFunds', response.data);
              this.$emit('OnLoadAlert','Importe eliminado correctamente', 'Mensaje de éxito', 'success');
              this.$forceUpdate();
          })
          .catch(error => {
              this.isLoading = false;
              this.$emit('OnLoadAlert',error.response.data.message);
      });
    },

  },
}
</script>
