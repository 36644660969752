<template>
    <div>
        <!-- TABLE HEADER -->
        <b-row>
                                <b-col lg="2" class="offset-md-10 mb-2">
                                    <b-button pill class="btn-md" variant="outline-success" block type="button"  v-on:click="csvExport"><i class="fas fa-download"></i> Exportar CSV
                                    </b-button>
                                </b-col>
        </b-row>
        <b-row  style="text-align: right;">
                                <b-col lg="4" class="my-1">
                                    <b-form-group
                                            label="Mostrar registros"
                                            label-cols-sm="4"
                                            label-cols-md="4"
                                            label-cols-lg="4"
                                            label-align-sm="left"
                                            label-size="small"
                                            label-for="perPageSelect"
                                            class="mb-0 ">
                                        <b-form-select
                                                v-model="perPage"
                                                id="perPageSelect"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="2" class="my-1"></b-col>
                                <b-col lg="6" class="my-1">
                                <b-form-group
                                        label="Buscar registro en la lista: "
                                        label-cols-sm="6"
                                        label-size="small"
                                        label-for="filterInput"
                                        class="mb-0">
                                    <b-input-group size="sm">
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                id="filterInput"
                                                placeholder=""
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Limpiar</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            </b-row>
                            <b-table id="example" stacked="sm" small hover striped class="table"
                                     ref="table"
                                     :items="items"
                                     :fields="fields"
                                     :per-page="perPage"
                                     :current-page="currentPage"
                                     :responsive="true"
                                     :busy.sync="isBusy"
                                     :filter="filter"
                                     :filterIncludedFields="filterOn"
                                     :sort-by.sync="sortBy"
                                     :sort-desc.sync="sortDesc"
                                     :sort-direction="sortDirection"
                                     thead-class="thead-light"
                                     @filtered="onFiltered"
                                     show-empty
                                     outlined>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong> Cargando...</strong>
                                    </div>
                                </template>
                                <template #empty="scope">
                                   <h6 class="text-center mt-2 mb-2"> {{ scope.emptyText ? 'No hay Registros para mostrar' : ''}}</h6>
                                </template>
                                <template #emptyfiltered="scope">
                                    <h6 class="text-center mt-2 mb-2"> {{ scope.emptyFilteredText ? 'Ningun registro coincide con la búsqueda' : ''}}  </h6>
                                </template>
                                <template v-slot:cell(created_at)="row">
                                    {{ formatDate(row.item.created_at) }}
                                </template>
                                <template #cell(estado)="row">
                                        <span v-bind:class="classStatus[row.item.status]">
                                        {{row.item.report_status.replace('ó', 'o')}}
                                        </span>
                                </template>
                                <template v-slot:cell(options)="row">
                                    <b-button pill class="mr-1"
                                              size="sm"
                                              @click="showExpenseReport(row.item)"
                                              variant="warning"
                                              title="Abrir Reporte">
                                        <i class="fas fa-eye"></i>
                                    </b-button>
                                </template>
                            </b-table>
                            <b-row class="align-items-center">
                                <div class="col-sm-12 col-md-5">
                                    Mostrando un total de <b>{{ totalRows }}</b> registros.
                                </div>

                                <b-col class="col-sm-12 col-md-7 d-flex justify-content-end">
                                    <b-pagination
                                            ref="pagination"
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                            align="fill"
                                    ></b-pagination>
                                </b-col>
                            </b-row>
    </div>

</template>

<script>
    import * as moment from 'moment'
    import {Functions} from "../../components/functions/lib.js";
    export default {
        name: "UCDatatable",
        props: {
            fields: Array,
            items: Array,
            isBusy: Boolean,
            small: Boolean,
            totalRows: Number,
        },
        data() {
            return {
                filter: null,
                filterOn: [],
                perPage: 25,
                currentPage: 1,
                pageOptions: [25, 50, 75, 100],
                sortBy: 'id',
                sortDesc: true,
                sortDirection: 'desc',
                showForm: false,
                isEdit: false,
                hasError: false,
                errorMessage: '',
                object: {},
                isLoading: false,
                classStatus: ["badge badge-warning","badge badge-dark"],
            }
        },
        mounted() {
            this.initialize();
        },
        methods: {
            initialize() {
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            formatDate(created_at) {
                return moment(created_at).format('DD/MM/YYYY');
            },
            showExpenseReport(item) {
                this.$emit('OnShowExpenseReport', item);
            },
            csvExport() {
                //var rows = document.getElementsByTagName("table")[0].rows;
                //console.log(rows);
                var arrData = this.items.map(item => ({
                                //...item,
                                id: item.id,
                                Responsable: item.user_name,
                                Reporte: item.title,
                                'Fecha Rendicion': Functions.formatDate(item.created_at),
                                Proyecto: item.project,
                                Estado: Functions.replaceChar(item.report_status),
                                'Gastos Registrados': item.expenses_num,
                                'Importe Rendido': item.expenses_total,
                                'Importe Aprobado': item.expenses_approved,
                                'Fecha Inicio': item.start_date,
                                'Fecha Fin': item.end_date
                            }));

                Functions.downloadFileCsv(arrData, 'Resumen_ReportesGastos.csv');
            },
        }
    }
</script>

<style scoped>

</style>