<template>
  <div class="aside-menu overflow-hidden">
    <nav id="stacked-menu" class="stacked-menu">
      <!-- Main Menu -->
      <ul class="menu">

        <li class="menu-item"  @click="openMenu(null)">
          <router-link class="menu-link" :to="{ path: '/dashboard'}" append>
            <span class="menu-icon oi oi-home"></span>
            <span class="menu-text">Inicio</span>
          </router-link>
        </li>

        <!-- Level 1 -->
        <li v-for="menu in menus" class="menu-item has-child" :class="menu.class" :key="menu.id">

          <a href="#" class="menu-link" @click="openMenu(menu)">
            <span class="menu-icon" :class="menu.icon"></span>
            <span class="menu-text">{{ menu.name }}</span>
          </a>

          <ul class="menu">
            <!-- Level 2 -->
            <li v-for="submenu in menu.items" class="menu-item" :class="submenu.class"
                :key="submenu.id">

              <a href="#" class="menu-link" @click="openMenu(submenu)" :class="submenu.class">
                <span class="menu-icon" :class="submenu.icon"></span>
                <span class="menu-text">{{ submenu.name }}</span>
              </a>
              <!-- Level 3 -->
              <ul v-if="Object.keys(submenu.items).length>0" class="menu">
                <li class="menu-subhead">{{ submenu.name }}</li>
                <li v-for="link in submenu.items" class="menu-item" :key="link.id">

                  <a href="#" class="menu-link" @click="openMenu(link)"
                     tabindex="-1">
                    <span class="menu-icon" :class="link.icon"></span>
                    <span class="menu-text small">{{ link.name }}</span>
                  </a>

                </li>

              </ul>

            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "UCMenu",
  data() {
    return {
      lastMenuItem: {},
      lastFather:{},
    }
  },
  mounted() {
    this.initialize();
  },
  computed: {
    menus() {
      return this.$store.getters.menu;
    },
  },
  methods: {
    async initialize() {

    },
    openMenu(itemMenu) {
      this.lastMenuItem.class = '';
      if(itemMenu===null || itemMenu=="null") {
        this.lastFather.class = '';
        this.lastMenuItem={};
        return;
      }
      //console.log("ULTIMO="+this.lastMenuItem.id);
      //console.log("NUEVO="+itemMenu.id);

      itemMenu.class = 'has-active';
      this.lastMenuItem.class = 'has-open'
      if (itemMenu.fatherId === 0) {
        this.lastFather=itemMenu;
        //itemMenu.class = (itemMenu.class === 'has-active') ? '' : 'has-active';
        //return;
      }

      if (itemMenu.class === "has-child")
        itemMenu.class = 'has-child has-open has-active';
      else if (itemMenu.class === 'has-child has-open')
        itemMenu.class = 'has-child';

      if (itemMenu.isItem) {
        //itemMenu.class = 'has-active';
        this.$router.push(itemMenu.url)
            .catch(err => {
              console.log(err.toString());
            });

        this.$emit('OnCloseMenu');

      }

      this.lastMenuItem = itemMenu;
      this.$forceUpdate();
    }
  }
}
</script>