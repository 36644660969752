<template>
   <div>
        <b-modal ref="modal" title="Obras" @hidden="hideModal()" centered header-bg-variant="light" header-text-variant='primary' footer-bg-variant="light" footer-text-variant='primary' >
        <br>
        <form ref="form" @submit.prevent="checkFormValidity('form')">
        <b-row>
            <b-col lg="12" class="mt-2">
                <label>Partida</label>
                <UCComboSearch v-if="showComboWorks"  :List="departures" :data="entity.projectdeparture" placeholder="Busque y seleccione Partida" v-on:OnSelectEvent="departureEvent" :isDisabled="isDisabled"></UCComboSearch>
            </b-col>

            <b-col lg="12" class="mt-2">
                <label>Obra</label>
                <UCComboBoxMultiple v-if="showComboWorks"  :List="works" :data="entity.projectwork" placeholder="Busque y seleccione Obra(s)" v-on:OnSelectEvent="worksEvent" :isDisabled="isDisabled"></UCComboBoxMultiple>
            </b-col>
        </b-row>
        <br>
        </form>

        <template #modal-footer="{ ok, cancel }">
            <!--<a v-if="!isDisabled" @click="ok()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;" ><strong>OK</strong></a>
            <a v-if="!isDisabled" @click="cancel()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;"><strong>Cancelar</strong></a>-->
            <a @click="cancel()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;"><strong>Cerrar</strong></a>
        </template>

        </b-modal>
        <div v-if="isLoading">
            <div class="overlay"></div>
            <div class="loading-img"></div>
        </div>
    </div>
</template>

<script>
import UCComboBoxMultiple from "../framework/UCComboBoxMultiple";
import UCComboSearch from "../framework/UCComboSearch";

export default {
  name: "ProjectWorkForm",
  components: {UCComboBoxMultiple, UCComboSearch},
  props: {
    entity: Object, isDisabled: Boolean, //projects: {}
  },
  data() {
    return {
      nameState: null,
      isLoading: false,
      items: {},
      works: [],
      departures: [],
      showComboWorks: true,
    }
  },
  mounted() {
    this.Initialize();
  },
  methods: {
    Initialize() {
      
      if(this.entity.projectwork==undefined) this.entity.projectwork=[];

      this.items.project_works=this.entity.projectwork;

      if(this.entity.project_id!=null && !this.isDisabled) {
          this.getWorkForProject();
      }
      this.$forceUpdate();
      this.$nextTick(() => {
                            this.$refs['modal'].show();
                        });
    },
    worksEvent(item){
      this.items.project_works=item;
    },
    departureEvent(item){
      //this.items.project_departure=item;
      this.entity.projectdeparture=item;
      this.entity.project_departure_id=item.id==undefined ? null : item.id;
    },
    hideModal(){
        this.entity.projectwork=this.items.project_works;
        this.$emit('onReplaceEntity', this.entity);
        this.$emit("OnCloseForm");
    }, 
    /*checkFormValidity(form) {
      const valid = this.$refs[form].checkValidity()
      this.nameState = valid
      return valid
    },*/

    getWorkForProject(){
      //console.log(this.items.project_id);
      this.isLoading = true;
      this.showComboWorks=false;
      this.$api.get("select/projectwork/"+this.entity.project_id)
            .then(response => {
                this.works = response.data.works;
                this.departures = response.data.departures;
                this.showComboWorks=true;
                this.isLoading = false;
            })
            .catch(error => {
                this.isLoading = false;
                if (error.response.status === 405) {
                    this.$emit("OnLoadAlert", error.response.data.message);
                }
            })
    },
  },
}
</script>
