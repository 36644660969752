<template>
  <div>
    <form ref="form" @submit.prevent="checkFormValidity('form')"  class="mt-3" @submit="clickSave">
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label>Código de Proyecto</label>
          <b-input type="text"
                   class="form-control text-uppercase"
                   v-model="entity.code"  required  minlength="3">
          </b-input>
        </div>
        <div class="col-md-6 mb-3">
          <label>Nombre de Proyecto<abbr
              title="Requerido">*</abbr></label>
          <b-input type="text"
                   class="form-control text-uppercase"
                   placeholder="Nombre de Proyecto"
                   v-model="entity.name" required minlength="3">
          </b-input>
        </div>
        <div class="col-md-6 mb-3">
          <label>Fecha Inicio</label>
          <b-form-datepicker class="form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  placeholder="Válido de ..." v-model="entity.validFrom">
          </b-form-datepicker>
        </div><!-- /form grid -->
        <div class="col-md-6 mb-3">
          <label>Fecha Fin</label>
          <b-form-datepicker class="form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  placeholder="Válido a ..." v-model="entity.validTo">
          </b-form-datepicker>
        </div>
        <div class="col-md-6 mb-3">
          <label>Partidas <span class="badge badge-info">usa (;) para agregar facilmente</span></label>
          <b-form-tags input-id="tags-basic" 
          v-model="departures" 
          tag-variant="danger"
          tag-pills
          separator=";"
          tag-class="text-uppercase"
          duplicate-tag-text="PARTIDA DUPLICADA: "
          add-button-text="Agregar"
          placeholder="Ingrese Partida(s)"
          size="lg"></b-form-tags>
        </div>
        <div class="col-md-6 mb-3">
          <label>Obras <span class="badge badge-info">usa (;) para agregar facilmente</span></label>
          <b-form-tags input-id="tags-basic" 
          v-model="works" 
          tag-variant="danger"
          tag-pills
          separator=";"
          tag-class="text-uppercase"
          duplicate-tag-text="OBRA DUPLICADA: "
          add-button-text="Agregar"
          placeholder="Ingrese obra(s)"
          size="lg"></b-form-tags>
        </div>
      </div>
      <div class="form-actions col-md-3 col-sm-12 col-xs-12  offset-md-9" >
        <b-button class="btn-block" type="submit" variant="primary">
          <span class="menu-icon fa fa-save"></span>
          <span class="menu-text"> Guardar</span>
        </b-button>
      </div>
    </form>
    <div v-if="isLoading">
        <div class="overlay"></div>
        <div class="loading-img"></div>
        </div>
  </div>
</template>

<script>
export default {
  name: "ProjectForm",
  props: {
    entity: Object
  },
  mounted() {
    this.initialize(this.works, this.departures);
  },
  data() {
    return {
      works: [],
      departures: [],
      isLoading: false,
      nameState: null,
      res: null,
    }
  },
  methods: {
    initialize(works, departures) {
      if(this.entity.works!==undefined) {
        this.entity.works.map(function(value) {
          if(value.name) {
            works.push(value.name);
          } else {
            works.push(value);
          }
        });
      } else {
        this.entity.works=[];
      }

      if(this.entity.departures!==undefined) {
          this.entity.departures.map(function(value) {
            if(value.name) {
              departures.push(value.name);
            } else {
              departures.push(value);
            }
          });
        
      } else {
        this.entity.departures=[];
      }

      this.$forceUpdate();
    },
    checkFormValidity(form) {
      const valid = this.$refs[form].checkValidity()
      this.nameState = valid
      return valid
    },
    clickSave() {
      if (!this.checkFormValidity('form')) {
        return;
      } 
      this.entity.works=this.works;
      this.entity.departures=this.departures;

      if (this.entity.isNew) {
        this.$emit("OnSaveEvent", this.entity);
      } else {
        this.isLoading = true;
        this.$api.put('management/projects/' + this.entity.id, this.entity)
            .then(response => {
              this.isLoading = false;
              this.res = response.data;
              this.$emit("OnSaveEvent", this.entity);
            })
            .catch(error => {
                  this.isLoading = false;
                  this.$bvToast.toast( error.response.data.message, {
                      title: 'Mensaje de Error',
                      variant: 'danger',
                      solid: true
                  });
              });
      }
    }
  },
}
</script>
