<template>

    <div class="page">
            <div class="page-inner" :class="getWidth()">
            <header class="page-title-bar">
                <div class="container-fluid">
                    <h5>Gastos</h5>
                    <p class="d-block text-muted">A continuación puedes ver todos los gastos registrados en tu cuenta.</p>
                </div>
            </header>
            <div class="page-section">
                <div id="base-style" class="card">
                    <div class="card-header">
                        <b-button  class="col-xl-2" pill  block v-if="showForm" v-on:click="onClosed"
                                  variant="secondary">
                            <i class="fa fa-backward"></i> Regresar
                        </b-button>
                        <div class="form-row col-xl-12 ">
                            <div class="col-xl-2 mb-2">
                                <b-button   pill block v-if="!showForm" v-on:click="create" variant="danger">
                                <i class="fa fa-plus"></i> Crear Gasto
                                </b-button>
                            </div>
                             <div class="col-xl-2">
                                <b-button pill block v-if="!showForm" v-on:click="refresh" variant="danger">
                                    <i class="fas fa-sync-alt"></i> Actualizar
                                </b-button>
                             </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <ExpenseForm v-bind:expense="expense"
                                     v-bind:isDisabled="isDisabled"
                                     :projects="projects"
                                     :docExpenses="docExpenses"
                                     :docTypes="docTypes"
                                     :docDetraccionPer="docDetraccionPer"
                                     v-if="showForm"
                                     v-on:OnCreated="onCreated"
                                     v-on:OnClosed="onClosed"
                                     v-on:OnUpdated="onUpdated">
                        </ExpenseForm>
                        <div v-if="!showForm">
                            <b-row>
                                <b-col lg="2" class="offset-md-10 mb-2">
                                    <b-button class="btn-md" pill variant="outline-success" block type="button"  v-on:click="csvExport"><i class="fas fa-download"></i> Exportar CSV
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row style="text-align: right;">
                                <b-col lg="4" class="my-1">
                                    <b-form-group
                                            label="Mostrar registros"
                                            label-cols-sm="4"
                                            label-cols-md="4"
                                            label-cols-lg="4"
                                            label-align-sm="left"
                                            label-size="small"
                                            label-for="perPageSelect"
                                            class="mb-0">
                                        <b-form-select
                                                v-model="perPage"
                                                id="perPageSelect"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="2" class="my-1"></b-col>
                                <b-col lg="6" class="my-1">
                                    <b-form-group
                                            label="Buscar en la lista: "
                                            label-cols-sm="4"
                                            label-size="small"
                                            label-for="filterInput"
                                            class="mb-0">
                                        <b-input-group size="sm">
                                            <b-form-input
                                                    v-model="filter"
                                                    type="search"
                                                    id="filterInput"
                                                    placeholder=""
                                            ></b-form-input>
                                            <b-input-group-append>
                                                <b-button :disabled="!filter" @click="filter = ''">Limpiar</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-table stacked="sm" small hover striped responsive class="table table-striped"
                                     ref="table"
                                     id="table"
                                     :items="items"
                                     :fields="fields"
                                     :per-page="perPage"
                                     :current-page="currentPage"
                                     :busy.sync="isBusy"
                                     :filter="filter"
                                     :filterIncludedFields="filterOn"
                                     thead-class="thead-light"
                                     :sort-by.sync="sortBy"
                                     :sort-desc.sync="sortDesc"
                                     :sort-direction="sortDirection"
                                     @filtered="onFiltered"
                                     show-empty
                                     outlined>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong> Cargando...</strong>
                                    </div>
                                </template>
                                <template #empty="scope">
                                   <h6 class="text-center mt-2 mb-2"> {{ scope.emptyText ? 'No hay Registros para mostrar' : ''}}</h6>
                                </template>
                                <template #emptyfiltered="scope">
                                    <h6 class="text-center mt-2 mb-2"> {{ scope.emptyFilteredText ? 'Ningun registro coincide con la búsqueda' : ''}}  </h6>
                                </template>
                                <template v-slot:cell(doc_date)="row">
                                    {{ formatdate(row.item.doc_date) }}
                                </template>
                                <template #cell(total)="data">
                                        <sub><span class="badge badge-secondary">
                                        PEN
                                        </span></sub> {{formatNumber(data.item.expense_amount)}}
                                </template>
                                <template #cell(id)="data">
                                       {{data.item.id}}
                                </template>
                                <template #cell(card_name)="data">
                                       {{data.item.card_name==null ? "-" :  data.item.card_name }}
                                </template>
                                <template #cell(estado)="data">
                                        <span v-bind:class="getStatusClass(data.item.status)">
                                        {{data.item.doc_status}}
                                        </span>
                                </template>
                                <template v-slot:cell(options)="data">
                                    <b-button-group size="sm">
                                    <b-button pill v-show="data.item.status!=0 && data.item.status!=4" variant="warning" size="sm" @click="update(data.item, true)"
                                              class="mr-1"  title="Ver Gasto">
                                        <i class="fa fa-eye"></i>
                                    </b-button>
                                    <b-button pill v-show="data.item.status==0 || data.item.status==4" variant="success" size="sm" @click="update(data.item, false)" class="mr-1"  title="Modificar Gasto">
                                        <i class="fa fa-edit"></i>
                                    </b-button>
                                    <b-button pill v-show="true" variant="info" size="sm" @click="duplicate(data.item, false)" class="mr-1"  title="Duplicar Gasto">
                                        <i class="fas fa-copy"></i>
                                    </b-button>
                                    <b-button pill v-show="data.item.status==0" variant="dark" size="sm" @click="showModal(data.item, 'modal-1')"  class="mr-1"  title="Eliminar Gasto">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </b-button-group>
                                </template>
                            </b-table>
                            <b-row class="align-items-center">
                                <div class="col-sm-12 col-md-5">
                                </div>
                                <b-col class="col-sm-12 col-md-7 d-flex justify-content-end">
                                    <b-pagination
                                            ref="pagination"
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                            align="fill"
                                    ></b-pagination>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        <b-modal ref="modal-1" title="Eliminar Gasto"  size="sm" centered @ok="destroy()" header-bg-variant="light" header-text-variant='primary' footer-bg-variant="light" footer-text-variant='primary' >
        <p class="my-2">¿Desea eliminar este gasto con<b> ID: {{ this.expense.id }}</b> ?</p>
        <template #modal-footer="{ ok, cancel }">
            <a @click="ok()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;" ><strong>OK</strong></a>
            <a @click="cancel()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;"><strong>Cancelar</strong></a>
        </template>
        </b-modal>
        <b-button type="button" variant="primary" class="mb-5 btn-md btn-floated hidden-lg" v-if="showForm" v-on:click="onClosed">
            <i class="fas fa-chevron-left fa-2x mr-1"></i>
        </b-button>
    </div>
</template>

<script>
    import * as moment from 'moment';
    import {Functions} from "../../components/functions/lib.js";
    import ExpenseForm from "../../components/employee/ExpenseForm";

    export default {
        name: "Expenses",
        components: {ExpenseForm},
        mounted() {
            this.initialize();
        },
        data() {
            return {
                expense: {},
                isDisabled: false,
                fields: [
                    {key: 'id', sortable: true},
                    {key: 'project', label: 'Proyecto', sortable: true},
                    {key: 'doc_date', label: 'Fecha', sortable: true},
                    {key: 'card_name', label: 'Proveedor', sortable: true},
                    {key: 'doc_num', label: 'Documento'},
                    {key: 'total', label: 'Rendicion', tdClass: 'sizeTd'},
                    {key: 'estado', label: 'Estado'},
                    {key: 'options', label: 'Opciones'},
                ],
                items: [],
                filter: null,
                filterOn: [],
                totalRows: 0,
                perPage: 30,
                currentPage: 1,
                pageOptions: [30, 60, 100],
                showForm: false,
                isBusy: false,
                errorMessage:'',
                errorVariant:'danger',
                errorTittle:'Mensaje de Error: ',
                sortBy: 'id',
                sortDesc: true,
                sortDirection: 'desc',
                itemscache: {},
                showWorkForm: false,
                projects: {},
                docExpenses: {},
                docTypes: {},
                docDetraccionPer: {},
            }
        },
        methods: {
            async initialize() {
                await this.loadExpense();
                this.loadOptions();
            },
            loadExpense(){
                this.isBusy = true;
                this.$api.get("employee/expenses")
                    .then(response => {
                        this.items = response.data;
                        this.totalRows = (!this.items) ? 0 : this.items.length;
                        this.isBusy = false;
                        this.showForm = false;
                        if(this.items.length==0) {
                            this.loadAlert('Click en Crear Gasto para Empezar!','No tienes Gastos registrados','success');
                        }
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.loadAlert(error.response.data.message);
                    })
                this.show = false;
            },
            loadOptions(){
                this.isBusy = true;
                this.$api.get("select/optExpenses")
                    .then(response => {
                        this.isBusy = false;
                        this.projects=response.data.projects.project;
                        this.docExpenses=response.data.docExpenses;
                        this.docTypes=response.data.docTypes;
                        this.docDetraccionPer=response.data.docDetraccionPer;
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.loadAlert(error.response.data.message);
                });
            },
            create() {
                this.isDisabled =false;
                const today = new Date().toISOString().slice(0, 10)
                this.expense.doc_expense=null;
                this.expense.doc_descripcion="";
                this.expense.doc_date = today;
                this.expense.doc_total = "";
                this.expense.doc_tax = 0;
                this.expense.doc_subtotal = 0;
                this.expense.total_round = 0;
                this.expense.doc_cur = "PEN";
                this.expense.detraccion = "0";
                this.expense.detraccion_per_id = null;
                this.expense.retencion = "0";
                this.expense.retencion_per = "";
                //this.expense.detraccion_num="NPD";
                this.expense.ocr_code = "92911";
                //this.expense.modular_code= "";
                this.expense.project_id=null;
                this.expense.card_code="";
                this.expense.doc_type_id = null;
                this.expense.image = "";
                this.expense.exchange_rate = "";
                this.expense.inafecto_igv="0";
                this.expense.round=0;
                this.expense.distribution="0";
                this.expense.status=0;
                this.expense.project_departure_id=null;
                this.expense.projectwork=[];
                this.showForm = true;
                window.scrollTo(0, 0);
            },
            update(expense, isDisabled) {
                this.isDisabled = isDisabled;
                this.expense = expense;
                //if(this.expense.distribution==1) {
                   //this.isDisabled = true; 
                //}
                //if(this.expense.doc_num!=null) {
                    //var doc_num = this.expense.doc_num.split('-');
                    //if(doc_num.length==2) {
                        //this.expense.serie= doc_num[0];
                        //this.expense.correlativo= doc_num[1];
                    //}
                //}
                this.showForm = true;
                window.scrollTo(0, 0);
            },
            showModal(item, modal) {
                this.expense=item;
                this.$refs[modal].show();
            },
            closeForm(){
                this.showWorkForm=false;
            },
            destroy() {
                this.isBusy=true;
                this.$api.delete('employee/expenses/' + this.expense.id);
                const index = this.items.indexOf(this.expense);
                if (index > -1) {
                    this.items.splice(index, 1);
                }
                this.loadAlert('Gasto eliminado Correctamente', 'Mensaje de éxito', 'success');
                this.expense= {};
                this.isBusy=false;
            },
            onCreated(item) {
                item.map(value =>{
                    this.items.push(value);
                    this.$forceUpdate();
                });
                this.onClosedpush();
            },
            onUpdated() {
                this.onClosedpush();
            },
            onClosedpush() {
                this.loadAlert('Gasto Registrado Correctamente', 'Mensaje de éxito', 'success');
                this.expense = {};
                this.$nextTick(() => this.$refs.table.refresh());
                this.totalRows = (!this.items) ? 0 : this.items.length;
                this.showForm = false;
                this.$forceUpdate();
            },
            onClosed() {
                if(!this.isDisabled && this.expense.id!== undefined){
                    //this.loadExpense();
                }
                this.expense = {};
                this.showForm = false;
                this.$forceUpdate();
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            getStatusClass(status) {
                switch (status) {
                    case "0":
                        // Pendiente
                        return "badge badge-primary";
                    case "1":
                        // En Revision
                        return "badge badge-warning";
                    case "2":
                        // Aprobado
                        return "badge badge-success";
                    case "3":
                        // Rechazado
                        return "badge badge-dark";
                    case "4":
                        // Observado
                        return "badge badge-danger";
                }
            },
            formatdate(created_at) {
                return moment(created_at).format('DD/MM/YYYY');
            },
            loadAlert(message, tittle='Mensaje de Error: ', variant='danger'){
                this.$bvToast.toast(message, {
                    title: tittle,
                    variant: variant,
                    solid: true
                });

            },
            duplicate(expense, disabled) {
                this.isDisabled =disabled;
                this.expense = Object.assign({}, expense);
                delete this.expense.id;
                delete this.expense.expensefile;
                       this.expense.status=0;
                delete this.expense.doc_status;
                delete this.expense.report_id;
                delete this.expense.user_revisor_first;
                delete this.expense.user_revisor_second;
                delete this.expense.decline_comment;
                if(this.expense.doc_num!=null) {
                    var doc_num = this.expense.doc_num.split('-');
                    if(doc_num.length==2) {
                        this.expense.serie=doc_num[0];
                        this.expense.correlativo=doc_num[1];
                    }
                }
                this.showForm = true;
            },
            csvExport() {
                if(this.items.length==0) {
                    this.loadAlert('No tiene gastos registrados', 'Advertencia', 'warning');
                    return;
                }
                var arrData = this.items.map(item => ({
                                //...item,
                                id: item.id,
                                Proyecto: item.project,
                                Fecha: this.formatdate(item.doc_date),
                                Proveedor: item.card_name,
                                'Descripcion Gasto': Functions.replaceChar(item.expensedescription.name),
                                'Observacion': item.doc_description==null ? '': Functions.replaceChar(item.doc_description),
                                'Importe Rendido' : item.expense_amount,
                                'Estado': Functions.replaceChar(item.doc_status),

                            }));

                Functions.downloadFileCsv(arrData, 'Resumen_Gastos.csv');
                
            },
            getWidth(){
                if(this.showForm){
                    return "col-md-8";
                } else return "";
            },
            refresh(){
                this.initialize();
            },
            formatNumber(nums) {
                return Number(nums).toFixed(2).replace(/(\d)(?=(\d{6})+(?:\.\d+)?$)/g, "$1,");
            },
        }
    }
</script>

<style scoped>
@media screen and (min-width: 601px) {
    /deep/ .table > tbody > tr > td.sizeTd {
        width: 150px;
    }
}
/*
/deep/ .table > tbody > tr > td.sizeTdmax {
   width: 300px;
}

/deep/ .table > tbody > tr > td.sizeTdopcion {
   width: 170px;
}
*/
</style>