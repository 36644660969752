<template>

<div class="page">
    <div class="page-inner">
        <header class="page-title-bar">
            <div class="container-fluid">
                <h5>Consultar Reportes de Gastos</h5>
                <p class="d-block text-muted"></p>
                </div>    
        </header>
        <div class="page-section">
            <div id="base-style" class="card">
                <div class="card-body">
                    <form class="mt-4 mb-4">
                        <div class="form-row">
                            <div class="form-group col-xl-3 mt-1">
                                <label>Seleccionar mes de búsqueda</label>
                                <date-picker v-model="searching.dateRange2" type="month" placeholder="Seleccione Mes"
                                 format="MMMM"
                                 title-format="MM"   
                                 :lang="lang" class="w-100"></date-picker>
                            </div>
                            <div class="form-group col-xl-3 mt-2">
                                <label class="mb-3"> &nbsp;</label>
                                <b-button type="button" class="mt-4" variant="success" @click="searchReporte"><i class="fas fa-search" ></i> Buscar</b-button>
                            </div>     
                        </div> 
                    </form>

                            <!-- TABLE HEADER -->
        <b-row style="text-align: right;" class="mt-3">
            <b-col lg="4" class="my-1">
                <b-form-group
                        label="Mostrar registros"
                        label-cols-sm="4"
                        label-cols-md="4"
                        label-cols-lg="4"
                        label-align-sm="left"
                        label-size=""
                        label-for="perPageSelect"
                        class="mb-0">
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="2" class="my-1"></b-col>
            <b-col lg="6" class="my-1">
                <b-form-group
                        label="Buscar registro en la lista: "
                        label-cols-sm="6"
                        label-size=""
                        label-for="filterInput"
                        class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder=""
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Limpiar</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <!-- TABLE CONTENT -->
        <b-table stacked="sm" hover striped class="table"
                 :small="small"
                 ref="table"
                 :items="items"
                 :fields="fields"
                 :per-page="perPage"
                 :current-page="currentPage"
                 :responsive="true"
                 :busy.sync="isBusy"
                 :filter="filter"
                 :filterIncludedFields="filterOn"
                 thead-class="thead-light"
                 @filtered="onFiltered"
                 show-empty
                 outlined>
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando...</strong>
                </div>
            </template>
            <template #empty="scope">
                <h6 class="text-center mt-2 mb-2"> {{ scope.emptyText ? 'No hay Registros para mostrar' : ''}}</h6>
            </template>
            <template #emptyfiltered="scope">
                <h6 class="text-center mt-2 mb-2"> {{ scope.emptyFilteredText ? 'Ningun registro coincide con la búsqueda' : ''}}  </h6>
            </template>
            <template #cell(estado)="row">
                                        <span v-bind:class="classStatus[row.item.status]">
                                        {{row.item.report_status}}
                                        </span>
            </template>
            <template v-slot:cell(created_at)="row">
                                    {{ formatdate(row.item.created_at) }}
            </template>
            <template v-slot:cell(options)="data">
                <b-button class="mr-1" size="sm" variant="success" @click="sendReportExport(data.item)">
                    <i class="fas fa-file-excel"></i>
                </b-button>
            </template>
        </b-table>
        <!-- TABLE FOOTER -->
        <b-row class="align-items-center">
            <div class="col-sm-12 col-md-5">
            </div>
            <b-col class="col-sm-12 col-md-7 d-flex justify-content-end">
                <b-pagination
                        ref="pagination"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                ></b-pagination>
            </b-col>
        </b-row>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isLoading">
        <div class="overlay"></div>
        <div class="loading-img"></div>
    </div>

</div>
</template>


<script>
    const moment = require('moment');
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/es';
    import {Functions} from "../../components/functions/lib.js";

    export default {
        name: "reports",
        components: { DatePicker  },
        mounted() {
            //this.loadReports();
        },
        computed: {
            url(){
                return this.$web.defaults.baseURL;
            },
        },
        data() {
            return {
                isLoading: false,
                isBusy: false ,
                searching: {
                    dateRange2: new Date(),
                    typeReport: null,
                },
                lang: {
                    formatLocale: {
                        firstDayOfWeek: 1,
                    },
                },
                disabledDates: {
                    from: new Date(),
                },
                stateRe:null,
                filter: null,
                filterOn: [],
                totalRows: 0,
                perPage: 25,
                currentPage: 1,
                pageOptions: [25, 50, 75, 100],
                items: [],
                small: true,
                fields: [
                    {key: 'id', label: 'ID', sortable: true},
                    {key: 'user_name', label: 'Responsable', sortable: true},
                    {key: 'title', label: 'Reporte', sortable: true},
                    {key: 'created_at', label: 'Fecha', sortable: true},
                    {key: 'project', label: 'Proyecto'},
                    {key: 'estado', label: 'Estado'},
                    {key: 'expenses_num', label: 'Registrados'},
                    {key: 'expenses_total', label: 'Imp. Rendido S/.' , variant: 'danger', class: 'text-right' },
                    {key: 'expenses_approved', label: 'Imp. Aprobado S/.', variant: 'success', class: 'text-right'},
                    {key: 'options', label: 'Opciones'},
                ],
                classStatus: ["badge badge-warning","badge badge-dark"],
            }
        },
        methods: {
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            searchReporte(){
                //console.log(moment(this.searching.dateRange2).format('M'))
                this.loadReports(moment(this.searching.dateRange2).format('M'))
            },
            loadReports($month){
                this.isBusy = true;
                this.$api.get("report/searchreports/" + $month)
                    .then(response => {
                        this.items = response.data;
                        this.totalRows = (!this.items) ? 0 : this.items.length;
                        this.isBusy = false;
                        this.showForm = false;
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.loadAlert(error.response.data.message);
                    });
            },
            loadAlert(message, tittle='Mensaje de Error: ', variant='danger'){
                this.$bvToast.toast(message, {
                    title: tittle,
                    variant: variant,
                    solid: true
                });
            },
            sendReportExport(item){
                this.isLoading = true;
                this.$api.post("report/reportexpenses", item)
                .then(response => {
                    this.isLoading = false;
                    var filename=response.data.file;
                    Functions.exportReport(this.url+filename, filename);
                })
                .catch(error => {
                    this.isLoading = false;
                    this.onAlert(error.response.data.message);                        
                });                
            },
            formatdate(created_at) {
                return moment(created_at).format('DD/MM/YYYY');
            },
        }
    }
</script>

