<template>
  <div class="page">
    <div class="page-inner">
      <header class="page-title-bar">
                <div class="container-fluid">
                    <h5>{{ this.$options.name}}</h5>
                </div>
      </header>
      <div class="page-section">
        <!-- BEGIN VIEW -->
        <div class="card card-fluid">
          <UCToolbar v-bind:is-active-form="isActiveForm"
                     v-on:OnCreateEvent="createListener"
                     v-on:OnReadEvent="readListener"
                     v-on:OnBackwardEvent="onBackwardEvent">
          </UCToolbar>
          <!-- <div class="card-header">
              <b-button v-show="!isActiveForm && !isActiveFormData" size="sm" variant="primary" tabindex="0"
                            class="border-left border-right-0"
                            @click="readListener">
                  <i class="fas fa-sync-alt"></i> Actualizar
              </b-button>
              <b-button v-show="isActiveForm || isActiveFormData" size="sm" variant="primary" tabindex="0"
                            class="border-left border-right-0"
                            @click="onBackwardEvent">  regresa y actualiza para reestablecer cambios no guardado
                  <i class="fa fa-backward"></i> Regresar
              </b-button>
          </div>-->
          <div class="card-body pt-0">
            <!-- BODY CARD -->
            <UCForm v-if="this.isActiveForm"
                    v-bind:entity="entity"
                    v-bind:roles="roles"
                    v-bind:projects="projects"
                    v-bind:costCenters="costCenters"
                    v-bind:departments="departments"
                    v-on:OnSaveEvent="saveListener"
                    v-on:OnLoadAlert="loadAlert">
            </UCForm>
            <div v-if="!isActiveForm & isActiveTable">
              <!-- TABLE HEADER -->
        <b-row style="text-align: right;">
            <b-col lg="4" class="my-1">
                                    <b-form-group
                                            label="Mostrar registros"
                                            label-cols-sm="4"
                                            label-cols-md="4"
                                            label-cols-lg="4"
                                            label-align-sm="left"
                                            label-size="small"
                                            label-for="perPageSelect"
                                            class="mb-0 ">
                                        <b-form-select
                                                v-model="perPage"
                                                id="perPageSelect"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select>
                                    </b-form-group>
            </b-col>
            <b-col lg="2" class="my-1"></b-col>
            <b-col lg="6" class="my-1">
                <b-form-group
                        label="Buscar registro en la lista: "
                        label-cols-sm="6"
                        label-size="small"
                        label-for="filterInput"
                        class="mb-0 small">
                    <b-input-group size="sm">
                        <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder=""
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Limpiar</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <!-- TABLE CONTENT -->
        <b-table stacked="sm" small hover striped  class="table"
                 ref="table"
                 :items="items"
                 :fields="columns"
                 :per-page="perPage"
                 :current-page="currentPage"
                 :responsive="true"
                 :busy.sync="isBusy"
                 :filter="filter"
                 :filterIncludedFields="filterOn"
                 thead-class="thead-light"
                 @filtered="onFiltered"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :sort-direction="sortDirection"
                 outlined>
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando...</strong>
                </div>
            </template>
            <template v-slot:cell(status)="data">
                    <b-button :variant="getStatusClass(data.item.status)" size="sm" @click="showModal(data.item, 'modal-1')">
                    {{ getStatusName(data.item.status) }}
                    </b-button>
            </template>
            <template v-slot:cell(options)="data">
                <b-button class="mr-1" size="sm" @click="updateListener(data.item)">
                    <i class="fa fa-edit"></i> Editar
                </b-button>
            </template>
        </b-table>
        <!-- TABLE FOOTER -->
        <b-row class="align-items-center">
            <div class="col-sm-12 col-md-5">
            </div>
            <b-col class="col-sm-12 col-md-7 d-flex justify-content-end">
                <b-pagination
                        ref="pagination"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                ></b-pagination>
            </b-col>
        </b-row>
            </div>
          </div>
        </div>
        <!-- END VIEW -->
      </div>
    </div>
    <b-modal ref="modal-1" title="Estado"  size="sm" centered @ok="deleteListener()"  @hidden="resetModal" header-bg-variant="light" header-text-variant='primary' footer-bg-variant="light" footer-text-variant='primary' >
        <p class="my-2">¿Desea {{ this.messageModal }} usuario con<b> ID: {{ this.entity.id }}</b> ?</p>
        <template #modal-footer="{ ok, cancel }">
            <a @click="ok()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;" ><strong>OK</strong></a>
            <a @click="cancel()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;"><strong>Cancelar</strong></a>
        </template>
    </b-modal>

    <div v-if="isLoading">
        <div class="overlay"></div>
        <div class="loading-img"></div>
    </div>

  </div>
</template>

<script>
import UCForm from "../../components/management/UserForm";
import UCToolbar from "../../components/framework/UCToolbar";

export default {
  name: "Usuarios",
  components: {UCToolbar, UCForm},
  data() {
    return {
      entity: {},
      items: [],
      columns: [
        {key: 'id', label: 'ID', sortable: true},
        {key: 'name', label: 'Usuario', sortable: true},
        {key: 'email', label: 'Correo Electronico', sortable: true},
        {key: 'role.name', label: 'Rol', sortable: true},
        {key: 'status', label: 'Estado'},
        {key: 'options', label: 'Opciones'},
      ],
      isActiveForm: false,
      isActiveTable: false,
      isBusy: false,
      isLoading: false,
      projects: {},
      filter: null,
      filterOn: [],
      totalRows: 0,
      perPage: 25,
      currentPage: 1,
      pageOptions: [25, 50, 75, 100],
      showForm: true,
      isActiveFormData: false,
      roles: {},
      messageModal: 'inactivar',
      costCenters: {},
      departments: {},
      res: null,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
    }
  },
  beforeMount() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.loadUser();
      this.$api.get('management/role')
          .then((response) => {
            this.roles = response.data;
          });
      this.$api.get('select/projects')
          .then((response) => {
            this.projects = response.data;
          });
      this.$api.get('select/departments')
          .then((response) => {
            this.departments = response.data;
          });
      this.$api.get('select/costCenters')
          .then((response) => {
            this.costCenters = response.data;
          });
    },
    loadUser(){
      this.items = [];
      this.isLoading = true;
      this.$api.get('management/users')
          .then(response => {
            this.items = response.data;
            this.totalRows = (!this.items) ? 0 : this.items.length;
            this.isLoading = false;
            this.isActiveTable=true;
          }).catch(error => {
              this.isLoading = false;
              this.loadAlert(error.response.data.message)
            });
    },
    createListener() {
      this.entity = {};
      this.entity.role_id=null;
      this.entity.isNew = true;
      this.entity.project = [];
      this.entity.department_id=null;
      this.entity.ocrCode=null;
      this.entity.planilla=null
      this.isActiveForm = true;
      this.$forceUpdate();
    },
    readListener() {
      this.initialize();
    },
    updateListener(item) {
      this.entity = item;
      this.entity.isNew = false;
      this.isActiveForm = true;
      this.$forceUpdate();
    },
    deleteListener() {
      this.entity.status=this.entity.status==0 ? 1 : 0;
      this.isLoading = true;
      this.$api.put('management/users/' + this.entity.id, this.entity)
            .then(response => {
              this.isLoading = false;
              this.res = response;
              this.loadAlert('Modificado correctamente', 'Mensaje de éxito', 'success')
              //this.onBackwardEvent();
            })
            .catch(error => {
              this.isLoading = false;
              this.loadAlert(error.response.data.message)
            });
     
    },
    saveListener(item) {
      this.isActiveForm = false;
      if (item.isNew) {
          this.items.push(item);
      }
      //this.onBackwardEvent();
      
    },
    onBackwardEvent() {
      this.isActiveForm = false;
      //this.isActiveFormData = false;
      this.$nextTick(() => this.$refs.table.refresh());
    },
    onBackward() {
      this.loadUser();
      this.onBackwardEvent();
    },
    getStatusClass(status) {
                switch (status) {
                    case 0:
                        // Inactivo
                        return "danger";
                    case 1:
                        return "success";
                }
    },
    getStatusName(status){
      //return status + "Activo";
      switch (status) {
          case 0:
            return "Inactivo";
          case 1:
            return "Activo";
      }
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
    },
    showModal(item, modal) {
        this.entity=item;
        this.messageModal = this.entity.status ? 'activar' : 'inactivar' 
        this.$refs[modal].show();
    },
    resetModal() {
        this.entity={};
    },
    loadAlert(message, tittle='Mensaje de Error: ', variant='danger'){
                this.$bvToast.toast(message, {
                            title: tittle,
                            variant: variant,
                            solid: true
                        });
    },
  }
}
</script>


<style scoped>

</style>
