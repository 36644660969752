<template>
    <div>
        <div class="alert alert-dark has-icon" role="alert" v-if="this.expense.status==3">
            <div class="alert-icon">
                <span class="fa fa-times"></span>
            </div>Motivo de Rechazo del Gasto: <strong>
               {{ (expense.decline_comment==null || expense.decline_comment=="")? '-' : expense.decline_comment }}
            </strong>.
        </div>
        <div class="alert alert-danger has-icon" role="alert" v-if="expense.status==4">
            <div class="alert-icon">
                <span class="fas fa-exclamation-triangle"></span>
            </div>Observación del Gasto: <strong>
               {{ expense.observation_comment }}
            </strong>.
        </div>
        <form ref="form" @submit.prevent="checkFormValidity('form')">
            <h6 v-if="expense.id" class="mb-3">Información del Gasto ID: {{expense.id}} </h6>                 
            <b-card>
                <legend>Datos del Gasto</legend>
                <div class="form-row">
                    <div class="col-xl-2">
                        <label>CC SAP</label>
                        <b-form-select v-model="expense.ocr_code" class="custom-select d-block w-100" :disabled="isDisabled">
                            <b-form-select-option value="92911">92911 - Gastos de Rendidor</b-form-select-option>
                            <b-form-select-option value="94716">94716 - Gastos de Proyecto</b-form-select-option>
                        </b-form-select>
                    </div>
                    <div class="col-xl-4">
                        <label>Descripción del Gasto <abbr title="Obligatorio">(*)</abbr></label>
                        <b-form-select v-model="expense.doc_expense" class="custom-select d-block w-100" required=""  :disabled="isDisabled" @change="changeExpense">
                            <b-form-select-option :value=null>Seleccione una opción</b-form-select-option>
                            <b-form-select-option v-for="(item, index) in docExpenses" :value="item.id" :key="index">{{item.name}}</b-form-select-option>
                        </b-form-select>
                    </div>
                    <div class="col-xl-6">
                        <label>Observación <abbr title="Obligatorio">(*)</abbr></label>
                        <b-input type="text" class="form-control upper-text"
                             v-model="expense.doc_description"
                             :disabled="isDisabled" required>
                        </b-input>
                    </div>
                </div>
            </b-card>
            <b-card>
                <legend><div class="d-flex align-items-center">
                          <span class="mr-auto">Datos de Documento</span>
                          <div class="card-header-control">
                            <div class="dropdown">
                                <b-button id="btn_sidebar" v-b-toggle.sidebar-right variant="success" :disabled="isDisabled" v-if="expense.doc_expense!=12"><i class="fas fa-qrcode"></i></b-button>
                            </div>
                          </div>
                </div>
                </legend>
                <div>
                <b-collapse visible id="collapse-1" v-if="expense.doc_expense!=12">
                <div class="form-row">
                <div class="col-xl-4 mb-2">
                        <label>Tipo de Documento <abbr title="Obligatorio">(*)</abbr></label>
                        <b-form-select v-model="expense.doc_type_id" class="custom-select d-block w-100" :disabled="isDisabled" @change="validarSerieDoc()" required>
                            <b-form-select-option :value=null>Seleccione una opción</b-form-select-option>
                            <b-form-select-option v-for="(item, index) in docTypes" :value="item.id" :key="index">{{item.name}}</b-form-select-option>
                        </b-form-select>
                </div>
                <div class="col-xl-4">
                    <label>Serie<abbr title="Obligatorio">(*)</abbr></label>
                    <b-input type="text" class="form-control"
                             v-model="expense.serie" maxlength="6" required placeholder="000000" style="text-transform:uppercase;" :disabled="isDisabled" >
                    </b-input>
                </div>
                <div class="col-xl-4">
                    <label>Nro. Documento<abbr title="Obligatorio">(*)</abbr></label>
                    <b-input type="text" class="form-control" placeholder="Max. 20 digitos" 
                             v-model="expense.correlativo" maxlength="20" style="text-transform:uppercase;" :disabled="isDisabled" required>
                    </b-input>
                </div>
                <div class="col-xl-4  mb-2">
                    <label>RUC<abbr title="Obligatorio">(*)</abbr></label>
                    <b-input-group>
                        <b-input type="tel"
                             class="form-control"
                             v-model="expense.card_code" maxlength="11"  @keyup="searchRuc()" @paste="searchRuc()" placeholder="Ingrese RUC para buscar" :disabled="isDisabled">
                        </b-input>
                        <b-input-group-append>
                        <b-button class="hidden-lg"><b-icon  icon="search" @click="searchRuc()"  :disabled="isDisabled"></b-icon></b-button>
                        </b-input-group-append>
                    </b-input-group>                
                </div>
                
                <div class="col-xl-8">
                    <label class="d-inline-flex">Razón Social <div v-html="miHtml" class="ml-2"></div></label>
                    <div class="input-group has-spinner">
                            <div class="spinner-border spinner-border-sm text-primary" :class="hasspinner">
                              <span class="sr-only">Loading...</span>
                            </div>
                            <b-input type="text"
                             class="form-control"
                             v-model="expense.card_name" :placeholder="placerazon" :disabled="isDisabled">
                            </b-input>
                    </div>
                </div>
                </div>
                </b-collapse>
                </div>
                <div class="form-row">
                <div class="col-xl-4  mb-2">
                    <label>Fecha de Gasto<abbr title="Obligatorio">(*)</abbr></label>
                    <b-form-datepicker class="form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :max="new Date().toISOString().substr(0, 10)"
                    v-model="expense.doc_date" :disabled="isDisabled" required @input="searchExchangerate">
                    </b-form-datepicker>
                </div>
                <div class="col-xl-4">
                    <label class="font-weight-bold">Importe <span class="badge badge-info" v-if="expense.doc_expense!=12 && expense.inafecto_igv==0 && isFactura">Inc. IGV</span><abbr title="Obligatorio">(*)</abbr></label>
                    <div class="input-group">
                        <label class="input-group-prepend">
                            <span class="badge">{{expense.doc_cur}}</span></label>
                        <b-input step="0.01" type="number" class="form-control text-right number"
                                v-model="expense.doc_total"
                                @input="changeTotal" :disabled="isDisabled || (expense.id!=undefined && expense.distribution==1)" required>
                        </b-input>
                    </div>
                </div>
                <div class="col-xl-2" v-if="expense.doc_expense!=12 && isFactura">
                <label><strong>Inafecto IGV?</strong></label>
                <b-form-checkbox class="custom-control"
                                     v-model="expense.inafecto_igv"
                                     value="1"
                                    unchecked-value="0" :disabled="isDisabled" @change="changeTotal"> SI
                </b-form-checkbox>
                </div>
                <div class="col-xl-2">
                    <label>Moneda</label>
                    <select class="custom-select d-block w-100"
                            v-model="expense.doc_cur"
                            @change="searchExchangerate" :disabled="isDisabled">
                        <option>PEN</option>
                        <option>USD</option>
                    </select>
                </div>
                <div class="col-xl-2">
                    <label>T. Cambio </label>
                    <b-input-group style="background-color: #f6f7f9;opacity: 1;">
                        <b-input v-model="expense.exchange_rate" type="text" class="form-control" :disabled=true >
                        </b-input> 
                        <!--<b-input-group-append>
                        <b-button><b-icon icon="arrow-clockwise" @click="searchExchangerate"  :disabled="isDisabled"></b-icon></b-button>
                        </b-input-group-append>-->
                    </b-input-group>
                </div>
                </div>
            </b-card>
            <b-card visible v-if="expense.doc_expense!=12 && showretencion">
                <div class="form-row">
                <div class="form-group col-xl-4 col-sm-12">
                    <b-form-checkbox class="custom-control"
                                     v-model="expense.retencion"
                                     value="1"
                                    unchecked-value="0" :disabled="isDisabled || (expense.id!=undefined && expense.distribution==1)" @change="setRetencion">Retención
                    </b-form-checkbox>
                </div>
                <div class="form-group col-xl-4 col-sm-12" v-if="expense.retencion==1">
                    <label>% de Retención</label>
                    <div class="input-group">
                        <label class="input-group-prepend" for="pi9">
                            <span class="badge">%</span></label>
                        <b-input type="number" class="form-control text-right number"
                                 v-model="expense.retencion_per"
                                 @input="changeTotal" max="10"
                                 :disabled="isDisabled" required>
                        </b-input>
                    </div>
                </div>
                </div>         
                <hr>
                <div class="form-row">
                <div class="form-group col-xl-4 col-sm-12">
                    <b-form-checkbox class="custom-control"
                                     v-model="expense.detraccion_show"
                                     :disabled="isDisabled || (expense.id!=undefined && expense.distribution==1)" value="1"
                                    unchecked-value="0" @change="setDetraccion"> Detracción
                    </b-form-checkbox>
                </div>
                <div class="col-xl-4" v-if="expense.detraccion_show==1">
                    <label>Tipo Detracción </label>
                        <b-form-select v-model="expense.detraccion_per_id" class="mb-3" :disabled="isDisabled" @change="setDetraccionPer">
                            <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
                            <b-form-select-option-group label="Bienes">
                                <b-form-select-option v-show="item.type==1" v-for="(item, index) in docDetraccionPer" :value="item.id" :key="index">{{item.name}} ({{item.per}}%)</b-form-select-option>
                            </b-form-select-option-group>
                            <b-form-select-option-group label="Servicios">
                                <b-form-select-option v-show="item.type==2" v-for="(item, index) in docDetraccionPer" :value="item.id" :key="index">{{item.name}} ({{item.per}}%)</b-form-select-option>
                            </b-form-select-option-group>
                        </b-form-select>
                </div>
                <div class="col-xl-4" v-if="expense.detraccion_show==1"> 
                    <label>Nro. de detracción</label>
                    <b-input type="text" class="form-control upper-text" v-model="expense.detraccion_num" :disabled="isDisabled">
                    </b-input>
                </div>
                <div class="col-xl-4"></div>
                <div class="col-xl-4" v-if="expense.detraccion_show==1">
                    <label>Fecha Detracción</label>
                    <b-form-datepicker class="form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                    v-model="expense.detraccion_date" placeholder="DD-MM-YYYY" :disabled="isDisabled">
                    </b-form-datepicker >
                </div>
                <div class="col-xl-4" v-if="expense.detraccion_per_id!=null">
                    <label>Pago Pendiente</label>
                    <b-form-checkbox class="custom-control"
                                     v-model="expense.detraccion"
                                     :disabled="isDisabled" value="1"
                                    unchecked-value="0" @change="changeTotal"> SI
                    </b-form-checkbox>
                </div>
                </div>
            </b-card>
            <b-card>
                <div class="form-row">
                <div class="col-xl-3" v-if="expense.id!=undefined && expense.distribution==1">
                    <label>Importe Distribuido</label>
                    <div class="input-group">
                        <label class="input-group-prepend">
                            <span class="badge">PEN</span></label>
                        <b-form-input readonly step="0.01"  type="number" class="form-control text-right number"
                                v-model="expense.amount_distributed"
                                :disabled="isDisabled">
                        </b-form-input>
                    </div>
                </div>
                <div class="col-xl-4 mb-2">
                    <label><strong>Importe a Rendir </strong><abbr title="Obligatorio">(*)</abbr></label>
                    <div class="input-group bg-secondary">
                        <label class="input-group-prepend">
                            <span class="badge">PEN</span></label>
                        <b-form-input readonly step="0.01"  type="number"  class="form-control text-right number"
                                v-model="expense.expense_amount"
                                :disabled="isDisabled" required>
                        </b-form-input>
                    </div>
                </div>
                <div class="col-xl-3">
                <label><strong>Importe distribuido?</strong></label>
                <b-form-checkbox class="custom-control mt-2"
                                     v-model="expense.distribution"
                                     value="1"
                                    unchecked-value="0" @change="setProjects" :disabled="expense.id!=undefined"> SI
                </b-form-checkbox>
                </div>
                <div class="col-xl-5" v-if="(expense.distribution==0  || (expense.distribution==1 && expense.id!=undefined)) && report==false && expense.status==0">
                    <label>Proyecto <abbr title="Obligatorio">(*)</abbr></label>
                    <b-form-select  required v-model="expense.project_id" class="custom-select d-block w-100" :disabled="isDisabled">
                        <b-form-select-option :value=null>Seleccione una opción</b-form-select-option>
                        <b-form-select-option v-for="(project, index) in projects" v-show="project.status==1"  v-bind:value="project.id"  :key=index >
                            {{ project.code }}
                        </b-form-select-option>
                    </b-form-select >
                </div>
                <div class="form-group col-xl-12" v-if="expense.distribution==1 && expense.id==undefined">
                    <label>Proyectos <abbr title="Obligatorio">(*)</abbr></label>
                    <UCComboBoxMultiple 
                            v-model="expense.project_id" 
                            :List="projects" :data="[]" textname="code" placeholder="Seleccione proyectos"
                            v-on:OnSelectEvent="ProjectEvent" >
                    </UCComboBoxMultiple>
                </div>
                </div>
                <div class="form-row" id="miHtmlProjects"></div> 
            </b-card>
            <!-- OBRAS-->
            <ProjectWorkForm  v-if="showWorkForm" 
                              :entity="expense"
                              :projects="projects"
                              :isDisabled="isDisabled"
                              v-on:OnCloseForm="closeModal"
                              v-on:OnLoadAlert="loadAlert"
                              v-on:onReplaceEntity="replaceEntity">
            </ProjectWorkForm>
            <!--DETALLE DOCUMENTO-->
            <ExpenseDetailsForm  v-if="showDetailsForm" 
                                 :entity="expense"
                                 :isDisabled="isDisabled"
                                 v-on:OnCloseForm="closeModal"
                                 v-on:OnLoadAlert="loadAlert">
            </ExpenseDetailsForm>

            <b-button squared block variant="outline-danger" v-b-toggle.sidebartwo-right v-if="!isBusy">
                <i class="fas fa-folder-open" ></i> SUSTENTOS (*)        
            </b-button>
            <!--<b-button squared block v-if="expense.status!=undefined" variant="outline-info" @click="showModal(3)">
                <b-icon icon="card-image"></b-icon>  VER SUSTENTOS
            </b-button>-->
            <b-button squared block variant="outline-info" @click="showModal(1)" v-if="expense.distribution==0 || expense.id!=undefined">
                <i class="fas fa-briefcase"></i> OBRAS (*)         
            </b-button>
            <b-button squared block variant="outline-info" @click="showModal(2)">
                <i class="fas fa-file-invoice"></i> DETALLE DOCUMENTO         
            </b-button>
            <div class="form-row ml-1 mt-2" v-if="expense.status==0 || expense.status==4">
                <p class="text-danger"><strong>(*): Campos obligatorios.</strong></p>
            </div>
            <div class="form-row">
                <div class="form-actions col-xl-5 offset-xl-7" v-if="!isDisabled && (expense.status!=3)">
                    <b-button size="lg" block variant="success"  type="submit" v-on:click="save">
                        <i class="fa fa-save" v-if="!isBusy"></i> Guardar         
                    </b-button>
                </div>
            </div>
        </form><!-- /.form -->


        <div v-if="isBusy">
            <div class="overlay"></div><div class="loading-img">
            </div>
        </div>
        <b-sidebar id="sidebar-right" header-class="mt-3 border-bottom" bg-variant="secondary"  title="Escanear QR" right shadow width="430px" @hidden="closeSidebar()" @shown="openScanQr()">
            <div class="px-3 py-2">
                <div class="reader" id="reader"></div>
                <hr>
            </div>
        </b-sidebar>

        <b-sidebar id="sidebartwo-right" header-class="mt-3 border-bottom" bg-variant="secondary"  title="Sustentos" right shadow width="430px" >
            <div class="px-3 py-2">
            <PaymentDocumentForm
                                :entity="expense"
                                v-on:OnBusy="OnBusy"
                                v-on:OnLoadAlert="loadAlert">
            </PaymentDocumentForm>
            </div>
        </b-sidebar>
    </div>
</template>

<script src="<%= BASE_URL %>assets/javascript/html5-qrcode.min.js"></script>
<script>
import ProjectWorkForm from "../employee/ProjectWorkForm";
import ExpenseDetailsForm from "../employee/ExpenseDetailsForm";
import PaymentDocumentForm from "../employee/PaymentDocumentForm";
import UCComboBoxMultiple from "@/components/framework/UCComboBoxMultiple";

    export default {
        name: "ExpenseForm",
        props: {expense: {}, isDisabled: Boolean, projects: {}, report: Boolean, docExpenses: {}, docTypes:{}, docDetraccionPer: {}},
        components: {ProjectWorkForm, ExpenseDetailsForm, UCComboBoxMultiple, PaymentDocumentForm},
        beforeMount() {
            this.initialize();
            this.url = this.$web.defaults.baseURL;
        },
        data: function () {
            return {
                isBusy: false,
                objectItem: {
                    ruc: '',
                    razon: ''
                },
                placerazon:'Nombre o Razón Social',
                regex: /^[0-9]*$/,
                hasspinner: '',
                searching: "",
                showretencion: false,
                showWorkForm: false,
                showDetailsForm: false,
                showScanQr: false,
                isFactura: false,
                miHtml: '',
                miHtmlProjects: '',
                uploadList: Array,
                
            }
        },
        methods: {
            async initialize() {
                if(this.expense.doc_type_id!=3) {
                    this.showretencion=true;
                }
                this.validarSerieDoc();
                //if(this.expense.distribution==1) {
                    //this.isDisabled=true;
                //}
            },
            setExpense(){
                this.expense.retencion_per=null;
                this.expense.detraccion_num="";
                this.expense.detraccion_date=null;
                this.expense.detraccion_per_id=null;
                this.expense.detraccion_per=null;
                this.expense.detraccion=0;
                this.changeTotal();
            },
            setRetencion(){
                this.setExpense();
                if(this.expense.retencion==1) {
                    this.expense.retencion_per=3;
                    this.expense.detraccion_show=0;
                }
                this.changeTotal();
                this.$forceUpdate();
            },
            setDetraccion(){
                this.setExpense();
                if(this.expense.detraccion_show==1) {
                    this.expense.retencion=0;
                }
                this.$forceUpdate();
            },
            checkFormValidity(form) {
                const valid = this.$refs[form].checkValidity()
                this.nameState = valid
                return valid
            },
            searchRuc(){

                if(this.validarRuc() && this.searching==false) {
                    this.objectItem.ruc=this.expense.card_code;
                    this.miHtml='';
                    this.expense.card_name='';
                    this.hasspinner='show';
                    this.searching= true;
                    this.$api.post("sunat/supplier", this.objectItem)
                        .then(response => {
                            this.searching= false;
                            this.expense.card_name=response.data.name ? response.data.name : '-';
                            this.hasspinner='';
                            if(response.data.error) {
                                this.loadAlert(response.data.error, 'Advertencia: ', 'warning')
                            } else {
                                this.miHtml='<span class="badge badge-info">'+response.data.condition+
                                            '</span> <span class="badge badge-info">'+response.data.state+'</span>';
                            }
                            this.$forceUpdate();
                        })
                        .catch(error => {
                           this.searching= false;
                            this.hasspinner='';
                            this.loadAlert(error.response.data.message);
                        });
                 }               
            },
            validarRuc (){
                var ruc=this.expense.card_code;
                if(ruc == undefined){
                    return false;
                }
                if(ruc.length !== 11){
                    return false;
                }
                if(!this.regex.test(ruc)) { 
                    this.loadAlert('Ingrese RUC Válido', 'Advertencia', 'warning');
                    this.placerazon='Nombre o Razón Social';
                    this.expense.card_name='';
                    return false;
                } else {
                    return true;
                }
            },
            changeExpense: function() {
                if(this.expense.doc_expense==12) this.cleanedDocument();
                this.$forceUpdate();
            },
            cleanedDocument: function () {
                this.expense.card_name=null;
                this.expense.card_code=null;
                this.expense.serie=null;
                this.expense.correlativo=null;
                this.expense.doc_type_id=null;
                this.expense.doc_type=null;
                this.expense.inafecto_igv=0;                
            },
            validarSerieDoc: function() {
                //if(this.expense.doc_type_id==null) {
                    //this.cleanedDocument();
                //}

                if(this.expense.doc_type_id==3) {
                    this.expense.serie='';
                    this.expense.correlativo='';
                    this.expense.serie= this.expense.serie.padStart(4, 0);
                    this.expense.correlativo=this.expense.serie.padStart(6, 0);
                    this.showretencion=false;
                    this.expense.retencion=0;
                    this.expense.detraccion_show=0;
                    this.setExpense();
                } else if (this.expense.doc_type_id==9) {
                    this.expense.serie='';
                    this.expense.correlativo='';
                    this.expense.serie= this.expense.serie.padStart(4, 0);
                    this.showretencion=false;
                    this.expense.retencion=0;
                    this.expense.detraccion_show=0;
                    this.setExpense();
                } else if (this.expense.doc_type_id!==null) {
                    this.showretencion=true;
                } else {
                    this.showretencion=false;
                }

                if(this.expense.doc_type_id==4 || this.expense.doc_type_id==5) {
                    this.isFactura=true;
                } else {
                    this.isFactura=false;
                    this.expense.inafecto_igv=0;
                }

                this.$forceUpdate();
            },

            /*changeSubTotal: function () {
                if (this.expense.doc_subtotal === 0) return;
                this.expense.doc_total = this.expense.doc_subtotal * 1.18;
                this.expense.doc_total = this.expense.doc_total.toFixed(2).replace(/(\d)(?=(\d{6})+(?:\.\d+)?$)/g, "$1,");
                this.expense.doc_tax = this.expense.doc_subtotal * 0.18;
                this.expense.doc_tax = this.expense.doc_tax.toFixed(2).replace(/(\d)(?=(\d{6})+(?:\.\d+)?$)/g, "$1,");
                this.$forceUpdate();
            },*/
            changeTotal: function () {
                if (this.expense.doc_total === 0) return;

                this.expense.expense_amount=0;

                if(this.expense.inafecto_igv==1) {
                    this.expense.doc_subtotal=this.expense.doc_total;
                    this.expense.doc_tax = 0;
                } else {
                    this.expense.doc_subtotal = this.expense.doc_total / 1.18;
                    this.expense.doc_subtotal = this.expense.doc_subtotal.toFixed(2).replace(/(\d)(?=(\d{6})+(?:\.\d+)?$)/g, "$1,");
                    this.expense.doc_tax = this.expense.doc_subtotal * 0.18;
                    this.expense.doc_tax = this.expense.doc_tax.toFixed(2).replace(/(\d)(?=(\d{6})+(?:\.\d+)?$)/g, "$1,");
                }
                
                this.expense.expense_amount=this.expense.doc_total;

                if(this.expense.retencion=='1') {
                    if(this.expense.retencion_per==="") this.expense.retencion_per=3;
                    this.expense.retencion_amount=this.formatAmount(this.expense.doc_total * (this.expense.retencion_per / 100));
                    this.expense.expense_amount=this.formatAmount(this.expense.doc_total - this.expense.retencion_amount);
                } else {
                    this.expense.retencion_amount=null;
                }

                if(this.expense.detraccion=='1'){
                    this.expense.detraccion_num=null;
                    this.expense.detraccion_date=null;
                    if(this.expense.detraccion_per>0) {
                        this.expense.detraccion_amount=this.formatAmount(this.expense.doc_total * (this.expense.detraccion_per / 100));
                        this.expense.expense_amount=this.formatAmount(this.expense.doc_total - this.expense.detraccion_amount);
                    } 
                    this.$forceUpdate();
                } else {
                        this.expense.detraccion_amount=null;
                }

                if(this.expense.doc_cur=='USD' && this.expense.exchange_rate && this.expense.doc_total) {
                    this.expense.expense_amount=this.formatAmount(this.expense.expense_amount*this.expense.exchange_rate);
                    if(this.expense.detraccion=='1'){
                      this.expense.detraccion_amount=this.formatAmount(this.expense.detraccion_amount*this.expense.exchange_rate);   
                    }

                    if(this.expense.retencion=='1'){
                      this.expense.retencion_amount=this.formatAmount(this.expense.retencion_amount*this.expense.exchange_rate);   
                    }
                }

                this.$forceUpdate();
            },
            formatAmount(amount) {
                return amount.toFixed(2).replace(/(\d)(?=(\d{6})+(?:\.\d+)?$)/g, "$1,");
            },
            validarForm(){

                if(this.expense.distribution==0 || this.expense.id!=undefined) {
                    if(this.expense.project_departure_id == null) {
                        this.loadAlert('Obras: Seleccione Partida y Obras');
                        return false;
                    }
                    if(this.expense.projectwork.length == 0) {
                        this.loadAlert('Obras: Seleccione Partida y Obras');
                        return false;
                    }
                }
                if(this.expense.doc_total<=0) {
                    this.loadAlert('Precio Total Inválido', 'Advertencia', 'warning');
                    return false;
                }

                if(this.expense.doc_expense!=12) {

                    if(this.expense.serie==undefined || this.expense.correlativo==undefined) {
                        this.loadAlert('Serie o Nro de Documento Inválido', 'Advertencia', 'warning');
                        return false;
                    } else {
                        if(this.expense.doc_type_id!=3) {
                            this.expense.correlativo=Number(this.expense.correlativo)
                            this.expense.serie=this.expense.serie.toUpperCase()
                            this.expense.doc_num=this.expense.serie+'-'+this.expense.correlativo;
                        } else {
                           this.expense.doc_num=this.expense.serie+'-'+this.expense.correlativo; 
                        }
                    }

                    if (this.expense.id==undefined && (this.expense.expensefile==undefined || this.expense.expensefile.length==0)) {
                        this.loadAlert('Adjunte su comprobante', 'Advertencia', 'warning');
                        return false;
                    }

                    if(this.expense.doc_type_id==3 || this.expense.doc_type_id==9) {
                        return true;
                    } //si no es comprobante de caja continuar con la validacion

                    if(this.expense.retencion_per<0 || this.expense.retencion_per>100 ){
                        this.loadAlert('Porcentaje de Retención Inválido');
                        return false;
                    }

                    if(!this.validarRuc()){
                        this.loadAlert('Ingrese RUC Válido', 'Advertencia', 'warning');
                        return false;
                    }
                }

                return true;
            },
            async save() {
                if(this.searching == true) {
                    this.loadAlert("El sistema está consultando ruc...",
                                    'Advertencia',
                                    'warning');
                    return;
                }

                 if (!this.checkFormValidity('form')) {
                     return
                 }

                if(this.validarForm()){
                    if (this.expense.id) {
                        this.isBusy = true;
                        var statusAnterior=null;
                        var docstatusAnterior=null;
                        if(this.expense.status==4) {
                            statusAnterior=4;
                            docstatusAnterior="Observado";
                            this.expense.status="1"
                            this.expense.doc_status='En Revisión'
                        }
                        await this.$api.put("employee/expenses/" + this.expense.id, this.expense)
                            .then(response => {
                                this.isBusy = false;
                                //this.expense.project=response.data.project;
                                //this.expense.details=response.data.details;
                                this.$emit('OnUpdated', this.expense, response);
                            })
                            .catch(error => {
                                this.isBusy = false;
                                this.loadAlert(error.response.data.message); 
                                if(statusAnterior==4) {
                                    this.expense.status="4"
                                    this.expense.doc_status='Observado'
                                }
                            });
    
                    } else {
                        //validar this.expense.newfiles??
                        this.expense.status=0;
                        this.expense.doc_status='Pendiente';
                        var total=0;
                        if(this.expense.distribution==1){
                            if(this.expense.projects.length==0) {
                                this.loadAlert('Seleccione Proyecto(s)', 'Advertencia', 'warning'); 
                                return;
                            }
                            this.expense.distributions=[];
                            this.expense.amount_distributed=this.expense.expense_amount;
                            this.expense.projects.map(value =>{
                                if ( document.getElementById('distribution_'+value.code)) {
                                    this.expense.distributions.push({'project': value.code, 'project_id': value.id, 'importe': document.getElementById('distribution_'+value.code).value});
                                    total+=parseFloat(document.getElementById('distribution_'+value.code).value);
                                }
                                
                            });
                            if(total!=this.expense.amount_distributed) {
                                this.loadAlert('Importes distribuidos no coinciden con el Monto a Rendir');
                                return;
                            }
                       }                             
                        this.isBusy = true;
                        await this.$api.post("employee/expenses", this.expense)
                            .then(response => {
                                //this.expense.id=response.data.id
                                //this.expense.details=response.data.details;
                                this.isBusy = false;
                                this.$emit('OnCreated', response.data);
                            })
                            .catch(error => {
                                //delete this.expense.status;
                                //delete this.expense.doc_status;
                                this.isBusy = false;
                                this.loadAlert(error.response.data.message);
                            });
                        
                    }
                }
            },
            showModal(modal){
                if(modal==1){
                    this.showWorkForm=true;
                    return;
                }
                if(modal==2){
                    this.showDetailsForm=true;
                    return;
                }
            },
            closeModal(){
                this.showWorkForm=false;
                this.showDetailsForm=false;
                this.showScanQr=false;
                //console.log(this.expense);
            },
            searchExchangerate() {
                
                if(this.expense.doc_cur=='USD' &&  this.isDisabled==false) {
                    this.isBusy = true;
                    this.$api.post("sunat/tipocambio",  { 'doc_date' : this.expense.doc_date})
                    .then(response => {
                        //console.log(response);
                        if(response.data.venta !== undefined) {
                            this.expense.exchange_rate=response.data.venta;
                        }
                        this.changeTotal();
                        this.$forceUpdate();
                        this.isBusy = false;
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.loadAlert(error.response.data.message);
                    });
                } else {
                    this.expense.exchange_rate=null;
                    this.changeTotal();
                    this.$forceUpdate();
                }
            },
            loadAlert(message, tittle='Mensaje de Error: ', variant='danger'){
                this.$bvToast.toast(message, {
                    title: tittle,
                    variant: variant,
                    solid: true
                });
            },
            replaceEntity(entity) {
                this.expense=entity;
            },
            setDetraccionPer(){
                if(this.expense.detraccion_per_id==null) {                    
                    this.expense.detraccion=0;      
                    this.changeTotal();              
                    this.$forceUpdate();
                    return; 
                }
                this.expense.detraccion_per=this.docDetraccionPer[this.expense.detraccion_per_id - 1].per;
                this.changeTotal();
                this.$forceUpdate();
            },
            ProjectEvent(item) {
              //  this.user.project=item;
                //console.log(item);
                this.expense.projects=item;
                this.expense.project_id=null;
                this.miHtmlProjects="";
                document.getElementById("miHtmlProjects").innerHTML="";
                item.map(value => {
                    this.miHtmlProjects+="<div class='form-group col-xl-2 col-xl-3 col-sm-12'><label><strong>"+value.code+"</strong>: Importe a Rendir</label><input type='number' step='0.01' class='form-control text-right number' id='distribution_"+value.code+"' required></div>";    
                });
                document.getElementById('miHtmlProjects').insertAdjacentHTML('beforeend', this.miHtmlProjects);
                this.$forceUpdate();
                
            },
            setProjects(){
                this.expense.project_id=null;
                this.miHtmlProjects=null;
                document.getElementById("miHtmlProjects").innerHTML="";
                this.$forceUpdate();
            },
            openScanQr(){
                this.showScanQr=true;
                var div = document.getElementById("reader").innerHTML;
                if(div=="") {
                    html5QrcodeScanner = new Html5QrcodeScanner(
                    'reader', { fps: 10, qrbox: 200 });            
                    html5QrcodeScanner.render(this.onScanSuccess);  
                    //console.log(html5QrcodeScanner);
                } else {
                    this.cleanScan();
                }
            },
            cleanScan(){
                html5QrcodeScanner.clear();
                document.getElementById("reader").innerHTML="";
                this.showScanQr=false;
            },
            onScanSuccess(decodedText, decodedResult) {
                var code=decodedText.split("|");
                //[RUC, TIPODOCUMENTOSUNAT, SERIE, CORRELATIVO, IGV, TOTAL, FEHCA, TIPODOC, DNI]
                //01    Factura
                //03  Boleta de Venta                
                console.log(code);
                if(code.length>6) {
                    this.expense.doc_cur = 'PEN';
                    this.expense.card_code = code[0];
                    this.searchRuc();
                    this.expense.doc_type_code = code[1]; 
                    this.expense.doc_type_id = code[1]==3 ? 2 : 5;
                    this.expense.serie = code[2];
                    this.expense.correlativo = code[3];
                    this.expense.doc_tax = code[4];
                    this.expense.doc_total = code[5];
                    this.changeTotal();
                    this.expense.doc_date = code[6];
                    this.$forceUpdate();
                    this.validarSerieDoc();
                    this.cleanScan();
                    const btn=document.getElementById('btn_sidebar');
                    btn.click();
                } else {
                    this.loadAlert("Verifique. Código Escaneado es Invalido");
                }
                return;
            },
            closeSidebar(){
                this.cleanScan()
            },
            OnBusy(value){
                this.isBusy=value;
            }

        }
    }
</script>


<style scoped>

img.zoom {
    width: 3rem;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    border-radius: 0.25rem;
    border: solid 1px #346cb0;
}

img.zoom:hover {
    cursor: pointer;
}

img.minizoom {
    width: 2rem;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
}

img.minizoom:hover {
    -webkit-transform: scale(2); 
    -moz-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
    z-index: 99999;
    padding-right: auto !important;;
    padding-left: auto !important;;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
input[type=number] { -moz-appearance:textfield; }

</style>
