<template>
  <div class="app">
    <header class="app-header app-header-dark">
      <div class="top-bar" :class="getBgEnv()">
        <div class="top-bar-brand">
          <router-link :to="{ path: '/dashboard'}" append>
            <b>{{ this.system.name }} {{ this.system.version }}</b>
          </router-link>
        </div>
        <div class="top-bar-list">
          <h4 class="ml-2 mt-2" v-if="testText">Ambiente de Prueba</h4>
          <div class="top-bar-item px-2 d-md-none d-lg-none d-xl-none">
            <button class="hamburger hamburger-squeeze"
                    type="button"
                    data-toggle="aside"
                    aria-label="toggle menu">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </div>

          <div class="top-bar-item top-bar-item-right px-0 d-none d-sm-flex">
            <div class="dropdown d-flex">
              <button class="btn-account d-none d-md-flex"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <!--<span class="user-avatar user-avatar-md fa fa-user-circle"></span>-->
                <span class="user-avatar user-avatar-md"><img
                                        v-bind:src="getImage()" alt=""></span>
                <span class="account-summary pr-lg-4 d-none d-lg-block">
                  <span class="account-name" v-if="user">{{
                      this.user.name || '...'
                    }}</span>
                  <span class="account-description" v-if="user.department_id">{{
                      this.user.role ? this.user.role.name : this.user.cargo
                    }}</span>
                </span>
              </button>
              <div class="dropdown-menu">
                <div class="dropdown-arrow ml-3"></div>
                <h6 class="dropdown-header d-none d-md-block d-lg-none">
                  {{ this.user.name }}
                </h6>
                <!--<a class="dropdown-item" href="#"
                ><span class="dropdown-icon oi oi-person"></span> Profile</a
                > -->
                <a class="dropdown-item" href="#" @click="logout">
                  <span class="dropdown-icon oi oi-account-logout"></span>
                  Cerrar sesión
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <aside class="app-aside app-aside-expand-md app-aside-light">
      <!-- .aside-content -->
      <div class="aside-content">
        <!-- .aside-header -->
        <header class="aside-header d-block d-md-none">
           <button class="hamburger hamburger-squeeze" type="button" data-toggle="aside" aria-label="Menu"><span class="hamburger-box"><span class="hamburger-inner" id="closeButton"></span></span></button>
          <!-- .btn-account -->
          <button
              class="btn-account"
              type="button"
              data-toggle="collapse"
              data-target="#dropdown-aside"
          >
            <!--<span class="user-avatar user-avatar-md fa fa-user-circle"></span>-->
            <span
                            class="user-avatar user-avatar-lg"><img
                                        v-bind:src="getImage()" alt=""></span> 
            <span class="account-icon">
              <span class="fa fa-caret-down fa-lg"></span>
            </span>
            <span class="account-summary">
              <span class="account-name" v-if="user">{{ this.user.name}}</span>
              <span class="account-description" v-if="user.department_id">{{
                  this.user.role ? this.user.role.name : this.user.cargo
                }}</span>
            </span>
          </button>
          <!-- /.btn-account -->
          <!-- .dropdown-aside -->
          <div id="dropdown-aside" class="dropdown-aside collapse">
            <!-- dropdown-items-->
            <div class="pb-3">
               <!--<a class="dropdown-item" href="#"
              ><span class="dropdown-icon oi oi-person"></span> Profile</a
              > -->
              <a class="dropdown-item" href="#" @click="logout">
                <span class="dropdown-icon oi oi-account-logout"></span>
                Cerrar sesión</a
              >
            </div>
          </div>
        </header>

        <!-- USER COMPONENT MENU -->
        <UCMenu v-on:OnCloseMenu="closeMenu"></UCMenu>
      </div>
    </aside>

    <main class="app-main">
      <div class="wrapper">
        <!-- ROUTE VIEW MAIN PANEL -->
        <router-view name="mainPanel"/>
      </div>
    </main>
  </div>
</template>
<script>
import UCMenu from "../components/framework/UCMenu";
import * as actions from "../store/action-types";

export default {
  components: {UCMenu},
  data() {
    return {
      testText: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    system() {
      return this.$store.getters.system;
    },
  },
  beforeMount() {
      this.url = this.$web.defaults.baseURL;
  },
  methods: {
    async initialize() {
    /*await this.$store.dispatch(actions.CONFIG_REQUEST).then(() => {
      });*/
    },
    async logout() {
      await this.$store.dispatch(actions.AUTH_LOGOUT).then(() => {
        this.$router.push("/logout");
      });
    },
    closeMenu() {
      document.getElementById("closeButton").click();
    },
    getImage() {
       return '/assets/images/avatars/avatar-user.png';
    },
    getBgEnv(){
      if(this.$api.defaults.environment!= "pro") {
        this.testText=true
        return "bg-red"
      } else {
        this.testText=false
        return ""
      }
    }
  },
};
</script>
<style>
</style>
