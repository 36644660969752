<template>
   <div>
        <b-modal ref="modal" size="lg" title="Detalles de Comprobante"  @hidden="hideModal()" centered header-bg-variant="light" header-text-variant='primary' footer-bg-variant="light" footer-text-variant='primary' >
        <br>
        <form ref="form" @submit.prevent="checkFormValidity('form')" @submit="pushDetail()">
        <b-row v-if="!isDisabled">
            <b-col lg="2">
                <label>Cantidad <abbr title="Requerido"> *</abbr></label>
                <b-input-group>
                        <b-form-input type="number"  step="0.1" min="0" required v-model="items.quantity" class="mb-1 mt-0" ></b-form-input>
                </b-input-group>
            </b-col>
            <b-col lg="6" >
                <label>Descripción <abbr title="Requerido"> *</abbr></label>
                <b-input-group>
                        <b-form-input type="text" minlength="3" required v-model="items.description" class="text-uppercase"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col lg="3">
                <label>P.U.<abbr title="Requerido"> *</abbr></label>
                <b-input-group>
                        <b-form-input type="number" step="0.01" required v-model="items.price" class="mb-1 mt-0" ></b-form-input>
                </b-input-group>
            </b-col>
            <b-col lg="1" sm="12"> 
                <b-form-group label="Opción" label-for="button">
                    <b-button id="button" block type="submit" variant="success" class="text-uppercase" v-b-tooltip title="Agregar Item">
                    <i class="fas fa-plus-circle"></i><span class="hidden-lg"> Agregar</span>
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
        <br>
        <b-row>
            <b-col v-if="details.length==undefined || details.length==0">
                <h6 class="text-danger">No hay Items registrados</h6>
            </b-col>
            <b-col lg="12" class="my-1">
                    <table class="table b-table table-hover border responsive table-bordered table-sm b-table-fixed">
                        <thead class="thead-light text-center" v-if="details.length!=undefined && details.length>0">
                            <tr>
                                <th >Cantidad</th>
                                <th >Descripción</th>
                                <th >P.U.</th>
                                <th >Sub Total</th>
                                <th v-if="!isDisabled">Opción</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in details"  :key=index >
                                <td class="text-center"><span v-if="item.id==undefined" class="badge badge-primary">No Guardado</span> {{ item.quantity }} {{ item.quantity_und }}</td>
                                <td class="text-uppercase">{{ item.description }}</td>
                                <td>{{ item.price }}</td>
                                <td>{{ Number(item.subtotal).toFixed(2) }}</td>
                                <td class="text-center" v-if="!isDisabled"><b-button v-if="item.id==undefined" size="sm" variant="danger" @click="spliceDetail(index)" class="mr-1"><i class="fa fa-trash"></i> <span class="hidden-sm">Eliminar</span></b-button>
                                <b-button v-if="item.id!=undefined" size="sm" variant="danger" @click="deleteDetail(item.id, index)" class="mr-1"><i class="fa fa-trash"></i> <span class="hidden-sm">Eliminar</span></b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </b-col>
            
        </b-row>
        </form>

        <template #modal-footer="{ ok, cancel }">
            <!-- <a  v-if="!isDisabled" @click="ok()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;" ><strong>Guardar</strong></a>
            <a v-if="!isDisabled" @click="cancel()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;"><strong>Cancelar</strong></a> -->
            <a @click="cancel()" class="card-footer-item card-footer-item-bordered" style="cursor: pointer;"><strong>Cerrar</strong></a>
        </template>

        </b-modal>
        <div v-if="isLoading">
            <div class="overlay"></div>
            <div class="loading-img"></div>
        </div>
    </div>
</template>

<script>

export default {
  name: "ExpenseDetailsForm",
  components: {},
  props: {
    entity: Object, isDisabled: Boolean,
  },
  data() {
    return {
      nameState: null,
      isLoading: false,
      projects: Array,
      items: {},
      details: [],
      newsitem: 0,
    }
  },
  mounted() {
    this.Initialize();
  },
  methods: {
    Initialize() {
        this.$refs['modal'].show();
        this.items.expense_id=this.entity.id;
        if(this.entity.details!=undefined) this.details=this.entity.details;

    },
    hideModal(){
      /*if(this.newsitem>0) {
        this.$emit('OnLoadAlert', 'Tiene Item(s) sin guardar. Verifique!', 'Advertencia', 'warning');
      }*/
      this.entity.details=this.details;
      this.$emit('onReplaceEntity', this.entity);
      this.$nextTick(() => {
                            this.$emit("OnCloseForm");
                        });
    }, 
    checkFormValidity(form) {
      const valid = this.$refs[form].checkValidity()
      this.nameState = valid
      return valid
    },
    pushDetail(){
      if (!this.checkFormValidity('form')) {
          return
      }

      this.items.subtotal=this.items.price * this.items.quantity

      var total=0;
      this.details.map(function(value) {
        total+=value.subtotal;
      });

      total+=this.items.subtotal;

      if(Number(this.entity.doc_total).toFixed(2) - total.toFixed(2) < 0) {
        this.$emit('OnLoadAlert', 'El Detalle Total supera el Importe Total del comprobante', 'Advertencia', 'warning');
        return;
      }

      this.details.push(this.items); 
      //this.newsitem++;
      this.items={};
      this.items.expense_id=this.entity.id;
    },
    spliceDetail(value/*, newdetail=true*/){
      this.details.splice(value, 1);
      this.$forceUpdate();
      /*if(newdetail) {
        this.newsitem--;
      }*/
    },

    /*saveDetails(bvModalEvt){
      bvModalEvt.preventDefault();
      if(this.details.length==0) {
        this.$emit('OnLoadAlert', 'No hay Item(s) registrados para guardar', 'Advertencia', 'warning');
        return;
      }
      var total=0;
      this.details.map(function(value) {
        total+=parseFloat(value.subtotal);
        console.log(total);
      });
      if(total > this.entity.doc_total) {
        this.$emit('OnLoadAlert', 'El Detalle Total supera el Importe Total del comprobante', 'Advertencia', 'warning');
        return;
      }

      this.isLoading = true;
      this.$api.post("employee/details", {'details' : this.details})
      .then(response => {
          console.log(response.data);
          this.details=response.data;
          this.entity.details=this.details;
          this.newsitem=0;
          this.$emit("OnLoadAlert", 'Item(s) registrado(s) correctamente', 'Mensaje de éxito', 'success');
          this.$refs['modal'].hide();
          this.$emit('onReplaceEntity', this.entity);
          this.$nextTick(() => {
              this.isLoading = false;
            });
      })
      .catch(error => {
          this.$emit("OnLoadAlert", error.response.data.message);
      });

    },*/
    deleteDetail(id, index){
        this.$bvModal.msgBoxConfirm('Confirme si desea eliminar este Item.', {
          title: 'Eliminar Item',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'OK',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if(!value) return;
          this.isLoading = true;
          this.$api.delete("employee/details/"+id)
          .then(response => {
              console.log(response.data);
              this.spliceDetail(index, false);
              this.$emit("OnLoadAlert", 'Item(s) eliminado(s) correctamente',  'Mensaje de éxito', 'success');
              this.isLoading = false;
          })
          .catch(error => {
              this.isLoading = false;
              this.$emit(error.response.data.message);
          });
        })
        .catch(err => {
          this.$emit("OnLoadAlert", err.message);
        })
    }
  },
}
</script>

