import Vue from 'vue'
import VueRouter from 'vue-router'
import Init from '../views/Index.vue';
import RendidorView from '../views/management/RendidorView';
import UsersView from '../views/management/UsersView';
import ProjectsView from '../views/management/ProjectsView';
import CostCentersView from "../views/management/CostCentersView";
import DepartmentsView from '../views/management/DepartmentsView';
import SettingAccountView from '../views/setting/SettingsAccountView';
import ReportsSearchView from '../views/summary/ReportsSearchView';
import Login from '../views/login/Index.vue';
import store from '../store'
import Home from '../views/Home.vue';
// EXPENSES MODULE

import Expenses from "../views/employee/Expenses";
import ExpenseReports from "../views/employee/ExpenseReports";
import ExpenseReportsRevisor from "../views/expenses/ExpenseReports";
import Summary from "../views/employee/Summary";
import SummaryRevisor from "../views/expenses/Summary";

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    next('/')
}

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next()
        return
    }
    next({name: 'Login'})
}
const menu = store.getters.menu!= undefined ? store.getters.menu : [];

Vue.config.productionTip = false;

Vue.use(VueRouter);

var item_route = {};

item_route['/employee'] = {
       path: '/employee',
       components: {default: Home},
       beforeEnter: ifAuthenticated,
       children: [
           {
               path: 'expenses',
               name: "expenses",
               components: {
                   mainPanel: Expenses
               },
               meta: {
                   requiresAuth: true
               }
           },
           {
               path: 'summary',
               name: "summary",
               components: {
                   mainPanel: Summary
               },
               meta: {
                   requiresAuth: true
               }
           },
           {
               path: 'expenseReports',
               components: {
                   mainPanel: ExpenseReports
               },
               meta: {
                   requiresAuth: true
               }
           },
       ]
   }

item_route['/expenses'] = {
       path: '/expenses',
       components: {default: Home},
       beforeEnter: ifAuthenticated,
       children: [
           {
               path: 'expenseReports',
               beforeEnter: ifAuthenticated,
               components: {
                   mainPanel: ExpenseReportsRevisor
               },
               meta: {
                   requiresAuth: true
               }
           },
           {
               path: 'summary',
               name: "summary",
               components: {
                   mainPanel: SummaryRevisor
               },
               meta: {
                   requiresAuth: true
               }
           },
       ]
   }

item_route['/management'] = {
        path: '/management',
        components: {default: Home},
        children: [
            {
                path: 'costCenters',
                beforeEnter: ifAuthenticated,
                components: {
                    mainPanel: CostCentersView
                }
            },
            {
                path: 'rendidor',
                beforeEnter: ifAuthenticated,
                components: {
                    mainPanel: RendidorView
                }
            },
            {
                path: 'projects',
                beforeEnter: ifAuthenticated,
                components: {
                    mainPanel: ProjectsView
                }
            },
            {
                path: 'departments',
                beforeEnter: ifAuthenticated,
                components: {
                    mainPanel: DepartmentsView
                }
            },
            {
                path: 'users',
                beforeEnter: ifAuthenticated,
                components: {
                    mainPanel: UsersView
                }
            }
        ]
    }

var routes = [
    {
        path: '/',
        redirect: 'dashboard',
    },
    {
        path: '/tmpfile',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/summary',
        components: {default: Home},
        children: [
            {
                path: 'record',
                beforeEnter: ifAuthenticated,
                components: {
                    mainPanel: ReportsSearchView
                }
            },
        ]
    },
    {
        path: '/setting',
        components: {default: Home},
        children: [
            {
                path: 'account',
                beforeEnter: ifAuthenticated,
                components: {
                    mainPanel: SettingAccountView
                }
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: ifNotAuthenticated,
    },
    //{
        //path: '/conection/:id',
        //name: 'Conection',
        //component: Login,
        //beforeEnter: ifNotAuthenticated,
    //},
    {
        path: '/',
        beforeEnter: ifAuthenticated,
        components: {
            default: Home
        },
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                beforeEnter: ifAuthenticated,
                components: {
                    mainPanel: Init
                },
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'dashboard/:password',
                name: 'ShowDashboard',
                beforeEnter: ifAuthenticated,
                components: {
                    mainPanel: Init
                },
                meta: {
                    requiresAuth: true
                }
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {   
        path: "/logout", 
        component: Login, 
        meta: {
            requiresAuth: false
        }
    },
    //{path: "*", component: Login},
]

if(menu.length>0) {
    menu.map(function(item) {
        if(item.path!=null && item_route[item.path]) {
            item_route[item.path]['children'].map(function(child, index){
                var isset=false;
                for (var i = 0; i < item.items.length; i++) {
                    if(child['path']==item.items[i]['path']) {
                        isset=true;
                    }
                    if(isset) return;
                }
                if(!isset) {
                    item_route[item.path]['children'].splice(index, 1);
                }
            });
            
            routes.push(item_route[item.path]);
        }
    });
}
routes.push({
        path: '*',
        redirect: 'dashboard',
        });

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
