<template>
  <div>
    <ListSelect
            :list="List"
            option-value="id"
            option-text="name"
            :selected-item="item"
            :placeholder="placeholder"
            @searchchange="printSearchText"
            @select="selectOption"
            :isDisabled="isDisabled"
          >
    </ListSelect>
  </div>
</template>

<script>

import 'vue-search-select/dist/VueSearchSelect.css'
import { ListSelect } from 'vue-search-select'

export default {
  name: "UCComboSearch",
  components: {ListSelect},
  props: {
    url: String,
    List: {},
    data: {},
    placeholder: String,
    isDisabled: Boolean,
  },
  data() {
    return {
      searchText: '',
      item: this.data,
    }
  },
  mounted(){
    this.initialize();
  },
  methods: {
    initialize() {
        if(this.url!=undefined) {
          this.$api.get(this.url)
          .then((response) => {
            this.List = response.data;
          })
        }
    },
    reset () {
      this.item = {}
    },
    selectOption (item) {
      this.item = item;
      this.$emit("OnSelectEvent", this.item);
    },
    printSearchText (searchText) {
      this.searchText = searchText
    }
  }
}
</script>