<template>
    <div class="page">
        <div class="page-inner" :class="getWidth()">
            <header class="page-title-bar">
                <div class="container-fluid">
                    <h5>Gestionar Reportes de Gastos</h5>
                    <p class="d-block text-muted">A continuación puedes ver todos los reportes de gastos recibidos</p>
                </div>
            </header>
                <div id="base-style" class="card" >
                    <div class="card-header" v-if="!showForm">
                        <b-button class="col-xl-2" block pill v-if="!showForm" v-on:click="initialize()" variant="danger">
                             <i class="fas fa-sync-alt"></i> Actualizar
                        </b-button>
                    </div>
                    <div class="card-header" v-if="showForm && !showExpense">
                        <b-button pill class="col-xl-2" v-if="showForm && !showExpense" variant="secondary" type="button"  v-on:click="onClosed">
                            <i class="fa fa-backward"></i> Regresar
                        </b-button>
                    </div>
                    <ExpenseReportDetails :Report="expenseReport"
                                           v-if="showForm"
                                           :projects="projects"
                                           :docExpenses="docExpenses"
                                           :docTypes="docTypes"
                                           :docDetraccionPer="docDetraccionPer"
                                           v-on:OnEditOK="OnEditOK"
                                           v-on:onClosed="onClosed"
                                           v-on:OnShowExpense="onShowExpense">
                    </ExpenseReportDetails>
                    <div class="card-body mt-3">
                        <UCDatatable :items="items"
                                     :fields="fields"
                                     :totalRows="totalRows"
                                     v-if="!showForm"
                                     v-on:OnShowExpenseReport="showExpenseReport">
                        </UCDatatable>
                    </div>
                </div>
        </div>
        <b-button type="button" variant="primary" class="btn-md btn-floated hidden-lg" v-if="showForm && !showExpense" v-on:click="onClosed">
            <i class="fas fa-chevron-left fa-2x mr-1"></i>
        </b-button>
        <div v-if="isLoading">
            <div class="overlay"></div>
            <div class="loading-img"></div>
        </div>
    </div>
</template>

<script>
    import ExpenseReportDetails from "../../components/expenses/ExpenseReportDetails";
    import UCDatatable from "../../components/expenses/UCDatatable";

    export default {
        name: "ExpenseReports",
        components: {ExpenseReportDetails, UCDatatable},
        computed: {
            user() {
                return this.$store.getters.user;
            },
        },
        mounted() {
            this.initialize();
        },
        data() {
            return {
                expenseReport: {},
                fields: [
                    {key: 'id', label: 'ID', sortable: true},
                    {key: 'user.planilla', label: 'Planilla', sortable: true},
                    {key: 'user_name', label: 'Responsable', sortable: true},
                    {key: 'title', label: 'Reporte', sortable: true},
                    {key: 'created_at', label: 'Fecha', sortable: true},
                    {key: 'project', label: 'Proyecto'},
                    {key: 'estado', label: 'Estado'},
                    {key: 'last_user_admin_name', label: 'Ult. Revisión', tdClass: 'sizeTdname'},
                    {key: 'expenses_total', label: 'Rendido S/.' , variant: 'danger', class: 'text-right' },
                    {key: 'expenses_approved', label: 'Aprobado S/.', variant: 'success', class: 'text-right'},
                    {key: 'options', label: 'Opciones'},
                ],
                items: [],
                totalRows: 0,
                showForm: false,
                showExpense: false,
                isEdit: false,
                isLoading: false,
                errorMessage: '',
                projects: {},
                docExpenses: {},
                docTypes: {},
                docDetraccionPer: {},
            }
        },
        methods: {
            initialize() {
                this.loadReports();  
            },
            loadReports() {
                this.isLoading = true;
                this.$api.get("expen/reports")
                    .then(response => {
                        this.items = response.data;
                        this.totalRows = (!this.items) ? 0 : this.items.length;
                        this.isLoading = false;
                        this.showForm = false;
                        this.$forceUpdate();
                        this.loadOptions();
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.loadAlert(error.response.data.message);
                    });
            },
            loadOptions(){
                this.isBusy = true;
                this.$api.get("select/optExpenses")
                    .then(response => {
                        this.isBusy = false;
                        this.projects=response.data.projects.project;
                        this.docExpenses=response.data.docExpenses;
                        this.docTypes=response.data.docTypes;
                        this.docDetraccionPer=response.data.docDetraccionPer;
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.loadAlert(error.response.data.message);
                });
            },
            showExpenseReport(item) {
                this.expenseReport = item;
                this.showForm = true;
            },
            /*update(expenseReport) {
                this.expenseReport = expenseReport;
                this.showForm = true;
            },
            onCreated(item) {
                this.items.push(item);
                this.onClosed();
            },
            onUpdated() {
                this.onClosed();
            },*/
            onClosed() {
                this.expenseReport = {};
                //this.$refs.table.refresh();
                this.showForm = false;
                this.$forceUpdate();

            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            /*confirmData(item) {
                this.itemSelected = item;
            },*/
            OnEditOK(value) {
                this.isEdit=value;
            },
            onShowExpense(value){
                this.showExpense=value;
            },
            loadAlert(message, tittle='Mensaje de Error: ', variant='danger'){
                this.$bvToast.toast(message, {
                    title: tittle,
                    variant: variant,
                    solid: true
                });
            },
            getWidth(){
                if(this.showExpense){
                    return "col-md-8";
                } else return "col-md-12";
            }
        }
    }
</script>

<style scoped>

@media screen and (min-width: 992px) {

    /deep/ .table > tbody > tr > td.sizeTdname {
        max-width: 180px;
    }
}

</style>